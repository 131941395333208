import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Dialog,
  Tabs,
  Tab,
  IconButton,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import DoctorInfo from '../DoctorInfo';
import TableHeadSort from '../../Common/TableHeadSort';

import { getDoctorList, searchDoctor, getAccessionDepartmentsList } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class DoctorList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorDetailsTab: 0,
      doctor_id: 1,
      dialog: false,
      doctor: '',
      rows: [],
      page: 0,
      rowsPerPage: 15,
      order: 'asc',
      orderBy: 'doctor_name',
      openSearch: false,
      editDoctorList: [],
    }
    this.handleDoctorlist();
    this.props.getAccessionDepartmentsList();
  }

  handleDoctorlist = async () => {
    await this.props.getDoctorList(this.state.doctor_id)
    this.setState({ rows: this.props.doctorList })
  }

  handleTextChange = (e) => {
    this.handleSearch(e.target.value)
    this.setState({ [e.target.name]: e.target.value })
  }

  debounce = (func, delay) => {
    let debounceTimer
    return function () {
      const context = this
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer
        = setTimeout(() => func.apply(context, args), delay)
    }
  }

  handleSearch = this.debounce(async (value) => {
    if (value !== '') {
      await this.props.searchDoctor({ doctor: value })
      this.setState({ rows: this.props.searchList })
    } else {
      this.handleDoctorlist();
    }
  }, 1500)

  handleTabChange = (event, newValue) => {
    this.setState({ doctorDetailsTab: newValue })
  }

  handleTabEvent = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  handleAddDoctor = () => {
    const { doctorDetailsTab, editDoctorList } = this.state;
    return (
      <Dialog open={this.state.dialog} fullWidth maxWidth='md' classes={{ paper: classes.dialogHeight }}>
        <Container className={classes.textAlign} style={{ backgroundColor: '#eff0f1' }}>
          <Grid container direction='row' justify='center'>
            <Grid item xs={11}>
              Signing Doctor Details
            </Grid>
            <Grid item xs={1}>
              <Grid container justify='flex-end'>
                <IconButton onClick={this.handleCloseDialog} >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Tabs
                    value={doctorDetailsTab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                  >
                    <Tab className={classes.buttonText} label="Doctor Info"  {...this.handleTabEvent(1)} />
                    <Tab className={classes.buttonText} label="Signature"  {...this.handleTabEvent(2)} />
                    <Tab className={classes.buttonText} label="Login & Access"  {...this.handleTabEvent(3)} />
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {doctorDetailsTab === 0 || doctorDetailsTab === 1 || doctorDetailsTab === 2 ?
                <DoctorInfo
                  editDoctorList={editDoctorList}
                  departmentsList={this.props.departmentsList}
                  listRefresh = {this.handleDoctorlist}
                  doctorTab={doctorDetailsTab}
                  handleCloseDialog={this.handleCloseDialog}
                  handleDoctorShiftTab={e => this.handleTabChange(e, 1)}
                  handleSignatureShiftTab={e => this.handleTabChange(e, 2)}
                  handleSignaturePrevious={e => this.handleTabChange(e, 0)}
                  handleLoginAccessPrevious={e => this.handleTabChange(e, 1)}
                />
                : ''
              }
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    );
  }

  handleOpenDialog = () => {
    this.setState({ dialog: true, editDoctorList: [] })
  }

  handleCloseDialog = () => {
    this.setState({ dialog: false, doctorDetailsTab: 0 })
  }

  handlePageChange = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 })
  };

  handleRequestSort = (e, property) => {                                                //Searching
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  }

  handleIconButtonClick = () => {
    this.setState({ openSearch: !this.state.openSearch })
  }

  handleDoctorNameChange = (e) => {
    var updatedList = this.state.rows;
    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.doctor_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.doctorList })
    }
  }

  handleContactChange = (e) => {
    var updatedList = this.state.rows;
    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.contact_number.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.doctorList })
    }
  }

  handleEmailChange = (e) => {
    var updatedList = this.state.rows;
    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        if(item.email_address !== null ) {
          return  item.email_address.toLowerCase().search(
            e.target.value) !== -1;
        }
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.doctorList })
    }
  }

  handleCityChange = (e) => {
    var updatedList = this.state.rows;
    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        if(item.city !== null) {
          return item.city.toLowerCase().search(
            e.target.value.toLowerCase()) !== -1;
        }
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.doctorList })
    }
  }

  handleRequestSort = (e, property) => {                                              //Sorting
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  }

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  handleEditDoctor = (row) => {
    this.setState({ dialog: true, editDoctorList: row })
  }

  handleTable = () => {
    const headCells = [
      { id: "doctor_name", label: "Doctor Name" },
      { id: "contact_number", label: "Contact" },
      { id: "email_address", label: "Email" },
      { id: "city", label: "City" },
    ];

    const { rows, order, orderBy, openSearch, page, rowsPerPage } = this.state

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
      <Table aria-label="simple table">
        <TableHead style={{ minWidth: 650 }} >
          <TableRow>
            <TableHeadSort
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              openSearch={openSearch}
              enableIcon
              onRequestSort={this.handleRequestSort}
              handleButtonClick={this.handleIconButtonClick}
            />
          </TableRow>
          {openSearch ?
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  placeholder='Doctor Name'
                  onChange={this.handleDoctorNameChange}
                />
              </TableCell>
              <TableCell align='left'>
                <TextField
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  placeholder='Contact'
                  onChange={this.handleContactChange}
                />
              </TableCell>
              <TableCell align='left'>
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Email'
                  onChange={this.handleEmailChange}
                />
              </TableCell>
              <TableCell align='left'>
                <TextField
                  fullWidth
                  margin='dense'
                  variant='outlined'
                  placeholder='City'
                  onChange={this.handleCityChange}
                />
              </TableCell>
            </TableRow>
            : null
          }
        </TableHead>

        <TableBody>
          {this.stableSort((rowsPerPage > 0 && rows.length > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows), this.getComparator(order, orderBy)).map((row) => (
              <TableRow key={row.id} id={row.id} onClick={e => this.handleEditDoctor(row)}>
                <TableCell component="th" scope="row">
                  {row.doctor_name}
                </TableCell>
                <TableCell align="left">{row.contact_number}</TableCell>
                <TableCell align="left">{row.email_address}</TableCell>
                <TableCell align="left">{row.city}</TableCell>
              </TableRow>
            ))
          }
          {emptyRows > 0 && emptyRows === 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }

  render() {
    const { rows, doctor, page, rowsPerPage } = this.state;

    return (
      <Container maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container direction='row' spacing={1}>
          <Grid item xs={6}>
            <Typography>
              Signing Doctor List
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction='row' justify='flex-end'>
              <Button variant='outlined' disabled color='primary' className={classes.buttonAccession}>
                Export
              </Button>&nbsp;&nbsp;
              <Button
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
                onClick={this.handleOpenDialog}
              >
                Add Doctor
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name='doctor'
              value={doctor}
              onChange={this.handleTextChange}
              variant='outlined'
              fullWidth
              margin='dense'
              className={classes.search}
              label="Search Doctor Name/Contact Number..."
              inputProps={{ 'aria-label': 'search google maps' }}
            ><SearchIcon /></TextField>
          </Grid>
          <Grid item xs={6}>
            <TablePagination
              component="div"
              count={rows.length}
              rowsPerPageOptions={[5, 10, 15, 20]}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>

          <Grid item xs={12}>
            {this.handleTable()}
          </Grid>
          <Grid item xs={12}>
            {this.state.dialog ? this.handleAddDoctor() : ''}
          </Grid>

        </Grid>
      </Container >
    );
  }
}

const mapStateToProps = (state) => ({
  doctorList: state.login.doctorList,
  searchList: state.login.searchDoctorList,
  departmentsList: state.login.departmentsList,
})

export default connect(mapStateToProps, {
  getDoctorList,
  searchDoctor,
  getAccessionDepartmentsList,
})(DoctorList);