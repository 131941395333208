import React from 'react';
import { connect } from 'react-redux';

import {
  List,
  ListItem,
  ListItemText,
  Link,
  Collapse,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { logout } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class RegistrationList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openBillingHistory: false
    }
  }

  handleBillingHistory = () => {
    this.setState({ openBillingHistory: !this.state.openBillingHistory })
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { openBillingHistory } = this.state;
    return (
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItem button>
          <Link underline="none" color="inherit" href="/registration">
            <ListItemText> <div className={classes.drawerMainList}> Registration </div> </ListItemText>
          </Link>
        </ListItem>
        
        <ListItem button>
          <Link underline="none" color="inherit" href="/billing">
            <ListItemText> <div className={classes.drawerMainList}> Billing </div> </ListItemText>
          </Link>
        </ListItem>
        
        {/* <ListItem button>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Assign Cards </div> </ListItemText>
          </Link>
        </ListItem> */}

        <ListItem button onClick={this.handleBillingHistory}>
          <ListItemText> <div className={classes.drawerMainList}> Billing History </div> </ListItemText>
          {openBillingHistory ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openBillingHistory} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit" href='/bill-settlements'> Bill Settlements </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit" href='/addtesttobill'> Add Tests To Bill </Link>
            </ListItem>
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Advance Collection </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Invoice </Link>
            </ListItem> */}
          </List>
        </Collapse>
        
        {/* <ListItem button>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Cash Transfer </div> </ListItemText>
          </Link>
        </ListItem> */}
        
        {/* <ListItem button>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Collection Reports </div> </ListItemText>
          </Link>
        </ListItem>
        
        <ListItem button>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Tests List </div> </ListItemText>
          </Link>
        </ListItem>
        
        <ListItem button>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Operational Status </div> </ListItemText>
          </Link>
        </ListItem>
        
        <ListItem button>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Advanced Search </div> </ListItemText>
          </Link>
        </ListItem> */}

        <ListItem button onClick={this.handleLogout}>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Logout </div> </ListItemText>
          </Link>
        </ListItem>

      </List>
    );
  }
}

export default connect(null, { logout })(RegistrationList);