import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { ValidatorForm } from 'react-material-ui-form-validator';

import {
  Container,
  Grid,
  TextField,
  Typography,
  Divider,
  MenuItem,
  Link,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Menu
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import {
  TextValidator
} from '../Material UI';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import AddTestList from './AddTestList';
import { searchList, createList, getList, updateList, getListPrices, deleteList } from '../Store/ActionCreators';

import classes from '../App.module.css';

class ListManagement extends React.Component {
  constructor(props) {
    super(props);
    this.autoSearch = React.createRef();
    this.state = {
      selected_list: [],
      openAddNewList: false,
      selectList: '',

      list_name: '',
      list_type: 1,
      comments: '',
      start_date: null,
      end_date: null,
      branches: 1,
      copy_list: '',
      interface_type: '',

      openItem: false,

      openCopyList: false,
      warning: false,
      
      open: false,
      openListName: false,
      updateList: false,

      openSlider: false,
      deletedList: [],
      anchorEl: null
    }
    this.reset = this.state;
    this.listRef = React.createRef();
    this.handleGetList();
  }

  debounce = (func, delay) => {
    let debounceTimer
    return function () {
      const context = this
      const args = arguments
      clearTimeout(debounceTimer)
      debounceTimer
        = setTimeout(() => func.apply(context, args), delay)
    }
  }

  handleNewList = () => {
    this.setState({ openAddNewList: true, updateList: false })
  }

  handleEditListClick = (data) => {
    this.handleNewList();
    this.setState({
      updateList: true,

      listref: data.id,
      list_name: data.list_name,
      list_type: data.list_type,
      comments: data.comments,
      start_date: data.start_date,
      end_date: data.end_date,
      copy_list: data.copy_list,
    })
  }


  handleCloseDialog = () => {
    this.setState({ openAddNewList: false })
    this.setState({
      list_name: '',
      list_type: 1,
      comments: '',
      start_date: null,
      end_date: null,
      copy_list: '',
    })
  }

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSearchChange = async (name, value) => {
    if (!value) {
      this.setState({ [name]: value })
    } else {
      await this.props.searchList({ list: value });
    }
  };

  handleMenuClick = async (item) => {
    await this.props.getListPrices({ listref: item })
    this.setState({ selected_list: this.props.listPrices[0], openItem: true});
    const node = this.autoSearch.current.getElementsByTagName('input')[0];
    node.value = '';
    node.blur();
  }

  handleGetList = async () => {
    await this.props.getList();
  }

  handleCopyList = () => {
    this.setState({ openCopyList: !this.state.openCopyList })
  }

  handleStartDateChange = (date) => {
    this.setState({ start_date: date })
  }

  handleEndDateChange = (date) => {
    this.setState({ end_date: date })
  }

  handleWarningClose = () => {
    this.setState({ warning: false })
  }

  setOpen = (value) => {
    this.setState({ open: value })
  }

  handleAddList = async () => {
    const { list_name } = this.state;
    if (list_name !== '') {
      await this.props.createList(this.state);
      if (this.props.createListSuccess) {
        this.setState(this.reset);
        return this.handleCloseDialog();
      }
    } else {
      this.setState({ warning: true })
    }
  }

  handleEditList = async () => {
    const { list_name, listref } = this.state;
    if (list_name !== '') {
      await this.props.updateList(this.state);
      if (this.props.updateListSuccess) {
        this.handleCloseDialog();
        this.handleMenuClick(listref)
      }
    } else {
      this.setState({ warning: true })
    }
  }

  handleSubmit = () => {
    if(!this.state.updateList) {
      this.handleAddList();
    } else {
      this.handleEditList()
    }
  }

  handleAddTestListDialog = () => {
    const { list_name, list_type, comments, start_date, end_date, openAddNewList, openCopyList, warning, openListName, updateList } = this.state;
    const { searchLists, listNameError, updateListNameError } = this.props;

    return (
      <Dialog
        open={openAddNewList}
        fullWidth
        maxWidth='sm'
      >
        <ValidatorForm onSubmit={this.handleSubmit}>
          <Grid container>
            <AppBar position='static'>
              <Grid container>
                <Grid item xs={11} className={classes.type}>
                  {updateList ?
                    <Typography variant='h6'>
                      Edit List
                    </Typography> :
                    <Typography variant='h6'>
                      Add New List
                    </Typography>
                  }
                </Grid>
                <Grid item xs={1}>
                  <Grid container justify='flex-end'>
                    <IconButton onClick={this.handleCloseDialog}>
                      <CloseIcon fontSize='small' className={classes.whiteColor} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </AppBar>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} className={classes.type}>
              <Container maxWidth={false} classes={{root: classes.containerPadding}}>
                <Grid container direction='row' alignItems='center' spacing={1}>
                  <Grid item xs={12}>
                    {
                      warning === true ?
                        <Grid container alignItems='center' justify='center' spacing={1} className={classes.warningStyles}>
                          <Grid item xs={11} className={classes.type}>
                            <Typography variant='subtitle2'>Kindly check all input values</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton onClick={this.handleWarningClose}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                        : ''
                    }
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>List Name:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextValidator
                      name='list_name'
                      value={list_name}
                      onChange={this.handleTextChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      label='List name'
                      validators={['required']}
                      errorMessages={['This Field is required']}
                    />
                    {listNameError !== [] ? <div className={classes.errorStyle}>{listNameError}</div> : ''}
                    {updateList && updateListNameError !== [] ? <div className={classes.errorStyle}>{updateListNameError}</div> : ''}
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>List type:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name='list_type'
                      value={list_type}
                      onChange={this.handleTextChange}
                      fullWidth
                      select
                      variant='outlined'
                      margin='dense'
                      label='List name'
                    >
                      <MenuItem value={1}>Organization Price List</MenuItem>{/*Organisation Type*/}
                      <MenuItem value={2}>Referral Price List</MenuItem>{/*Referral Type*/}
                      <MenuItem value={3}>Doctor Price List</MenuItem> {/*Doctor Type*/}
                      <MenuItem value={4}>Outsource Price List</MenuItem> {/* no right bar - Outsource Type */}
                      <MenuItem value={5}>List Discount Price List</MenuItem> {/* no right bar - List Discount Type*/}
                    </TextField>

                  </Grid>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography> Comments:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name='comments'
                      value={comments}
                      onChange={this.handleTextChange}
                      fullWidth
                      multiline
                      variant='outlined'
                      margin='dense'
                      label='Comments (if any)'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Start Date:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        margin='dense'
                        inputVariant='outlined'
                        id="date-picker-dialog"
                        placeholder="Date picker"
                        format="yyyy/MM/dd"
                        value={start_date}
                        onChange={this.handleStartDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>End Date:</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        margin='dense'
                        inputVariant='outlined'
                        id="date-picker-dialog"
                        placeholder="Date picker"
                        format="yyyy/MM/dd"
                        value={end_date}
                        onChange={this.handleEndDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} className={classes.type}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container justify='center'>
                      <Typography>
                        <Link onClick={this.handleCopyList}>
                          Copy List
                        </Link>
                      </Typography>
                    </Grid>
                    {
                      openCopyList ?
                        <Autocomplete
                          id="asynchronous-search"
                          open={openListName}
                          onOpen={() => {
                            this.setState({ openListName: true })
                          }}
                          onClose={() => {
                            this.setState({ openListName: false });
                          }}
                          onChange={(e, newValue) => {
                            if (newValue !== null) {
                              this.setState({ copy_list: newValue.id })}
                            }
                          }
                          getOptionLabel={(option) => option.list_name}
                          renderOption={(option) => (
                            <React.Fragment>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography variant='subtitle2'>{option.list_name}</Typography>
                                </Grid>
                                <Grid item xs={12}><Divider /></Grid>
                              </Grid>
                            </React.Fragment>
                          )}
                          options={searchLists}
                          loading={openListName && searchLists.length === 0}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              margin='dense'
                              placeholder='List name'
                              onChange={e => this.handleSearchChange(e.target.name, e.target.value)}
                              {...params}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {openListName && searchLists.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      : ''
                    }
                  </Grid>
                </Grid>

              </Container>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={1} className={classes.type}>
              <Button
                onClick={this.handleCloseDialog}
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
              >
                Close
              </Button>
            </Grid>

            <Grid item xs={11} className={classes.type}>
              <Grid container justify='flex-end'>
                {updateList ?
                  <Button
                    type='submit'
                    variant='outlined'
                    color='primary'
                    className={classes.buttonAccession}
                  >
                    Edit List
                  </Button> :
                  <Button
                    type='submit'
                    variant='outlined'
                    color='primary'
                    className={classes.buttonAccession}
                  >
                    Add List
                  </Button>
                }
                &nbsp;&nbsp;
              </Grid>
            </Grid>

          </Grid>
        </ValidatorForm>
      </Dialog>
    )
  }

  handleDeleteList = (item) => {
    this.setState({ openSlider: true, deletedList: item })
  }

  handleDialogSlider = () => {
    const { openSlider } = this.state;
    return (
      <Dialog
        open={openSlider}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container>
            <Grid item xs={11}>
              Confirm List Removal
            </Grid>
            <Grid item xs={1}>
              <Grid container justify='flex-end'>
                <IconButton onClick={this.handleClose} >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <Typography>
            Are you sure you want to remove this list? It will be completely removed from your 
            centre and there will be no option to revert this back. You will have to add it once 
            again if you choose to go ahead with this action!
          </Typography>
        </DialogContent>

        <Divider/>

        <DialogActions>
          <div className={classes.type}>
            <Button onClick={this.handleClose} variant='outlined' className={classes.buttonText} color="primary">
              No! I clicked this by mistake
            </Button> &nbsp;&nbsp;
            <Button onClick={this.handleDeleteListItem} variant='contained' className={classes.buttonText} color="primary">
              Yes! I'm sure
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  handleClose = () => {
    this.setState({ openSlider: false })
  }

  handleDeleteListItem = async() => {
    const { deletedList } = this.state;
    await this.props.deleteList({ listref: deletedList.id })
    if(this.props.deleteListSuccess) {
      window.location.reload();
    }
  }

  setAnchorEl = (value) => {
    this.setState({ anchorEl: value})
  }

  render() {
    const { openItem, selectList, selected_list, openAddNewList, open, openSlider, anchorEl } = this.state;
    const { searchLists, getListNames } = this.props;

    return (
      <Container maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container justify='center' alignItems='center' direction='row' spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems='flex-start'>
              <h2>
                List Management
              </h2>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={2}>
            <Grid container justify='flex-end'>
              <Typography>
                List name:
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container direction='row'>
              <Grid item xs={10}>
                <Autocomplete
                  id="asynchronous-search"
                  name = "asynchronousSearch"
                  open={open}
                  ref={this.autoSearch}
                  onOpen={() => {
                    this.setOpen(true);
                  }}
                  onClose={() => {
                    this.setOpen(false);
                  }}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      this.handleMenuClick(newValue.id);
                    }
                  }}
                  getOptionLabel={(option) => option.list_name}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant='subtitle2'>{option.list_name}</Typography>
                        </Grid>
                        <Grid item xs={12}><Typography>{option.list_type_name}</Typography></Grid>
                        <Grid item xs={12}><Divider /></Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  options={searchLists.length === 0 ? getListNames : searchLists}
                  loading={searchLists.length === 0 && getListNames.length === 0 ? false : true}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      margin='dense'
                      placeholder='List name'
                      id="autoText"
                      
                      onChange={e => this.handleSearchChange(e.target.name, e.target.value)}
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {open && searchLists.length === 0 && getListNames.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Button aria-controls="simple-menu" aria-haspopup="true" variant="outlined" 
                onClick={e => this.setAnchorEl(e.currentTarget)} className={classes.listButton}>
                  List
                  <ArrowDropDownIcon></ArrowDropDownIcon>
                 </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                  onClose={e => this.setAnchorEl(null)}
                  >
                  {getListNames.map(item => {
                    return (<MenuItem key={item.id} value={item} onClick={e => {this.setAnchorEl(null);this.handleMenuClick(item.id)}}
                    >{item.list_name}</MenuItem>)
                  })}
                  </Menu>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid container alignItems='flex-start'>
              <Typography>
                <Link onClick={this.handleNewList} className={classes.pointer}>
                  + New List
                </Link>
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {
              openItem && selected_list !== null ?
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Grid container justify='flex-end' spacing={1}>
                      <Grid item xs={9}>
                        <div className={clsx(classes.box, classes.type, classes.branchDetailsStyle)}>
                          <Grid container>
                            <Grid item xs={2}>
                              List name:
                            </Grid>
                            <Grid item xs={9}>
                              <Typography variant='subtitle2'>
                                {selected_list.list_name}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              List Type:
                            </Grid>
                            <Grid item xs={9}>
                              <Typography variant='subtitle2'>
                                {selected_list.list_type_name}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              Comment:
                            </Grid>
                            <Grid item xs={9}>
                              <Typography variant='subtitle2'>
                                {selected_list.comments}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <Grid container direction='column'>
                      <Grid item xs={12}>
                        <Typography variant='body2'>
                          <Link onClick={e => this.handleEditListClick(selected_list)} className={classes.pointer}>
                            Edit List
                          </Link>
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <Typography variant='body2'>
                          <Link>
                            Export List
                          </Link>
                        </Typography>
                      </Grid> */}
                      <Grid item xs={12}>
                        <Typography variant='body2'>
                          <Link color='secondary' onClick={e => this.handleDeleteList(selected_list)} className={classes.pointer}>
                            Delete List
                          </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
                : ''
            }
          </Grid>

          <Grid item xs={12}>
            {openSlider === true  ? this.handleDialogSlider() : ''}
          </Grid>

          <Grid item xs={12}>
            {
              openItem && selected_list !== null ?
                <AddTestList
                  selectedItem={selected_list}

                />
              : ''
            }
          </Grid>

          <Grid item xs={12}>
            { openAddNewList ? this.handleAddTestListDialog() : '' }
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  searchLists: state.login.searchLists,
  createListSuccess: state.login.createListSuccess,
  deleteListSuccess: state.login.deleteListSuccess,
  listNameError: state.login.listNameError,
  getListNames: state.login.getListNames,
  updateListSuccess: state.login.updateListSuccess,
  updateListNameError: state.login.updateListErrors.updateListNameError,

  listPrices: state.login.listPrices,
})

export default connect(mapStateToProps, {
  searchList,
  createList,
  deleteList,
  getList,
  updateList,
  getListPrices,
})(ListManagement);