import React from 'react';
import { connect } from 'react-redux';

import {
  List,
  ListItem,
  ListItemText,
  Link,
} from '@material-ui/core';

import { logout } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class AccessionList extends React.Component {

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    return (
      <List>
        <ListItem button>
          <Link underline="none" color="inherit" href='/pending-samples'>
            <ListItemText> <div className={classes.drawerMainList}> Pending Samples </div> </ListItemText>
          </Link>
        </ListItem>
        <ListItem button>
          <Link underline="none" color="inherit" href='/accessed'>
            <ListItemText> <div className={classes.drawerMainList}> Accessed </div> </ListItemText>
          </Link>
        </ListItem>
        <ListItem button>
          <Link underline="none" color="inherit" href='/batch-management'>
            <ListItemText> <div className={classes.drawerMainList}> Batch Management </div> </ListItemText>
          </Link>
        </ListItem>
        <ListItem button>
          <Link underline="none" color="inherit" href='/rejected-samples'>
            <ListItemText> <div className={classes.drawerMainList}> Rejected Samples </div> </ListItemText>
          </Link>
        </ListItem>
        {/* <ListItem button>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Batch Management </div> </ListItemText>
          </Link>
        </ListItem>
        {/* 
        <ListItem button>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Rejected Samples </div> </ListItemText>
          </Link>
        </ListItem> */}
        <ListItem button>
          <Link underline="none" color="inherit" href='/accession-settings'>
            <ListItemText> <div className={classes.drawerMainList}> Accession Settings </div> </ListItemText>
          </Link>
        </ListItem>
        {/* <ListItem button>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Advanced Search </div> </ListItemText>
          </Link>
        </ListItem> */}
        <ListItem button onClick={this.handleLogout}>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Logout </div> </ListItemText>
          </Link>
        </ListItem>
      </List>
    );
  }
}

export default connect(null, { logout })(AccessionList);