import {getBarcodesData} from '../../Store/ActionCreators';
import printJS from 'print-js'

const PDFHelper = {
    getBarCodeData: async function(id){
        return await new Promise((resolve, reject) => {
            getBarcodesData(id).then(res => {
                    resolve (res);
                }, err => {
                    reject (err);
                });
        })
    },
    printPDF: function(data){
        printJS(data)
    }
}

export default PDFHelper;