import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Typography,
  Popper,
  Paper,
  Divider,
} from '@material-ui/core';

import { TextField } from '../Material UI';
import { getPatients, getPatientIdBilling } from '../Store/ActionCreators';
import classes from '../App.module.css';
import PDFHelper from '../Utilities/PDFHelper/index';

class DefaultBilling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search_patient: '',
      openBillingSearch: false
    }
    this.billRef = React.createRef();
  }

  printBarCodePDF = async (id) => {
    const barCodeResponse = await PDFHelper.getBarCodeData(id);
    let printableData = {
      printable:  barCodeResponse.payload.data.data,
      type: 'pdf',
      base64: true
    } 
    PDFHelper.printPDF(printableData);
  }

  handleSearch = (e) => {
    this.setState({ [e.target.name]: e.target.value, openBillingSearch: true })
    this.props.getPatients(e.target.value)
  }

  handleMenuClick = (item) => {
    this.setState({ search_patient: item.patient_name, openBillingSearch: false })
    window.location.replace('/bill/' + item.id)
  }

  render() {
    const { search_patient, openBillingSearch } = this.state;
    const { patientBillingSearch } = this.props;
    return (
      <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container direction="row" justify="center" alignItems='center'>
          <Grid item xs={2}>
            <Typography align="left" variant="subtitle1"> Select Patient: </Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              name="search_patient"
              value={search_patient}
              placeholder="Search User by Name, Contact or National Identity"
              onChange={this.handleSearch}
              inputProps={{
                ref:this.billRef
              }}
            />
            <Popper
              open={openBillingSearch}
              anchorEl={this.billRef.current}
              placement='bottom-start'
            >
              <Paper className={classes.listSuggestions}>
                {patientBillingSearch !== undefined ? patientBillingSearch.map(item => {
                  return (
                    <div value={item.mobile} key={item.id} onClick={event => this.handleMenuClick(item)} className={classes.listhover}>
                      <Grid container className={classes.type}>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">
                            Name: {item.patient_name} ({item.gender.charAt(0)}-{item.age})
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">
                            Mobile: {item.mobile}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">
                            ID: {item.id}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="subtitle2">
                            Registered on- {item.registered_on}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </div>
                  );
                }) : ''}
              </Paper>
            </Popper>
          </Grid>
        </Grid>
        {/* Please don't delete this code we can append it to print api's once they are ready  */}
        {/* <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
            <Button variant="contained" color="primary" onClick= { event => this.printBarCodePDF({testref:1})} >
               Generate PDF
            </Button>
        </Grid> */}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  patientBillingSearch: state.login.patientBillingSearch
})

export default connect(mapStateToProps, { getPatients, getPatientIdBilling })(DefaultBilling);
