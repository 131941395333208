import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Popper,
  Box,
  Divider,
} from '@material-ui/core';

import Colors from '../../../Data/colors.json';
import { getOperationSettings, updateOperationSettings } from '../../../Store/ActionCreators';

import classes from '../../../App.module.css';

class QuickSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      operationsettingref: null,
      days_for_data_load: 0,
      fetch_group_list_by: 0,
      auto_highlight_ranges: 0,
      default_signing_doctor: 0,
      is_lock_primary_signing_doctor: '0',
      is_show_latest_on_top: '0',
      is_flat_waiting_list: '0',
      completed_reports: 'None',
      partially_completed_reports: 'None',
      partially_signed_reports: 'None',
      signed_reports: 'None',
      backgroundColorCR: '#eff0f1',
      backgroundColorPCR: '#eff0f1',
      backgroundColorPSR: '#eff0f1',
      backgroundColorSR: '#eff0f1',
      open: false,
      openX: false,
      open_partially_completed: false,
      open_partially_signed: false,
      open_signed: false,
    }
    this.completedReportsRef = React.createRef();
    this.partiallyCompletedReportsRef = React.createRef();
    this.partiallySignedReportsRef = React.createRef();
    this.signedReportsRef = React.createRef();
    this.handleOperationSettings()
  }

  handleOperationSettings = async() => {
    await this.props.getOperationSettings();
    const { operationSettings } = this.props;
    this.setState({
      operationsettingref: operationSettings.id,
      days_for_data_load: operationSettings.operation_setting.days_for_data_load,
      fetch_group_list_by: operationSettings.operation_setting.fetch_group_list_by,
      auto_highlight_ranges: operationSettings.operation_setting.auto_highlight_ranges,
      default_signing_doctor: operationSettings.operation_setting.default_signing_doctor,
      is_lock_primary_signing_doctor: operationSettings.operation_setting.is_lock_primary_signing_doctor,
      is_show_latest_on_top: operationSettings.operation_setting.is_show_latest_on_top,
      is_flat_waiting_list: operationSettings.operation_setting.is_flat_waiting_list,
      completed_reports: operationSettings.operation_setting.completed_reports !== undefined ? operationSettings.operation_setting.completed_reports : 'None',
      partially_completed_reports: operationSettings.operation_setting.partially_completed_reports !== undefined ? operationSettings.operation_setting.partially_completed_reports : 'None',
      partially_signed_reports: operationSettings.operation_setting.partially_signed_reports !== undefined ? operationSettings.operation_setting.partially_signed_reports : 'None',
      signed_reports: operationSettings.operation_setting.signed_reports !== undefined ? operationSettings.operation_setting.signed_reports : 'None',
      backgroundColorCR: operationSettings.operation_setting.backgroundColorCR !== undefined ? operationSettings.operation_setting.backgroundColorCR : '#eff0f1',
      backgroundColorPCR: operationSettings.operation_setting.backgroundColorPCR !== undefined ? operationSettings.operation_setting.backgroundColorPCR : '#eff0f1',
      backgroundColorPSR: operationSettings.operation_setting.backgroundColorPSR !== undefined ? operationSettings.operation_setting.backgroundColorPSR : '#eff0f1',
      backgroundColorSR: operationSettings.operation_setting.backgroundColorSR !== undefined ? operationSettings.operation_setting.backgroundColorSR : '#eff0f1',
    })
  } 

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleOpen = () => {
    this.setState({ openX: !this.state.openX })
  }

  handleSaveSettings = async() => {
    const { operationsettingref, days_for_data_load, fetch_group_list_by, auto_highlight_ranges, default_signing_doctor, is_lock_primary_signing_doctor,
      is_show_latest_on_top, is_flat_waiting_list, completed_reports, partially_completed_reports, partially_signed_reports, signed_reports,
      backgroundColorCR, backgroundColorPCR, backgroundColorPSR, backgroundColorSR, colorCode } = this.state;

    let operation_setting = {
      days_for_data_load, fetch_group_list_by, auto_highlight_ranges, default_signing_doctor, is_lock_primary_signing_doctor,
      is_show_latest_on_top, is_flat_waiting_list, completed_reports, partially_completed_reports, partially_signed_reports, signed_reports,
      backgroundColorCR, backgroundColorPCR, backgroundColorPSR, backgroundColorSR, colorCode
    }
    await this.props.updateOperationSettings({ operationsettingref, operation_setting})
    if(this.props.updateSettings) {
      window.location.reload();
    }
  }

  render() {
    const { days_for_data_load, fetch_group_list_by, auto_highlight_ranges, default_signing_doctor, is_lock_primary_signing_doctor, 
      is_show_latest_on_top, is_flat_waiting_list, completed_reports, open, backgroundColorCR, open_partially_completed, open_partially_signed,
      open_signed, partially_completed_reports, partially_signed_reports, signed_reports, backgroundColorPCR, backgroundColorPSR, backgroundColorSR,
    } = this.state;
    return (
      <Container maxWidth={false}>
        <Grid container spacing={1} alignItems='center' className={clsx(classes.type,classes.containerPadding )}>
          <Grid item xs={10}>
            <Typography className={classes.fontColor}>Default Settings</Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid container justify='flex-end'>
              <Button
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
              >
                Cancel
              </Button>&nbsp;&nbsp;
              <Button
                variant='contained'
                color='primary'
                className={classes.buttonAccession}
                onClick={this.handleSaveSettings}
              >
                Save Settings
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Typography>Default days for Data load</Typography>
            <TextField
              select
              name='days_for_data_load'
              value={days_for_data_load}
              onChange={this.handleChange}
              fullWidth
              margin='dense'
              variant='outlined'
            >
              <MenuItem value={1} key={1}>Today(Fastest)</MenuItem>
              <MenuItem value={2} key={2}>2 Days</MenuItem>
              <MenuItem value={3} key={3}>4 Days(Optimum)</MenuItem>
              <MenuItem value={4} key={4}>7 Days</MenuItem>
              <MenuItem value={5} key={5}>15 Days</MenuItem>
              <MenuItem value={6} key={6}>30 Days</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <Typography>Fetch & Group List By</Typography>
            <TextField
              select
              name='fetch_group_list_by'
              value={fetch_group_list_by}
              onChange={this.handleChange}
              fullWidth
              margin='dense'
              variant='outlined'
            >
              <MenuItem value={1} key={1}>Latest Updated(Default)</MenuItem>
              <MenuItem value={2} key={2}>Accession Date</MenuItem>
              <MenuItem value={3} key={3}>Bill Date</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <Typography>Auto HighLight Ranges</Typography>
            <TextField
              select
              name='auto_highlight_ranges'
              value={auto_highlight_ranges}
              onChange={this.handleChange}
              fullWidth
              margin='dense'
              variant='outlined'
            >
              <MenuItem value={1} key={1}>Every Report Save(Default)</MenuItem>
              <MenuItem value={2} key={2}>On First Report Save</MenuItem>
              <MenuItem value={3} key={3}>Off Auto Higlight</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <Typography>Default Signing Doctor</Typography>
            <TextField
              select
              name='default_signing_doctor'
              value={default_signing_doctor}
              onChange={this.handleChange}
              fullWidth
              margin='dense'
              variant='outlined'
            >
              <MenuItem value={1} key={1}>Srinivas</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              name='is_lock_primary_signing_doctor'
              value={is_lock_primary_signing_doctor}
              control={<Checkbox color='primary' />}
              checked={true ? is_lock_primary_signing_doctor === '1' : false}
              label='Lock Primary Signing Doctor'
              labelPlacement='end'
              onClick={this.handleCheckBox}
            />&nbsp;&nbsp;
            <FormControlLabel
              name='is_show_latest_on_top'
              value={is_show_latest_on_top}
              control={<Checkbox color='primary' />}
              checked={true ? is_show_latest_on_top === '1' : false}
              label='Show Latest on Top'
              labelPlacement='end'
              onClick={this.handleCheckBox}
            />&nbsp;&nbsp;
            <FormControlLabel
              name='is_flat_waiting_list'
              value={is_flat_waiting_list}
              control={<Checkbox color='primary' />}
              checked={true ? is_flat_waiting_list === '1' : false}
              label='Flat Waiting List'
              labelPlacement='end'
              onClick={this.handleCheckBox}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.fontColor}>Default Operation Status Color</Typography>
          </Grid>

          <Grid item xs={2}>
            <Typography>Completed Reports</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              size='large'
              ref={this.completedReportsRef}
              onClick={e => this.setState({ open: true })}
              className={clsx(classes.buttonText, classes.submitButton)}
              style={{ backgroundColor: backgroundColorCR }}
            >
              {completed_reports}
            </Button>
            <Popper
              open={open}
              anchorEl={this.completedReportsRef.current}
            >
              {Colors.map(item =>
                <MenuItem
                  key={item.id}
                  className={classes.menuListWidth}
                  style={{ backgroundColor: item.colorCode }}
                  onClick={e => this.setState({ completed_reports: item.color, backgroundColorCR: item.colorCode, open: false })}
                >
                  {item.color}
                </MenuItem>
              )}
              <input
                style={{height: '34px', padding: '0px', cursor: 'pointer', width: '100%'}}
                type="color" 
                onChange={e => this.setState({ partially_completed_reports: e.target.value, backgroundColorPCR: e.target.value })}
              ></input> 
            </Popper>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <Typography> Partially Completed Reports</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              size='large'
              ref={this.partiallyCompletedReportsRef}
              onClick={e => this.setState({ open_partially_completed: !this.state.open_partially_completed })}
              className={clsx(classes.buttonText, classes.submitButton)}
              style={{ backgroundColor: backgroundColorPCR }}
            >
              {partially_completed_reports}
            </Button>
            <Popper
              open={open_partially_completed}
              anchorEl={this.partiallyCompletedReportsRef.current}
            >
              {Colors.map(item =>
                <MenuItem
                  key={item.id}
                  className={classes.menuListWidth}
                  style={{ backgroundColor: item.colorCode }}
                  onClick={e => this.setState({ partially_completed_reports: item.color, backgroundColorPCR: item.colorCode, open_partially_completed: false })}
                >
                  {item.color}
                </MenuItem>
              )}
              <input
                style={{height: '34px', padding: '0px', cursor: 'pointer', width: '100%'}}
                type="color" 
                onChange={e => this.setState({ partially_completed_reports: e.target.value, backgroundColorPCR: e.target.value })
              }
              ></input> 
            </Popper>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}>
            <Typography> Partially Signed Reports</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              size='large'
              ref={this.partiallySignedReportsRef}
              onClick={e => this.setState({ open_partially_signed: true })}
              className={clsx(classes.buttonText, classes.submitButton)}
              style={{ backgroundColor: backgroundColorPSR }}
            >
              {partially_signed_reports}
            </Button>
            <Popper
              open={open_partially_signed}
              anchorEl={this.partiallySignedReportsRef.current}
            >
              {Colors.map(item =>
                <MenuItem
                  key={item.id}
                  className={classes.menuListWidth}
                  style={{ backgroundColor: item.colorCode }}
                  onClick={e => this.setState({ partially_signed_reports: item.color, backgroundColorPSR: item.colorCode, open_partially_signed: false })}
                >
                  {item.color}
                </MenuItem>
              )}
              <input
                style={{height: '34px', padding: '0px', cursor: 'pointer', width: '100%'}}
                type="color" 
                onChange={e => this.setState({ partially_completed_reports: e.target.value, backgroundColorPCR: e.target.value })
              }
              ></input> 
            </Popper>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <Typography> Signed Reports</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              size='large'
              ref={this.signedReportsRef}
              onClick={e => this.setState({ open_signed: true })}
              className={clsx(classes.buttonText, classes.submitButton)}
              style={{ backgroundColor: backgroundColorSR }}
            >
              {signed_reports}
            </Button>
            <Popper
              open={open_signed}
              anchorEl={this.signedReportsRef.current}
            >
              {Colors.map(item =>
                <MenuItem
                  key={item.id}
                  className={classes.menuListWidth}
                  style={{ backgroundColor: item.colorCode }}
                  onClick={e => this.setState({ completed_reports: item.color, backgroundColorSR: item.colorCode, open_signed: false })}
                >
                  {item.color}
                </MenuItem>
              )}
              <input
                style={{height: '34px', padding: '0px', cursor: 'pointer', width: '100%'}}
                type="color" 
                onChange={e => this.setState({ partially_completed_reports: e.target.value, backgroundColorPCR: e.target.value })
              }
              ></input> 
            </Popper>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={2}>
            <Typography className={classes.fontColor}>Sample Not Received/In Question//Not performed/On Hold</Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              disabled
              fullWidth
              size='large'
              ref={this.signedReportsRef}
              onClick={e => this.setState({ open_signed: true })}
              className={clsx(classes.buttonText, classes.submitButton)}
              style={{ backgroundColor: backgroundColorSR }}
            >
              {signed_reports}
            </Button>
            <Popper
              open={open_signed}
              anchorEl={this.signedReportsRef.current}
            >
              {Colors.map(item =>
                <MenuItem
                  key={item.id}
                  className={classes.menuListWidth}
                  style={{ backgroundColor: item.colorCode }}
                  // onClick={e => this.setState({ completed_reports: item.color, backgroundColorSR: item.colorCode, open_signed: false })}
                >
                  {item.color}
                </MenuItem>
              )}
              <input
                style={{height: '34px', padding: '0px', cursor: 'pointer', width: '100%'}}
                type="color" 
                onChange={e => this.setState({ partially_completed_reports: e.target.value, backgroundColorPCR: e.target.value })
              }
              ></input> 
            </Popper>
          </Grid>

          <Grid item xs={12}><Divider /></Grid>

          <Grid item xs={12}>
            <Typography>Keyboard Shortcuts:</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2'><b>Report Entry</b></Typography>
          </Grid>

          <Grid item xs={6}>
            <Box border={1}>
              <Grid container>
                <Grid item xs={5} className={classes.backgroundLightPurple}>
                  <Typography className={classes.type}>Save</Typography>
                </Grid>
                <Grid item className={classes.backgroundLightPurple}>
                  <Divider orientation='vertical' />
                </Grid>
                <Grid item xs={6} className={classes.backgroundLightPurple}>
                  <Typography className={classes.type}>
                    <span className={clsx(classes.expansionPanelColor, classes.buttonKeyStyles)}>ctrl + enter</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>

                <Grid item xs={5}>
                  <Typography className={classes.type}>Save And Sign</Typography>
                </Grid>
                <Grid item>
                  <Divider orientation='vertical' />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.type}>
                    <span className={clsx(classes.expansionPanelColor, classes.buttonKeyStyles)}>shift + enter</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>

                <Grid item xs={5} className={classes.backgroundLightPurple}>
                  <Typography className={classes.type}>Edit Report</Typography>
                </Grid>
                <Grid item className={classes.backgroundLightPurple}>
                  <Divider orientation='vertical' />
                </Grid>
                <Grid item xs={6} className={classes.backgroundLightPurple}>
                  <Typography className={classes.type}>
                    <span className={clsx(classes.expansionPanelColor, classes.buttonKeyStyles)}>ctrl + shift + enter</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>

                <Grid item xs={5}>
                  <Typography className={classes.type}>Submit Report</Typography>
                </Grid>
                <Grid item>
                  <Divider orientation='vertical' />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.type}>
                    <span className={clsx(classes.expansionPanelColor, classes.buttonKeyStyles)}>ctrl + enter</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>

                <Grid item xs={5} className={classes.backgroundLightPurple}>
                  <Typography className={classes.type}>Back to Previous Page</Typography>
                </Grid>
                <Grid item className={classes.backgroundLightPurple}>
                  <Divider orientation='vertical' />
                </Grid>
                <Grid item xs={6} className={classes.backgroundLightPurple}>
                  <Typography className={classes.type}>
                    <span className={clsx(classes.expansionPanelColor, classes.buttonKeyStyles)}>ctrl + backspace</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>

                <Grid item xs={5}>
                  <Typography className={classes.type}>Back to Patient Waiting List</Typography>
                </Grid>
                <Grid item>
                  <Divider orientation='vertical' />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.type}>
                    <span className={clsx(classes.expansionPanelColor, classes.buttonKeyStyles)}>ESC</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  operationSettings: state.login.getOperationSettings,
  updateSettings: state.login.updateOperationSettings,
})

export default connect(mapStateToProps, {
  getOperationSettings,
  updateOperationSettings,
})(QuickSettings);