import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Divider,
  Box,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { appReset, loginConfirmation, activityLog } from '../Store/ActionCreators';

import Lucid from '../Images/lucid.png';
import classes from '../App.module.css';

class ConcurrentLogin extends React.Component {
  componentDidMount() {
    this.props.activityLog();
  }

  handleClose = () => {
    this.props.appReset();
  }

  handleCloseAndLogin = () => {
    this.props.loginConfirmation();
  }

  handleLastLogin = () => {
    var activitylog = this.props.activitylog;
    for (var item of activitylog) {
      if (item.log_type === 'LOGIN') {
        return (
          <div>
            <Grid container component="main" alignItems="center">
              <Grid item xs={9}>
                {
                  item.hasOwnProperty('user_agent') && typeof item.user_agent === 'object' ?
                    <Typography variant="h6">
                      {item.user_agent.is_desktop ? 'Desktop' :
                        (item.user_agent.is_phone ? 'Mobile' :
                          (item.user_agent.is_robot ? 'Robot' : ''))
                      } {'-'} {item.user_agent.platform} {item.user_agent.platformversion}
                    </Typography>
                  : ''
                }
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2"> {item.user_agent.browser}{'-'}{item.user_agent.browserversion} </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2"> Last Login Time: {item.activity_log_time} </Typography>
              </Grid>
            </Grid>

          </div>
        );
        break;
      }
    }
  }

  render() {
    const {activitylog} = this.props
    if(activitylog.length <= 0) {
      return(
        <Grid container justify="center"><CircularProgress /></Grid>
      )
    }
    return (
      <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container spacing={8}>

          <Grid item xs={12} className={classes.headerContainer}>
            <img src={Lucid} alt="!" className={classes.logo} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}}>
            <Grid container alignItems="center" justify="center" direction="row" spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h5" align="center">
                  You are already logged in on another computer
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography align="center">
                  You are seeing this message because, your account seems to be logged in on another computer,
                </Typography>
                <Typography align="center">
                  details below. kindly choose how you would like to proceed.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box border={1} className={classes.type}>
                  {this.handleLastLogin()}
                </Box>
              </Grid>

              <Grid container item xs={12} justify="flex-end">
                <Button variant="outlined" onClick={this.handleClose} className={classes.buttonText}>Continue previous session</Button> &nbsp;
                <Button color="primary" variant="contained" onClick={this.handleCloseAndLogin} className={classes.buttonText}>Login and close previous session</Button>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6"> Past Activity </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box border={1}>
                  {activitylog.map((item, index) => {
                    return (
                      <div key={index}>
                        <Grid container className={classes.type}>
                          {item.log_type === 'LOGIN' ?
                            <Grid item xs={12} className={classes.textAlign}>
                              <Typography variant="body1">
                                Logged in from {item.user_agent.browser} {item.user_agent.browserversion} on {item.user_agent.platform} {item.user_agent.platformversion}
                              </Typography>
                            </Grid> :
                            <Grid item xs={12} className={classes.textAlign}>
                              <Typography variant="body1">
                                Logged out {item.user_agent.browser} {item.user_agent.browserversion} on {item.user_agent.platform} {item.user_agent.platformversion}
                              </Typography>
                            </Grid>
                          }

                          <Grid item xs={9} className={classes.textAlign}> {item.log_type} </Grid>
                          <Grid item xs={3} className={classes.textAlign}> {item.activity_log_time} </Grid>
                          <Grid item xs={12} className={classes.textAlign}> <Divider /> </Grid>
                        </Grid>
                      </div>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Container>

        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  activitylog: state.login.activitylog
})

export default connect(mapStateToProps, { appReset, loginConfirmation, activityLog })(ConcurrentLogin);