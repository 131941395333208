import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';

import {
  RadioGroup,
  Grid,
} from '@material-ui/core';

import classes from '../../App.module.css';

class RadioButtonValidator extends ValidatorComponent {
  errorText() {
    const { isValid } = this.state;

    if (isValid) return null;
    return (
      <Grid className={classes.error}>
        {this.getErrorMessage()}
      </Grid>
    );
  }

  render() {
    const { errorMessages, validators, requiredError, validatorListener, value, ...rest } = this.props;
    return (
      <div>
        <RadioGroup
          {...rest}
          ref={(r) => { this.input = r; }}
        />
        {this.errorText()}
      </div>
    );
  }
}

export default RadioButtonValidator;