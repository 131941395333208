import React from 'react';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';

import DateFnsUtils from '@date-io/date-fns';

import {
  Paper,
  Popper,
  Typography,
  Button,
  Grid,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  Divider,
  Dialog,
  CircularProgress,
} from '@material-ui/core';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { TextValidator } from '../Material UI';
import RadioButtonValidator from '../Common/RadioValidation';

import designationData from '../Data/designation.json';
import flags from '../Data/flags.json';

import {
  getPatientCount,
  patientType,
  getPatientMobile,
  getPatientName,
  getOrganisations,
  getReferrals,
  submitRegistration,
  updateRegistration
} from '../Store/ActionCreators';

import classes from '../App.module.css';

class Registration extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      patient_id: '',
      patient_type: 1,
      patient_ref: '',
      country_code_mobile: '+91',
      mobile: '',
      email: '',
      designation: 'Mr.',
      patient_name: '',
      card: '',
      list_card: '',
      date_of_birth: null,
      dob: '',
      age: '',
      gender: 'MALE',
      country_code_alt_mobile: '+91',
      alternative_mobile: '',
      organisation: '', //Default values for organisation and referral
      referral: '',
      area: '',
      city: '',
      pincode: '',
      passport: '',
      history: '',
      existingDataFlag: false,
      flag: false,

      type_of_patient: 'DIRECT',
      type_of_flag: '🇮🇳',
      type_of_alt_flag: '🇮🇳',
      country_flag: '',
      alt_country_flag: '',
      organisationType: '',
      referralType: '',
      openOrg: false,
      openRef: false,
      openMobileSuggest: false,
      openNameSuggest: false,
      openAlternateMobileSuggest: false,
      openOrganisationSuggest: false,
      openReferralSuggest: false,
    }
    this.mobileRef = React.createRef()
    this.nameRef = React.createRef()
    this.alt_mobileRef = React.createRef()
    this.organisationRef = React.createRef()
    this.referralRef = React.createRef()
    this.props.patientType();
  }

  componentDidMount() {
    this.props.getPatientCount();
  }

  handleAutoFill = (e) => {
    if (e !== undefined && typeof e === 'object') {
      if (e.hasOwnProperty('mobile') && e.hasOwnProperty('patient_name') && e.hasOwnProperty('alternative_mobile') && e.hasOwnProperty('email')
        && e.hasOwnProperty('designation') && e.hasOwnProperty('age') && e.hasOwnProperty('organisation') && e.hasOwnProperty('referral')
        && e.hasOwnProperty('organisationdetails') && e.hasOwnProperty('referraldetails') && e.hasOwnProperty('dob') && e.hasOwnProperty('area')
        && e.hasOwnProperty('gender') && e.hasOwnProperty('city') && e.hasOwnProperty('pincode') && e.hasOwnProperty('patient_type')
        && e.hasOwnProperty('country_code_mobile') && e.hasOwnProperty('country_code_alt_mobile') && e.hasOwnProperty('id')
      ) {
        this.setState({
          type_of_patient: e.patient_type,
          type_of_flag: e.country_code_mobile,
          type_of_alt_flag: e.country_code_alt_mobile,
          patient_id: e.id,
          mobile: e.mobile,
          patient_name: e.patient_name,
          alternative_mobile: e.alternative_mobile !== null ? e.alternative_mobile.toString() : '',
          email: e.email,
          designation: e.designation,
          date_of_birth: e.dob,
          dob: e.dob,
          age: e.age,
          gender: e.gender,
          organisation: e.organisation,
          organisationType: e.organisationdetails !== null ? e.organisationdetails.organisation_name : '',
          referral: e.referral,
          referralType: e.referraldetails !== null ? e.referraldetails.referrer_name : '',
          area: e.area !== null ? e.area.toString() : '',
          city: e.city !== null ? e.city.toString() : '',
          pincode: e.pincode !== null ? e.pincode.toString() : '',
          existingDataFlag: true,
        })
      }
    }
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  }

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleMobileChange = (e) => {
    if (e.target.value !== '') {
      let mobileValue = parseInt(e.target.value)
      if (mobileValue) {
        this.setState({ [e.target.name]: mobileValue });
        this.props.getPatientMobile(e.target.value);
      }
    } else this.setState({ mobile: '' });

    this.setState({ openMobileSuggest: true })
  }

  handleMobileClick = (e) => {
    this.handleAutoFill(e);
    this.setState({ openMobileSuggest: false })
  }

  handleNameChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value !== '') {
      this.props.getPatientName(e.target.value)
    }
    this.setState({ openNameSuggest: true })
  }

  handleNameClick = (e) => {
    this.handleAutoFill(e);
    this.setState({ openNameSuggest: false })
  }

  handleDateChange = (date) => {
    if (date !== undefined) {
      this.handleDateFormat(date)
      this.setState({ date_of_birth: date })
      this.handleAgeChange(date)
    } else return '';
  }

  handleDateFormat = (date) => {
    if (date !== null) {
      const formattedDate = date.getFullYear() + '-' + ('0' + date.getMonth()).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
      this.setState({ dob: formattedDate })
    }
  }

  handleAgeChange = (e) => {
    if (e !== null) {
      if (e !== undefined && e.target !== undefined) {
        this.setState({ [e.target.name]: e.target.value })
      }
    }

    if (e instanceof Date && !isNaN(e)) {
      const now = new Date();
      if (e.target === undefined) {
        if (e.getMonth() <= now.getMonth()) {
          if (e.getMonth() === now.getMonth()) {
            const age1 = `${now.getFullYear() - e.getFullYear()}`;
            this.setState({ age: age1 })
          } else {
            const current_age = `${(now.getFullYear() - e.getFullYear())}`;
            this.setState({ age: current_age })
          }
        } else {
          const age_now = `${((now.getFullYear() - e.getFullYear()) - 1)}`;
          this.setState({ age: age_now })
        }
      }
    }
  }

  handleRadioButton = (e) => {
    if (e.target !== undefined && e.target.value !== undefined) {
      this.setState({ gender: e.target.value })
    } else this.setState({ gender: '' })
  }

  handleAlternateMobileChange = (e) => {
    if (e.target.value !== '') {
      let alt_mobile = parseInt(e.target.value)
      if (alt_mobile) {
        this.setState({ [e.target.name]: alt_mobile });
      }
    } else this.setState({ alternative_mobile: '' });

    this.props.getPatientMobile(e.target.value);
    this.setState({ openAlternateMobileSuggest: true })
  }

  handleAlternateMobileClick = (e) => {
    this.handleAutoFill(e);
    this.setState({ openAlternateMobileSuggest: false })
  }

  handleOrganisationChange = (e) => {
    this.handleTextChange(e);
    if (e.target.value !== '') {
      this.props.getOrganisations(e.target.value);
    } else this.setState({ organisation: '' })
    this.setState({ openOrganisationSuggest: true })
  }

  handleOrganisationClick = (e, id) => {
    this.setState({ organisation: id, openOrganisationSuggest: false, organisationType: e })
  }

  handleOpenOrg = () => {
    this.setState({ openOrg: true })
  }

  handleCloseOrg = () => {
    this.setState({ openOrg: false })
  }

  handleOrgModal = () => {
    return (
      <Dialog
        onClose={this.handleCloseOrg}
        aria-labelledby="customized-dialog-title"
        open={this.state.openOrg !== undefined ? this.state.openOrg : false}
        fullWidth
      >
        Organization Modal
      </Dialog>
    );
  }

  handleReferralChange = (e) => {
    this.handleTextChange(e);
    if (e.target.value !== '') {
      this.props.getReferrals(e.target.value);
    } else this.setState({ referral: '' })
    this.setState({ openReferralSuggest: true })
  }

  handleReferralClick = (e, id) => {
    this.setState({ referral: id, openReferralSuggest: false, referralType: e })
  }

  handleOpenRef = () => {
    this.setState({ openRef: true })
  }

  handleCloseRef = () => {
    this.setState({ openRef: false })
  }

  handleRefModal = () => {
    return (
      <Dialog
        open={this.state.openRef !== undefined ? this.state.openRef : false}
        onClose={this.handleCloseRef}
        fullWidth
      >
        Referral Modal
      </Dialog>
    )
  }

  handleAutoSuggestClose = () => {
    this.setState({
      openMobileSuggest: false,
      openNameSuggest: false,
      openAlternateMobileSuggest: false,
      openOrganisationSuggest: false,
      openReferralSuggest: false
    })
  }

  handleFormSubmit = (e) => {
    const {
      patient_type, patient_ref, patient_id, country_code_mobile, mobile, email, designation, patient_name, card, list_card, dob,
      age, gender, country_code_alt_mobile, alternative_mobile, organisation, referral, area, city, pincode, passport, history, flag
    } = this.state;
    let register  = {
      patient_type, patient_ref, patient_id, country_code_mobile, mobile, email, designation, patient_name, card, list_card, dob,
      age, gender, country_code_alt_mobile, alternative_mobile, organisation, referral, area, city, pincode, passport, history, flag
    }
    if (this.state.existingDataFlag) {
      this.props.updateRegistration(register);
    } else this.props.submitRegistration(register);
    e.preventDefault();
  }

  render() {
    const { type_of_patient, patient_ref, type_of_flag, mobile, email, designation,
      patient_name, date_of_birth, age, gender, type_of_alt_flag, alternative_mobile,
      organisationType, referralType, area, city, pincode, passport, history, openMobileSuggest, openNameSuggest,
      openAlternateMobileSuggest, openOrganisationSuggest, openReferralSuggest, existingDataFlag
    } = this.state

    const { patientTypes, patientMobiles, patientCurrentLocation, patientOtherLocation, organisations, referrals, registerError,
      registerErrorMessage, ageError, altMobileError, altCountryCodeError, mobileError, mobileCountryCodeError, emailError,
      genderError, designationError, organisationError, referralError, patientNameError, patientTypeError, patientId
    } = this.props;

    if (patientTypes.length <= 0) {
      return <Grid container justify="center"><CircularProgress /></Grid>
    }

    const organizationModal = this.handleOrgModal();
    const referralModal = this.handleRefModal();

    return (
      <Container maxWidth={false} classes={{root: classes.containerPadding}} component="main" onClick={this.handleAutoSuggestClose}>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item xs={9}>
            <h2>Registration</h2>
          </Grid>

          <Grid item xs={3}>
            <Grid container justify='flex-end' direction='row' alignItems='flex-start' spacing={1}>
              <Grid item>
                <Button
                  size='medium'
                  variant='outlined'
                  className={classes.buttonText}
                  disabled
                >Bulk Registration</Button>
              </Grid>

              <Grid item>
                <Button
                  size='medium'
                  variant='outlined'
                  className={classes.buttonText}
                  disabled
                >Settings</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={9}>
              <h3>Patient ID: {patientId}</h3>
            </Grid>
            <Grid item xs={3}>
              <Grid container justify='flex-end' direction='row' alignItems='flex-start' spacing={1}>
                <Grid item>
                  <Button
                    size='medium'
                    variant='outlined'
                    className={classes.buttonText}
                    disabled
                  >Search Patient</Button>
                </Grid>
                <Grid item>
                  <Button
                    size='medium'
                    variant='outlined'
                    className={classes.buttonText}
                    disabled
                  >I</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ValidatorForm onSubmit={this.handleFormSubmit} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    autoHighlight
                    autoSelect
                    disableClearable
                    options={patientTypes}
                    name='patient_type'
                    getOptionLabel={option => {
                      if (typeof option !== 'object') {
                        if (typeof option === 'number') {
                          patientTypes.map(item => {
                            if (item.id === option) {
                              this.setState({ type_of_patient: item.type, patient_type: option });
                              return false
                            } else return false;
                          })
                        } else return option;
                      } else return option.type;
                    }}
                    value={type_of_patient}
                    renderOption={option => option.type}
                    onChange={(e, value) => {
                      this.setState({ [e.target.name]: e.target.value })
                      if (value !== null) {
                        this.setState({ [e.target.name]: e.target.value, type_of_patient: value.type })
                      }
                    }}
                    renderInput={params => (
                      <TextValidator
                        {...params}
                      />
                    )}
                  />
                </FormControl>
                {registerError ?
                  <div className={classes.errorStyle}>
                    {patientTypeError}
                  </div> : ''
                }
              </Grid>

              <Grid item xs={6}>
                <TextValidator
                  name="patient_ref"
                  value={patient_ref}
                  onChange={this.handleTextChange}
                  validators={['maxStringLength: 6', 'matchRegexp:^[1-9]{1}[0-9]{5}$']}
                  errorMessages={['Invalid Patient ID']}
                  label="Patient ID (Optional)"
                  inputProps={{
                    maxLength: 6,
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    autoHighlight
                    autoSelect
                    disableClearable
                    options={designationData}
                    name='designation'
                    getOptionLabel={option => {
                      return option;
                    }}
                    value={designation}
                    renderOption={option => (
                      <React.Fragment>
                        {option.title}
                      </React.Fragment>
                    )}
                    onChange={(e, value) => {
                      this.setState({ [e.target.name]: e.target.value })
                      if (value !== null) {
                        if (value.title === 'Mr.' || value.title === 'Master') {
                          this.setState({ gender: 'MALE' })
                        } else if (value.title === 'Mrs.' || value.title === 'Ms.' || value.title === 'Miss' || value.title === 'Smt.') {
                          this.setState({ gender: 'FEMALE' })
                        } else this.setState({ gender: '' })
                        this.setState({ designation: value.title, patientDesignation: value.title })
                      }
                    }}
                    renderInput={params => (
                      <TextValidator
                        {...params}
                        label='Designation'
                      />
                    )}
                  />
                </FormControl>
                {registerError ?
                  <div className={classes.errorStyle}>
                    {designationError}
                  </div> : ''
                }
              </Grid>

              <Grid item xs={9}>
                <TextValidator
                  name="patient_name"
                  value={patient_name}
                  onChange={this.handleNameChange}
                  label="Full Name"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  inputProps={{
                    ref: this.nameRef,
                    maxLength: 80,
                  }}
                />
                <Popper
                  open={openNameSuggest !== undefined ? openNameSuggest : false}
                  anchorEl={this.nameRef.current}
                  placement='bottom-start'
                >
                  <Paper className={classes.listSuggestions}>
                    {patientCurrentLocation !== undefined ? patientCurrentLocation.map(item => {
                      return (
                        <div value={item.patient_name} key={item.id} onClick={event => this.handleNameClick(item)} className={classes.listhover}>
                          <Grid container component="main" className={classes.type}>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Patient: {item.patient_name} ({item.gender.charAt(0)}-{item.age})
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Mobile: {item.mobile}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                patient ID: {item.id}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Registered on: {item.registered_on}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </div>
                      );
                    }) : ''}
                    {patientOtherLocation !== undefined && patientOtherLocation.length > 0 ?
                      <div>
                        <Typography variant="h6" className={classes.type}> Patients registered on your other locations </Typography> <Divider />
                      </div>
                      : ''
                    }

                    {patientOtherLocation !== undefined ? patientOtherLocation.map(item => {
                      return (
                        <div value={item.patient_name} key={item.id} onClick={event => this.handleNameClick(item)} className={classes.listhover}>
                          <Grid container component="main" className={classes.type}>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Patient: {item.patient_name} ({item.gender.charAt(0)}-{item.age})
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Registered on: {item.registered_on}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography className={classes.branchDetailsStyle} align="right">
                                {item.branchdetails.branch_name}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </div>
                      );
                    }) : ''}
                  </Paper>
                </Popper>
                {registerError ?
                  <div className={classes.errorStyle}>
                    {patientNameError}
                  </div> : ''
                }
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    autoHighlight
                    autoSelect
                    disableClearable
                    options={flags}
                    name='country_code_mobile'
                    getOptionLabel={option => {
                      if (typeof option !== 'object') {
                        if (typeof option === 'number') {
                          flags.map(item => {
                            if (parseInt(item.dial_code) === option) {
                              this.setState({ type_of_flag: item.flag, country_code_mobile: option })
                            }
                            return false;
                          })
                        } else return option;
                      }
                      return option;
                    }}
                    value={type_of_flag}
                    renderOption={option => (
                      <React.Fragment>
                        {option.flag} {option.name} {option.dial_code}
                      </React.Fragment>
                    )}
                    onChange={(e, value) => {
                      this.setState({ [e.target.name]: e.target.value })
                      if (value !== null) {
                        this.setState({ country_code_mobile: value.dial_code, type_of_flag: value.flag })
                      }
                    }}
                    renderInput={params => (
                      <TextValidator
                        {...params}
                      />
                    )}
                  />
                </FormControl>
                {registerError ?
                  <div className={classes.errorStyle}>
                    {mobileCountryCodeError}
                  </div> : ''
                }
              </Grid>

              <Grid item xs={9}>
                <TextValidator
                  name="mobile"
                  value={mobile}
                  onChange={this.handleMobileChange}
                  label="Mobile"
                  validators={['required']}
                  errorMessages={['This field is required', 'Invalid Mobile Number']}
                  inputProps={{
                    ref: this.mobileRef,
                    maxLength: 10,
                  }}
                />
                <Popper
                  open={openMobileSuggest !== undefined ? openMobileSuggest : false}
                  anchorEl={this.mobileRef.current}
                  placement='bottom-start'
                >
                  <Paper className={classes.listSuggestions}>
                    {patientMobiles !== undefined ? patientMobiles.map(item => {
                      return (
                        <div value={item.mobile} key={item.id} onClick={event => this.handleMobileClick(item)} className={classes.listhover}>
                          <Grid container className={classes.type}>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Patient: {item.patient_name} ({item.gender.charAt(0)}-{item.age})
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Mobile: {item.mobile}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                patient ID: {item.id}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Registered on: {item.registered_on}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </div>
                      );
                    }) : ''}
                  </Paper>
                </Popper>
                {registerError ?
                  <div className={classes.errorStyle}>
                    {mobileError}
                  </div> : ''
                }
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  label='Email'
                  validators={['isEmail']}
                  errorMessages={['Email is not valid']}
                  name="email"
                  value={email}
                  onChange={this.handleTextChange}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
                {registerError ?
                  <div className={classes.errorStyle}>
                    {emailError}
                  </div> : ''
                }
              </Grid>

              {/* <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date of Birth"
                    format="dd/MM/yyyy"
                    value={date_of_birth}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid> */}

              <Grid item xs={12}>
                <TextValidator
                  name='age'
                  value={age}
                  onChange={this.handleAgeChange}
                  validators={['trim']}
                  errorMessages={['This field is required']}
                  margin='normal'
                  label='Age(in years)'
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                {registerError ?
                  <div className={classes.errorStyle}>
                    {ageError}
                  </div> : ''
                }
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2}>
                    <h3>Gender:</h3>
                  </Grid>
                  <Grid item xs={6}>
                    <RadioButtonValidator
                      validators={['required']}
                      errorMessages={['This field is required']}
                      row aria-label="gender"
                      name="gender"
                      value={gender}
                      onChange={this.handleRadioButton}
                    >
                      <FormControlLabel
                        value="MALE"
                        checked={gender === 'MALE'}
                        control={<Radio color="primary" />}
                        label="Male"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="FEMALE"
                        checked={gender === 'FEMALE'}
                        control={<Radio color="primary" />}
                        label="Female"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="OTHER"
                        checked={gender === 'OTHER'}
                        control={<Radio color="primary" />}
                        label="Other"
                        labelPlacement="end"
                      />
                    </RadioButtonValidator>
                  </Grid>
                </Grid>
                {registerError ?
                  <div className={classes.errorStyle}>
                    {genderError}
                  </div> : ''
                }
              </Grid>

              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    autoHighlight
                    autoSelect
                    disableClearable
                    options={flags}
                    name='country_code_alt_mobile'
                    getOptionLabel={option => {
                      if (typeof option !== 'object') {
                        if (typeof option === 'number') {
                          flags.map(item => {
                            if (parseInt(item.dial_code) === option) {
                              this.setState({ type_of_alt_flag: item.flag, country_code_alt_mobile: option })
                            }
                            return false;
                          })
                        } else return option;
                      }
                      return option;
                    }}
                    value={type_of_alt_flag}
                    renderOption={option => (
                      <React.Fragment>
                        {option.flag} {option.name} {option.dial_code}
                      </React.Fragment>
                    )}
                    onChange={(e, value) => {
                      this.setState({ [e.target.name]: e.target.value })
                      if (value !== null) {
                        this.setState({ country_code_alt_mobile: value.dial_code, type_of_alt_flag: value.flag })
                      }
                    }}
                    renderInput={params => (
                      <TextValidator
                        {...params}
                      />
                    )}
                  />
                </FormControl>
                {registerError ?
                  <div className={classes.errorStyle}>
                    {altCountryCodeError}
                  </div> : ''
                }
              </Grid>

              <Grid item xs={9}>
                <TextValidator
                  name="alternative_mobile"
                  value={alternative_mobile}
                  onChange={this.handleAlternateMobileChange}
                  label="Alternative Mobile"
                  inputProps={{
                    ref: this.alt_mobileRef,
                    maxLength: 10,
                  }}
                />
                Messages will be sent to this number in case no primary number is mentioned.
                <Popper
                  open={openAlternateMobileSuggest !== undefined ? openAlternateMobileSuggest : false}
                  anchorEl={this.alt_mobileRef.current}
                  placement='bottom-start'
                >
                  <Paper className={classes.listSuggestions}>
                    {patientMobiles !== undefined ? patientMobiles.map(item => {
                      return (
                        <div value={item.mobile} key={item.id} onClick={event => this.handleAlternateMobileClick(item)} className={classes.listhover}>
                          <Grid container component="main" className={classes.type}>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Patient: {item.patient_name} ({item.gender.charAt(0)}-{item.age})
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Mobile: {item.mobile}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                patient ID: {item.id}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="subtitle2">
                                Registered on: {item.registered_on}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </div>
                      );
                    }) : ''}
                  </Paper>
                </Popper>
                {registerError ?
                  <div className={classes.errorStyle}>
                    {altMobileError}
                  </div> : ''
                }
              </Grid>

              <Grid item xs={12} >
                <Grid container alignItems="center" direction="row" justify="center">
                  <Grid item xs={2}>
                    <h3>Organization :</h3>
                  </Grid>

                  <Grid item xs={9}>
                    <TextValidator
                      name="organisationType"
                      value={organisationType || ''}
                      onChange={this.handleOrganisationChange}
                      label="Enter Organisation Name (Default - DIRECT)"
                      inputProps={{
                        ref: this.organisationRef,
                      }}
                    />
                    <Popper
                      open={openOrganisationSuggest !== undefined ? openOrganisationSuggest : false}
                      anchorEl={this.organisationRef.current}
                      placement='bottom-start'
                    >
                      <Paper className={classes.listSuggestions} classes={{ root: classes.orgListStyle }}>
                        {organisations !== undefined ? organisations.map(item => {
                          return (
                            <div key={item.id} onClick={event => this.handleOrganisationClick(item.organisation_name, item.id)} className={classes.listhover}>
                              <Grid container component="main" justify='flex-end' alignItems="flex-end" className={classes.type}>
                                <Grid item xs={12}>
                                  <Typography variant="subtitle2">
                                    {item.organisation_name}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Divider />
                            </div>
                          );
                        }) : ''}
                      </Paper>
                    </Popper>
                    {registerError ?
                      <div className={classes.errorStyle}>
                        {organisationError}
                      </div> : ''
                    }
                  </Grid>

                  <Grid item xs={1}>
                    <Grid container justify='flex-end'>
                      <AddCircleIcon
                        color="primary"
                        fontSize="default"
                      />
                      {organizationModal}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} >
                <Grid container alignItems="center" direction="row" justify="center">
                  <Grid item xs={2}>
                    <h3>Referral :</h3>
                  </Grid>
                  <Grid item xs={9}>
                    <TextValidator
                      name="referralType"
                      value={referralType || ''}
                      onChange={this.handleReferralChange}
                      label="Enter Referral Name (Default - SELF)"
                      inputProps={{
                        ref: this.referralRef,
                      }}
                    />
                    <Popper
                      open={openReferralSuggest !== undefined ? openReferralSuggest : false}
                      anchorEl={this.referralRef.current}
                      placement='bottom-start'
                    >
                      <Paper className={classes.listSuggestions} classes={{ root: classes.orgListStyle }}>
                        {referrals !== undefined ? referrals.map(item => {
                          return (
                            <div key={item.id} onClick={event => this.handleReferralClick(item.referrer_name, item.id)} className={classes.listhover}>
                              <Grid container component="main" className={classes.type}>
                                <Grid item xs={7}>
                                  <Typography variant="subtitle2">
                                    {item.referrer_name}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Divider />
                            </div>
                          );
                        }) : ''}
                      </Paper>
                    </Popper>
                    {registerError ?
                      <div className={classes.errorStyle}>
                        {referralError}
                      </div> : ''
                    }
                  </Grid>

                  <Grid item xs={1} >
                    <Grid container justify='flex-end'>
                      <AddCircleIcon
                        color="primary"
                        fontSize="default"
                      />
                      {referralModal}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  name="area"
                  value={area}
                  onChange={this.handleTextChange}
                  validators={['maxStringLength: 60', 'isString']}
                  errorMessages={['Invalid Text']}
                  label="Area"
                  inputProps={{
                    maxLength: 99
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  name="city"
                  value={city}
                  onChange={this.handleTextChange}
                  validators={['maxStringLength: 60', 'isString']}
                  errorMessages={['Invalid Text']}
                  label="City"
                  inputProps={{
                    maxLength: 74
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  name="pincode"
                  value={pincode}
                  onChange={this.handleTextChange}
                  validators={['maxStringLength: 6', 'matchRegexp:^[1-9]{1}[0-9]{5}$']}
                  errorMessages={['Invalid Pincode']}
                  label="Pincode"
                  inputProps={{
                    maxLength: 6
                  }}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <TextValidator
                  name="passport"
                  value={passport}
                  onChange={this.handleTextChange}
                  validators={['maxStringLength: 9', 'matchRegexp:[a-zA-Z]{2}[0-9]{7}']}
                  errorMessages={['Invalid Passport Number']}
                  label="Passport Number"
                  inputProps={{
                    maxLength: 9
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  name="history"
                  value={history}
                  onChange={this.handleTextChange}
                  validators={['maxStringLength: 100', 'isString']}
                  errorMessages={['Invalid Data']}
                  label="Clinical History"
                  inputProps={{
                    maxLength: 100
                  }}
                />
              </Grid> */}

              <Grid container direction="column" justify="center" alignItems="center">
                {registerError ?
                  <div className={classes.errorStyle}>
                    {registerErrorMessage}
                  </div>
                  : ''
                }
                {existingDataFlag !== true ?
                  <div>
                    <Button
                      fullWidth
                      type="submit"
                      size='large'
                      color='primary'
                      variant='contained'
                    >Register</Button>
                    <div>You are registering <b>new</b> patient </div>
                  </div> :

                  <Grid container direction="row" justify="flex-end" spacing={4}>
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        type="submit"
                        size='large'
                        color='primary'
                        variant='contained'
                        className={classes.buttonText}
                      > Update </Button>
                    </Grid>

                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        className={classes.buttonText}
                        type="submit"
                        size='large'
                        color='default'
                        variant='outlined'
                        onClick={() => this.setState({ flag: true })}
                      > Update & proceed to Billing </Button>
                    </Grid>
                    <Grid container justify="center">
                      <div className={classes.errorStyle}>You are <b>updating existing</b> patient </div>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>

          </ValidatorForm>
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  patientId: state.login.patientCount,
  patientTypes: state.login.patientType,
  patientMobiles: state.login.patientMobile,
  patientCurrentLocation: state.login.patientCurrentLocation,
  patientOtherLocation: state.login.patientOtherLocation,
  organisations: state.login.organisation,
  referrals: state.login.referral,
  //Errors

  registerError: state.login.registerError,
  registerErrorMessage: state.login.registerErrorMessage,
  ageError: state.login.errorMessages.ageError,
  altMobileError: state.login.errorMessages.altMobileError,
  altCountryCodeError: state.login.errorMessages.altCountryCodeError,
  mobileError: state.login.errorMessages.mobileError,
  mobileCountryCodeError: state.login.errorMessages.mobileCountryCodeError,
  designationError: state.login.errorMessages.designationError,
  emailError: state.login.errorMessages.emailError,
  genderError: state.login.errorMessages.genderError,
  organisationError: state.login.errorMessages.organisationError,
  patientNameError: state.login.errorMessages.patientNameError,
  patientTypeError: state.login.errorMessages.patientTypeError,
  referralError: state.login.errorMessages.referralError,
})

export default connect(mapStateToProps,
  {
    getPatientCount,
    patientType,
    getPatientMobile,
    getPatientName,
    getOrganisations,
    getReferrals,
    submitRegistration,
    updateRegistration
  }
)(Registration);