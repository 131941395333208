import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';

import {
  Container,
  Grid,
  Typography,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  // Link,
  // Table,
  // TableRow,
  // TableCell,
  // TableHead,
  // TableBody,
  Select,
  MenuItem,
  Menu,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { Close, ArrowDropUp } from '@material-ui/icons';
import { getBill, searchReferral, searchOrganization, updateBill, referralList, cancelBill } from '../../../Store/ActionCreators';

import classes from '../../../App.module.css';

var Total = 0, Percentage_Concession = 0;
class EditBill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order_number: 0,
      is_emergency_report: '0',
      bill_date: null,
      concession_type: 1,
      concession_value: 0,
      additional_price: 0,
      remarks: '',
      comments: '',
      other_referral: 'DIRECT',
      report_collection_type: 1,
      additional_services: '',
      patient_tests: [],
      deleted_tests: [],
      test_details: [],
      type_of_referral: '',
      referrer_name: '',

      test_concession_disable: false,
      openEdit: false,
      anchorEl: null,
      billAnchor: null,
      display_tests: [],
      cancel_bill_comments: '',
      error_cancel_bill: false,
      openRemoveBillPopUp: false,
      removeBillWarning: false,
      openRemoveTestPopUp: false,
      cancel_test_comments: '',
      error_cancel_test: false,
      openUpdateBillPopUp: false,
      update_bill_comments: '',
      error_update_bill: false,
      is_email_to_patient_update: false,
      is_send_sms_to_organisation_update: false,
      is_send_sms_to_patient_update: false,
      lock_bill: false,
      enableConcession: false,
    }
    this.handleBill()
  }

  handleBill = async () => {
    const pathName = window.location.pathname.split('/')
    if (pathName !== undefined && pathName[2] !== undefined) {
      const mobile = pathName[2].toString();
      await this.props.getBill({ billref: mobile })
    } else window.location.replace('/bill-settlements')
    if (this.props.bill.patientdetails !== undefined) {
      this.props.bill.billtestdetails.map(item => {
        this.setState({
          patient_tests: [...this.state.patient_tests, {
            billtestref: item.id,
            // price_type: item.price_type,
            test_id: item.test_id,
            test_price_id: item.test_price_id,
            organisation_id: item.organisation_id,
            test_name: item.testdetails.test_name,
            test_price: item.test_price,
            test_discount_price: item.test_discount_price,
            test_payable_amount: item.test_payable_amount,
            outsource_type: item.outsourced_status,
            // concession_type: this.state.concession_type
          }]
        })
      })
      this.setState({
        billref: this.props.bill.id,
        patient_id: this.props.bill.patient_id,
        organisation_id: this.props.bill.patientdetails.organisationdetails !== null ? 
          this.props.bill.patientdetails.organisationdetails.id : '',
        total_price: this.props.bill.total_price,
        discount_price: this.props.bill.discount_price,
        paid_amount: this.props.bill.paid_amount,
        payable_amount: parseInt(this.props.bill.payable_amount),
        due_amount: this.props.bill.due_amount,
        is_emergency_report: this.props.bill.billtestdetails[0].is_emergency_report,
        type_of_referral: this.props.bill.patientdetails.referraldetails !== null ? 
          this.props.bill.patientdetails.referraldetails.referrer_name : '',
        referrer_name: this.props.bill.patientdetails.referraldetails !== null ? 
          this.props.bill.patientdetails.referraldetails.id : '',
        additional_price: this.props.bill.additional_price,
        concession_type: this.props.bill.concession_type,
        concession_value: this.props.bill.concession_value,
        bill_date: this.props.bill.created_at,
        comments: this.props.bill.comments,
        remarks: this.props.bill.remarks,
        outsource_type: this.props.bill.outsourced_status,

        display_tests: this.state.patient_tests,
      })
    }
    this.state.display_tests.map( item => {
      if(item.test_discount_price !== 0 ) {
        this.setState({ enableConcession: true })
      }
    })
    await this.props.referralList()
  }

  handleConcessionAmountChange = (display_tests) => {
    let enable = false;
    display_tests.map( item => {
      if(parseInt(item.test_discount_price) !== 0 ) {
        enable = true;
      }
    })
    if(enable) {
      this.setState({ enableConcession: true })
    } else {
      this.setState({ enableConcession: false, concession_value: 0 })
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleKeyPress = (e) => {
    const { concession_type, concession_value } = this.state;
    let total_concession_bill = 0, newArray = [...this.state.display_tests];

    this.state.display_tests.map((item, index) => {
      if (concession_type === '1') {
        if (!isNaN((Percentage_Concession * item.test_price) / 100)) {
          total_concession_bill = Math.round(((Percentage_Concession * item.test_price) / 100))
        } else total_concession_bill = item.test_discount_price;
      } else if (!isNaN((concession_value * item.test_price) / 100)) {
        total_concession_bill = Math.round(((concession_value * item.test_price) / 100))
      } else total_concession_bill = Math.round(((concession_value * item.test_price) / 100))
        newArray[index] = { ...newArray[index], 
        total_concession: parseInt(total_concession_bill),
        test_discount_price: parseInt(total_concession_bill) 
      }
    })
    this.setState({ display_tests: newArray })
  }

  handleSearchChange = async (name, value) => {
    if (!value) {
      this.setState({ [name]: value })
    } else {
      await this.props.searchReferral({ referral: value });
    }
  }

  handleOrganizationSearch = async (name, value) => {
    if (!value) {
      this.setState({ [name]: value })
    } else {
      await this.props.searchOrganization({ organisation: value })
    }
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleDateChange = (date) => {
    this.setState({ bill_date: date })
  }

  // handleDialogSlider = () => {
  //   let obj = [
  //     {
  //       t: 'assdas',
  //       u: 23423,
  //       v: 'Edit'
  //     },
  //     {
  //       t: 'werwer',
  //       u: 4345,
  //       v: 'Edit'
  //     }
  //   ]
  //   const { openSlider, is_send_sms_to_patient, is_send_sms_to_organisation, warning, barcode } = this.state;
  //   return (
  //     <Dialog
  //       open={false}
  //       keepMounted
  //       maxWidth='md'
  //       onClose={this.handleClose}
  //       aria-labelledby="alert-dialog-slide-title"
  //       aria-describedby="alert-dialog-slide-description"
  //     >
  //       <Grid container>
  //         <Grid item xs={11} className={classes.type}>
  //           <Typography>Add Payment</Typography>
  //         </Grid>
  //         <Grid item xs={1}>
  //           <Grid container justify='flex-end'>
  //             <IconButton onClick={this.handleClose} >
  //               <Close fontSize="small" />
  //             </IconButton>
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //       <Divider />
  //       <DialogContent>
  //         <Grid container spacing={2}>
  //           <Grid item xs={4}>
  //             <TextField
  //               fullWidth
  //               variant='outlined'
  //               margin='dense'
  //             />
  //           </Grid>
  //           <Grid item xs={2}>
  //             <TextField
  //               fullWidth
  //               variant='outlined'
  //               margin='dense'
  //             />
  //           </Grid>
  //           <Grid item xs={2}>
  //             <TextField
  //               fullWidth
  //               variant='outlined'
  //               margin='dense'
  //             />
  //           </Grid>
  //           <Grid item xs={3}>
  //             <TextField
  //               fullWidth
  //               variant='outlined'
  //               margin='dense'
  //             />
  //           </Grid>
  //           <Grid item xs={1}>
  //             <IconButton onClick={this.handleClose} >
  //               <Close fontSize="small" />
  //             </IconButton>
  //           </Grid>
  //           <Grid item xs={12}>
  //             <Typography>
  //               <Link>+Add</Link>
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //       </DialogContent>

  //       <Divider />

  //       <DialogContent>
  //         <Table aria-label='simple table'>
  //           <TableHead style={{ minWidth: 650 }} >
  //             <TableRow>
  //               <TableCell style={{ fontWeight: '600' }}>Transaction Date</TableCell>
  //               <TableCell style={{ fontWeight: '600' }} align='left'>Payment Type</TableCell>
  //               <TableCell style={{ fontWeight: '600' }} align='left'>Test Name</TableCell>
  //               <TableCell style={{ fontWeight: '600' }} align='left'>Bank Details</TableCell>
  //               <TableCell style={{ fontWeight: '600' }} align='left'>Amount</TableCell>
  //               <TableCell style={{ fontWeight: '600' }} align='left'>After Transaction</TableCell>
  //               <TableCell style={{ fontWeight: '600' }} align='left'>Lab User Name</TableCell>
  //               <TableCell style={{ fontWeight: '600' }} align='left'></TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {obj.map((item, index) => (
  //               <TableRow key={index}>
  //                 <TableCell>{item.t}</TableCell>
  //                 <TableCell>{item.u}</TableCell>
  //                 <TableCell>{item.v}</TableCell>
  //                 <TableCell>{item.v}</TableCell>
  //                 <TableCell>{item.v}</TableCell>
  //                 <TableCell>{item.v}</TableCell>
  //                 <TableCell>{item.v}</TableCell>
  //                 <TableCell>
  //                   <IconButton onClick={this.handleClose} >
  //                     <Close fontSize="small" />
  //                   </IconButton>
  //                 </TableCell>
  //               </TableRow>
  //             ))}
  //           </TableBody>
  //         </Table>
  //       </DialogContent>

  //       <Divider />

  //       <DialogActions>
  //         <Grid container className={classes.type}>
  //           <Grid item xs={6}>
  //             <Button onClick={this.handleClose} variant='outlined' className={classes.buttonText} color="primary">
  //               Close
  //             </Button> &nbsp;&nbsp;
  //           </Grid>
  //           <Grid item xs={6}>
  //             <Grid container justify='flex-end'>
  //               <Button onClick={this.handleAddIntoExistingBill} variant='contained' className={classes.buttonText} color="primary">
  //                 Submit
  //               </Button>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       </DialogActions>
  //     </Dialog>
  //   )
  // }

  handleRemoveTest = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleRemoveTestClose = () => {
    this.setState({ anchorEl: null })
  }

  handleRemoveBill = event => {
    this.setState({ billAnchor: event.currentTarget })
  }

  handleRemoveBillClose = () => {
    this.setState({ billAnchor: null })
  }

  handleOpenEdit = (item) => {
    this.setState({ openEdit: true, test_amount: item.test_price, test_concession: item.test_discount_price, test_details: item, 
      test_concession_disable: item.outsource_type === '2' ? true : false
    })
  }

  handleCloseEdit = () => {
    this.setState({ openEdit: false, anchorEl: null })
  }

  handleRemoveTestPopUp = () => {
    this.setState({ openRemoveTestPopUp: true, openEdit: false })
    this.handleRemoveTestClose()
  }

  handleRemoveTestPopUpClose = () => {
    this.setState({ openRemoveTestPopUp: false })
  }

  handleCancelTestPopUp = () => {
    const { openRemoveTestPopUp, error_cancel_test, cancel_test_comments } = this.state;
    return (
      <Dialog
        open={openRemoveTestPopUp}
        fullWidth
        keepMounted
        maxWidth='md'
        onClose={this.handleRemoveTestPopUpClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container>
          <Grid item xs={11} className={classes.type}>
            <Typography variant='h6'>Confirm Bill Removal</Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid container justify='flex-end'>
              <IconButton onClick={this.handleRemoveTestPopUpClose} >
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <DialogContent>
          <Grid container justify='center' alignItems='center' spacing={1}>
            <Grid item xs={12}>
              <Typography>
                Are you sure you want to remove this Test? This test and all the associated details will be completely
                removed from the system and there is no option to revert this back. Click No if you are not sure.
              </Typography>
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography variant='h6'>
                  Comments :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name='cancel_test_comments'
                value={cancel_test_comments}
                onChange={this.handleChange}
                error={error_cancel_test}
                multiline
                fullWidth
                variant='outlined'
              />
            </Grid>
          </Grid>
        </DialogContent>

        <Divider />

        <DialogActions>
          <div className={classes.type}>
            <Button onClick={this.handleRemoveTestPopUpClose} variant='outlined' className={classes.buttonText} color="primary">
              No! I clicked this by mistake
            </Button> &nbsp;&nbsp;
            <Button onClick={this.handleCancelTest} variant='contained' className={classes.buttonText} color="primary">
              Yes! I'm sure
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  handleCancelTest = () => {
    const { test_details, patient_tests, deleted_tests, cancel_test_comments } = this.state;
    if (cancel_test_comments !== '') {
      let updatedTestList = patient_tests.filter(item => item.billtestref !== test_details.billtestref)
      let deletedTestList = patient_tests.filter(item => item.billtestref === test_details.billtestref)
      var joined = deleted_tests.concat(deletedTestList);
      this.setState({ patient_tests: updatedTestList, deleted_tests: joined, openRemoveTestPopUp: false })
      this.handleCloseEdit()
    } else this.setState({ error_cancel_test: true })
  }

  handleSaveEditTestChanges = () => {
    const { test_details, test_amount, test_concession } = this.state;
    const elementsIndex = this.state.display_tests.findIndex(element => element.billtestref === test_details.billtestref)
    let newArray = [...this.state.display_tests];
    newArray[elementsIndex] = { ...newArray[elementsIndex], 
      test_price: test_amount, 
      test_discount_price: !isNaN(parseInt(test_concession)) ? parseInt(test_concession) : 0,
      total_concession: !isNaN(parseInt(test_concession)) ? parseInt(test_concession) : 0,
    }
    let discount = 0;
    newArray.map(item => {
      discount = discount + item.total_concession;
    })
    this.setState({ display_tests: newArray, concession_value: discount, patient_tests: newArray });
    this.handleCloseEdit();
    this.handleConcessionAmountChange(newArray)
  }

  handleEditSlider = () => {
    const { openEdit, test_details, test_amount, test_concession, anchorEl, test_concession_disable } = this.state;
    return (
      <Dialog
        open={openEdit}
        fullWidth
        keepMounted
        maxWidth='md'
        onClose={this.handleCloseEdit}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container>
          <Grid item xs={11} className={classes.type}>
            <Typography variant='h6'>Edit Bill Test</Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid container justify='flex-end'>
              <IconButton onClick={this.handleCloseEdit} >
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <DialogContent>
          <Grid container justify='center' alignItems='center' spacing={1}>
            <Grid item xs={12}>
              <Typography variant='h6'><b>{test_details.test_name}</b></Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography>
                  Test Quantity :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                disabled
                fullWidth
                type='number'
                margin='dense'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography>
                  Selling Price :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                disabled
                fullWidth
                type='number'
                margin='dense'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography>
                  Selling Price :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                disabled
                fullWidth
                type='number'
                margin='dense'
                variant='outlined'
                placeholder='Minimum Selling Price'
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography>
                  Amount :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name='test_amount'
                value={test_amount}
                onChange={this.handleChange}
                fullWidth
                type='number'
                margin='dense'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography>
                  Concession :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name='test_concession'
                value={test_concession}
                disabled={test_concession_disable}
                onChange={this.handleChange}
                fullWidth
                type='number'
                margin='dense'
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            <Grid item xs={12}>
              <Typography variant='h6'>Outsource Details</Typography>
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography>
                  Outsource :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                disabled
                fullWidth
                type='number'
                margin='dense'
                variant='outlined'
              />
            </Grid>

            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography>
                  Amount :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                disabled
                fullWidth
                type='number'
                margin='dense'
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            <Grid item xs={12}>
              <Typography variant='h6'>Doctor Revenue Details</Typography>
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography>
                  Doctor :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                disabled
                fullWidth
                type='number'
                margin='dense'
                variant='outlined'
              />
            </Grid>

            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography>
                  Amount :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                disabled
                fullWidth
                type='number'
                margin='dense'
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
          </Grid>
        </DialogContent>

        <Divider />

        <DialogActions>
          <Grid container className={classes.type}>
            <Grid item xs={6}>
              <Button onClick={this.handleCloseEdit} variant='outlined' className={classes.buttonText} color="primary">
                Close
              </Button>&nbsp;&nbsp;
              <Button
                variant='contained'
                color='secondary'
                className={clsx(classes.buttonText, classes.buttonAccession)}
                onClick={this.handleRemoveTest}
              >
                Remove Test<ArrowDropUp fontSize='small' />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleRemoveTestClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={this.handleRemoveTestPopUp}>Just Cancel Test</MenuItem>
                {/* <MenuItem>Cancel with Add Test Amount to Advance</MenuItem> */}
              </Menu>
            </Grid>
            <Grid item xs={6}>
              <Grid container justify='flex-end'>
                <Button
                  variant='contained'
                  className={classes.buttonText}
                  color="primary"
                  onClick={this.handleSaveEditTestChanges}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }

  handleRemoveBillPopUp = () => {
    this.setState({ openRemoveBillPopUp: true, billAnchor: null })
  }

  handleRemoveBillPopUpClose = () => {
    this.setState({ openRemoveBillPopUp: false })
  }

  handleRemoveBillSlider = () => {
    const { openRemoveBillPopUp, removeBillWarning, error_cancel_bill, cancel_bill_comments, is_send_sms_to_organisation } = this.state;
    return (
      <Dialog
        open={openRemoveBillPopUp}
        fullWidth
        keepMounted
        maxWidth='md'
        onClose={this.handleRemoveBillPopUpClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container>
          <Grid item xs={11} className={classes.type}>
            <Typography variant='h6'>Do you want to cancel this bill?</Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid container justify='flex-end'>
              <IconButton onClick={this.handleRemoveBillPopUpClose} >
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <DialogContent>
          <Grid container justify='center' alignItems='center' spacing={1}>
            <Grid item xs={12}>
              <Typography>
                Are you sure you want to cancel this Bill? This bill and all the associated reports will be canceled from the system.
                Click No if you are not sure.
              </Typography>
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            {
              removeBillWarning ?
                <Grid item xs={12} className={clsx(classes.type, classes.warningStyles, classes.cursorStyle)}>
                  <Grid container>
                    <Grid item xs={11} className={classes.type}>
                      <Typography> Please write comment.</Typography>
                    </Grid>
                    <Grid item xs={1} className={classes.type}>
                      <Close onClick={e => this.setState({ removeBillWarning: false })} />
                    </Grid>
                  </Grid>
                </Grid>
                : ''
            }

            <Grid item xs={4}>
              <Grid container justify='flex-end'>
                <Typography variant='h6'>
                  Comments :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                name='cancel_bill_comments'
                value={cancel_bill_comments}
                onChange={this.handleChange}
                error={error_cancel_bill}
                multiline
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                name='is_send_sms_to_organisation'
                value={is_send_sms_to_organisation}
                control={<Checkbox color='primary' />}
                label='Send SMS to Organization'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={clsx(classes.errorMessage, classes.padding)}>
            <Typography variant='subtitle2'>This will set your bill amount to zero and all payment will get deleted.
            This action not reversible.
            </Typography>
          </Grid>
        </DialogContent>

        <Divider />

        <DialogActions>
          <div className={classes.type}>
            <Button onClick={this.handleRemoveBillPopUpClose} variant='outlined' className={classes.buttonText} color="primary">
              No! I clicked this by mistake
            </Button> &nbsp;&nbsp;
            <Button onClick={this.handleCancelBill} variant='contained' className={classes.buttonText} color="primary">
              Yes! I'm sure
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  handleUpdateBillPopUpClose = () => {
    this.setState({ openUpdateBillPopUp: false })
  }

  handleUpdateBillPopUpOpen = () => {
    if (this.state.comments !== '') {
      if (this.state.comments !== null) {
        this.setState({ openUpdateBillPopUp: true, error_update_bill: false })
      } else this.setState({ error_update_bill: true })
    } else this.setState({ error_update_bill: true })
  }

  handleUpdateBillPopUp = () => {
    const { openUpdateBillPopUp, is_email_to_patient_update, is_send_sms_to_organisation_update, is_send_sms_to_patient_update, lock_bill } = this.state;
    return (
      <Dialog
        open={openUpdateBillPopUp}
        fullWidth
        keepMounted
        maxWidth='md'
        onClose={this.handleUpdateBillPopUpClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container>
          <Grid item xs={11} className={classes.type}>
            <Typography variant='h6'>Are you sure to update this bill?</Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid container justify='flex-end'>
              <IconButton onClick={this.handleUpdateBillPopUpClose} >
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <DialogContent>
          <Grid container justify='center' alignItems='center' spacing={1}>
            <Grid item xs={12}>
              <Typography>
                Are you sure you want to update this Bill? This bill and all the associated reports will be updated
                from the system. Click No if you are not sure.
              </Typography>
            </Grid>

            <Grid item xs={12}><Divider /></Grid>

            <Grid item xs={12}>
              <FormControlLabel
                name='is_send_sms_to_patient_update'
                value={is_send_sms_to_patient_update}
                control={<Checkbox color='primary' />}
                label='Send SMS to Patient'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                name='is_send_sms_to_organisation_update'
                value={is_send_sms_to_organisation_update}
                control={<Checkbox color='primary' />}
                label='Send SMS to Organization'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                name='is_email_to_patient_update'
                value={is_email_to_patient_update}
                control={<Checkbox color='primary' />}
                label='Send Email to Patient'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                name='lock_bill'
                value={lock_bill}
                control={<Checkbox color='primary' />}
                label='Lock bill'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <Divider />

        <DialogActions>
          <div className={classes.type}>
            <Button onClick={this.handleUpdateBillPopUpClose} variant='outlined' className={classes.buttonText} color="primary">
              No! I clicked this by mistake
            </Button> &nbsp;&nbsp;
            <Button onClick={this.handleUpdateBill} variant='contained' className={classes.buttonText} color="primary">
              Yes! I'm sure
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  handleUpdateBill = async (e) => {
    const { billref, patient_id, organisation_id, total_price, additional_price, concession_type, concession_value, discount_price,
      remarks, paid_amount, payable_amount, due_amount, is_emergency_report, deleted_tests, order_number, bill_date,
      comments, is_send_sms_to_patient_update, is_send_sms_to_organisation_update, is_email_to_patient_update, lock_bill, display_tests,
    } = this.state;
    let patientTestList = display_tests.filter(item => item.billtestref !== deleted_tests.billtestref)
    let deletedTestList = display_tests.filter(item => item.billtestref === deleted_tests.billtestref)
    let patient_tests = patientTestList;
    let deleted_test = deletedTestList;
    let data = {
      billref,
      patient_id,
      organisation_id,
      total_price,
      additional_price,
      concession_type,
      concession_value,
      discount_price,
      remarks,
      paid_amount,
      payable_amount,
      due_amount,
      patient_tests,
      deleted_test,
      order_number,
      bill_date,
      comments,
      is_emergency_report,
      is_send_sms_to_patient_update,
      is_send_sms_to_organisation_update,
      is_email_to_patient_update,
      lock_bill,
    }
    await this.props.updateBill(data);
    if (this.props.updateBillSuccess) {
      window.location.reload();
    }
  }

  handleCancelBill = async () => {
    const { billref, patient_id, cancel_bill_comments } = this.state;
    if (cancel_bill_comments !== '') {
      await this.props.cancelBill({ billref, patient_id })
    } else this.setState({ error_cancel_bill: true, removeBillWarning: true })
    if (this.props.cancelBillSuccess) {
      window.location.reload();
    }
  }

  render() {
    const { is_emergency_report, order_number, bill_date, concession_type, concession_value, additional_price, remarks,
      comments, other_referral, /* report_collection_type, additional_services, */ type_of_referral, openEdit, payable_amount,
      paid_amount, deleted_tests, due_amount, billAnchor, display_tests, openRemoveBillPopUp, openRemoveTestPopUp, patient_tests,
      openUpdateBillPopUp, error_update_bill, enableConcession,
    } = this.state;
    const { referral, bill } = this.props;
    let SumOfTests = 0;
    let Price_Concession = 0;
    let total_discount_price = 0;
    this.state.patient_tests.map(item => {
      total_discount_price = total_discount_price + parseInt(item.test_discount_price);
      return SumOfTests = SumOfTests + (parseInt(item.test_price) - parseInt(item.test_discount_price))
    })
    this.state.payable_amount = SumOfTests;

    let amountPay = 0;
      patient_tests.map( item => {
        amountPay = amountPay + parseInt(item.test_price) - (item.total_concession !== undefined ? parseInt(item.total_concession) : parseInt(item.test_discount_price))
      })
    Total = amountPay + parseInt(additional_price !== '' ? additional_price : 0) - (parseInt(this.state.discount_price) + parseInt(this.state.paid_amount))

    // if (this.state.additional_price !== '') {
    //   if (parseInt(concession_type) === 1) {
    //     if (concession_value !== '') {
    //       Total = SumOfTests + parseInt(additional_price) - parseInt(concession_value);
    //       Percentage_Concession = (parseInt(concession_value) * 100) / (SumOfTests);
    //     }
    //   } else {
    //     if (concession_value !== '') {
    //       Price_Concession = parseInt((parseInt(concession_value) * (SumOfTests)) / 100)
    //       Total = SumOfTests + parseInt(additional_price) - Price_Concession
    //     }
    //   }
    // }

    return (
      <Container maxWidth={false} classes={{ root: classes.containerPadding }}>
        {bill.patientdetails !== undefined ?
          <Grid container justify='center' alignItems='center' spacing={1}>
            <Grid item xs={12}>
              <Typography variant='h6'>Bill Update</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid><br />
            <Grid item xs={12}>
              <Typography>
                Bill ID: {bill.id}&nbsp;
                <span
                  className={bill.bill_payment_status === 'Completed' ? clsx(classes.textAlign, classes.textColor, classes.backgroundGreen) :
                    (bill.bill_payment_status === 'Pending' ? clsx(classes.textAlign, classes.textColor, classes.backgroundOrange) :
                      (bill.bill_payment_status === 'Cancelled' ? clsx(classes.textAlign, classes.textColor, classes.buttonColor) : ''))}
                >
                  {bill.bill_payment_status}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Name: Bill Of&nbsp;
                <span>
                  {bill.patientdetails.designation} {bill.patientdetails.patient_name}&nbsp;
                  ({bill.patientdetails.gender.charAt(0)} - {bill.patientdetails.age})
                </span>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Grid container justify='flex-start'>
                <Typography>
                  Order Number :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <TextField
                name='order_number'
                value={order_number}
                onChange={this.handleChange}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={1}>
              <Grid container justify='flex-start'>
                <Typography>
                  Referral :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                autoHighlight
                autoSelect
                disableClearable
                options={referral}
                name='referrer_name'
                getOptionLabel={option => {
                  if (typeof option !== 'object') {
                    if (typeof option === 'number') {
                      referral.map(item => {
                        if (item.id === option) {
                          this.setState({ type_of_referral: item.referrer_name, referrer_name: option });
                          return false
                        } else return false;
                      })
                    } else return option;
                  } else return option.referrer_name;
                }}
                value={type_of_referral}
                renderOption={option => option.referrer_name}
                onChange={(e, value) => {
                  this.setState({ referrer_name: value.id })
                  if (value !== null) {
                    this.setState({ referrer_name: value.id, type_of_referral: value.referrer_name })
                  }
                }}
                renderInput={params => (
                  <TextField
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Grid container justify='flex-end'>
                <Typography>
                  Organization : &nbsp;
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <TextField
                disabled
                value={bill.patientdetails.organisationdetails !== null ? bill.patientdetails.organisationdetails.organisation_name : ''}
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={1}>
              <Grid container justify='flex-start'>
                <Typography>
                  Date :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  inputVariant='outlined'
                  margin='dense'
                  fullWidth
                  style={{ marginTop: '0px' }}
                  type="datetime-local"
                  placeholder="Date picker"
                  format="dd/MM/yyyy hh:mm:ss"
                  value={bill_date}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={4}>
              {/* <TextField
                select
                name='additional_services'
                value={additional_services}
                onChange={this.handleChange}
                variant='outlined'
                margin='dense'
                fullWidth
              >
                <MenuItem value={1} key={1}>Additional Services (Default: None)</MenuItem>
                <MenuItem value={2} key={2}>Home Visit Services</MenuItem>
                <MenuItem value={3} key={3}>Inter Laboratory Services</MenuItem>
                <MenuItem value={4} key={4}>Health Camp</MenuItem>
                <MenuItem value={5} key={5}>Night Charges</MenuItem>
                <MenuItem value={6} key={6}>Other</MenuItem>
              </TextField> */}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={8}>
              <Typography>Test Name</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Test Amount (Concession)</Typography>
            </Grid>
            <Grid item xs={1}>
              <Grid container justify='flex-end'>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {
              display_tests.map((item, index) =>
                item !== undefined ?
                  <React.Fragment key={index}>
                    {/* <Grid item xs={1}></Grid> */}
                    <Grid item xs={8}>
                      &nbsp;&nbsp;
                      <Typography>
                        {item.test_name}&nbsp;
                        {
                          deleted_tests.length !== 0 ? (deleted_tests.map(li => li.billtestref === item.billtestref ?
                            <span key={index} className={clsx(classes.textAlign, classes.textColor, classes.backgroundBrown)}>Dismissed</span> : ''))
                            : ''
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>
                        {item.test_price}&nbsp;
                        {
                          item.total_concession !== undefined ? 
                          item.total_concession !== '0' ? '(' + item.total_concession + ')' : '' : 
                          item.test_discount_price !== '0' ? '(' + item.test_discount_price + ')' : '' 
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Grid container justify='flex-end'>
                        <Typography
                          color='primary'
                          className={classes.cursorStyle}
                          onClick={e => this.handleOpenEdit(item)}
                        >
                          Edit
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </React.Fragment>
                  : ''
              )
            }

            <Grid item xs={2}>
              <Grid container justify='flex-start'>
                <Typography>
                  Other Referral (if any) :
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <TextField
                name='other_referral'
                value={other_referral}
                onChange={this.handleChange}
                placeholder='Other Referral (Default: DIRECT)'
                variant='outlined'
                margin='dense'
                fullWidth
              />
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={4}>
              {/* <TextField
                select
                name='report_collection_type'
                value={report_collection_type}
                onChange={this.handleChange}
                variant='outlined'
                margin='dense'
                fullWidth
              >
                <MenuItem value={1} key={1}>Report Collection (Default: Personally)</MenuItem>
                <MenuItem value={2} key={2}>Home Delivery</MenuItem>
                <MenuItem value={3} key={3}>Courier</MenuItem>
              </TextField> */}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={2}>
              <Typography>
                Comments:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                name='comments'
                value={comments}
                error={error_update_bill}
                onChange={this.handleChange}
                placeholder='Comments'
                fullWidth
                multiline
                variant='outlined'
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={2}>
              <Typography>
                Remark :
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                name='remarks'
                value={remarks}
                onChange={this.handleChange}
                fullWidth
                multiline
                variant='outlined'
                placeholder='Remark'
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={3}>
              <Typography>
                Additional Price (if any):
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                name='additional_price'
                value={additional_price}
                onChange={this.handleChange}
                fullWidth
                type='number'
                variant='outlined'
                margin='dense'
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <Typography>View Payment</Typography>
            </Grid>

            <Grid item xs={3}>
              <Select
                name='concession_type'
                id='concession_type'
                value={concession_type}
                onChange={this.handleChange}
                variant='outlined'
                margin="dense"
              >
                <MenuItem key={1} value={1}>Concession (in INR)</MenuItem>
                <MenuItem key={2} value={2}>Concession (in %)</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3}>
              <TextField
                name='concession_value'
                value={concession_value}
                disabled={enableConcession}
                // ref={this.wrapperRef}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                fullWidth
                type='number'
                variant='outlined'
                margin='dense'
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <Grid container justify='center' alignItems='center' spacing={1}>
                <Grid item xs={12}>
                  <Grid container justify='center' className={clsx(classes.type, classes.expansionPanelColor, classes.borderColor)}>
                    <Typography>Erika's Due : INR </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography className={classes.branchDetailsStyle}>Payable Amount:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify='flex-start'>
                    <Typography className={classes.branchDetailsStyle}>INR {Total}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography>Advance Paid:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify='flex-start'>
                    &nbsp;
                    <TextField
                      value={bill.paid_amount}
                      disabled
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify='flex-end'>
                    <Typography>Balance remaining:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify='flex-start'>
                    &nbsp;<Typography>INR {Total - bill.paid_amount}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                name='is_emergency_report'
                value={is_emergency_report}
                checked={is_emergency_report === '1' ? true : false}
                control={<Checkbox color='primary' />}
                label='Critical / Emergency Reports.'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={12} className={clsx(classes.padding, classes.warningStyles)}>
              <i>
                Note : Concession on Discard Discounted test have to be adjust manually.
                Please verify your Bill concession, Bill additional amount, Advances and Payments in case of bill update.
              </i>
            </Grid>

            <Grid item xs={12} className={classes.dividerPadding}>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <Button
                variant='contained'
                color='secondary'
                className={clsx(classes.buttonText, classes.buttonAccession, classes.fontSize)}
                onClick={this.handleRemoveBill}
              >
                Remove Bill<ArrowDropUp fontSize='small' />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={billAnchor}
                keepMounted
                open={Boolean(billAnchor)}
                onClose={this.handleRemoveBillClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={this.handleRemoveBillPopUp}>Just Cancel Bill</MenuItem>
                {/* <MenuItem>Cancel with Deduct Bill Amount From Credit</MenuItem> */}
                {/* <MenuItem>Cancel & Clear Bill Payment & Amount</MenuItem> */}
              </Menu>
              &nbsp;
              <Button
                variant='outlined'
                className={classes.buttonText}
                disabled
              >
                Write Off Button
              </Button>&nbsp;
              <Button
                variant='outlined'
                className={classes.buttonText}
                disabled
              >
                Reset Bill
              </Button>&nbsp;
              <Button
                variant='outlined'
                className={classes.buttonText}
                disabled
              >
                Send Bill SMS To Patient
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Grid container justify='flex-end'>
                <Button
                  variant='contained'
                  color='primary'
                  className={clsx(classes.buttonText, classes.submitButton, classes.fontSize)}
                  onClick={this.handleUpdateBillPopUpOpen}
                >
                  Update
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {/* {this.handleDialogSlider()} */}
            </Grid>

            <Grid item xs={12}>
              {openEdit ? this.handleEditSlider() : ''}
            </Grid>

            <Grid item xs={12}>
              {openRemoveBillPopUp ? this.handleRemoveBillSlider() : ''}
            </Grid>

            <Grid item xs={12}>
              {openRemoveTestPopUp ? this.handleCancelTestPopUp() : ''}
            </Grid>

            <Grid item xs={12}>
              {openUpdateBillPopUp ? this.handleUpdateBillPopUp() : ''}
            </Grid>

          </Grid>
          : ''
        }
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  bill: state.login.bill,
  searchReferralList: state.login.searchReferralList,
  organizationSearchList: state.login.searchList,
  updateBillSuccess: state.login.updateBillSuccess,
  cancelBillSuccess: state.login.cancelBillSuccess,
  referral: state.login.referralList,
})

export default connect(mapStateToProps, {
  getBill,
  searchReferral,
  searchOrganization,
  updateBill,
  referralList,
  cancelBill,
})(EditBill);