import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';

import {
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
  Button,
  Divider,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import {
  TextValidator,
} from '../../Material UI';
import { updatePassword } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class UpdateProfile extends React.Component {
  static propTypes = {
    openDialog: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.userId,
      current_password: '',
      new_password: '',
      reenter_password: '',
      password_mismatch: false,
      error: false,
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleUpdatePassword = async () => {
    const { new_password, reenter_password, current_password } = this.state;
    if (new_password === '' || reenter_password === '' || current_password === '') {
      this.setState({ error: true })
    } else if (new_password === reenter_password) {
      await this.props.updatePassword(this.state);
      if (this.props.updatePasswordSuccess) {
        this.props.handleCloseDialog()
      }
    } else this.setState({ password_mismatch: true })
  }

  render() {
    const { current_password, new_password, reenter_password, password_mismatch, error } = this.state;
    const { openDialog, handleCloseDialog, currentPasswordError } = this.props;

    return (
      <Dialog open={openDialog} maxWidth='sm' classes={{ paper: clsx(classes.dialogWidth, classes.dialogHeight) }}>
        <Grid container direction='row' justify='center' alignItems='center' alignContent='center' className={classes.dividerPadding}>
          <Grid item xs={11}>
            <Typography>
              <span className={classes.collectButtonPadding}>
                Update Profile
              </span>
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Grid container justify='flex-end'>
              <IconButton onClick={handleCloseDialog}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} className={classes.updatePasswordHeight}>
            <Grid container>
              <Grid item xs={5}>
                <Grid container justify='flex-end'>
                  <Typography className={classes.type}>Enter current password&nbsp;&nbsp;</Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='current_password'
                  value={current_password}
                  onChange={this.handleChange}
                  fullWidth
                  variant='outlined'
                  margin='dense'
                  label='Current Password'
                  error={current_password === '' && error ? true : false}
                />
                {currentPasswordError !== [] ? <div className={classes.errorStyle}>{currentPasswordError}</div> : ''}
              </Grid>

              <Grid item xs={5}>
                <Grid container justify='flex-end'>
                  <Typography className={classes.type}>Enter new password&nbsp;&nbsp;</Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='new_password'
                  value={new_password}
                  onChange={this.handleChange}
                  type='password'
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='New password'
                  error={new_password === '' && error ? true : false}
                />
              </Grid>

              <Grid item xs={5}></Grid>
              <Grid item xs={6}>
                <Typography variant='body2'>* Password should contain at least one small letter, one capital letter,
                one digit,one special character & greater than 8 characters length
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <Grid container justify='flex-end'>
                  <Typography className={classes.type}>Re-enter password&nbsp;&nbsp;</Typography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='reenter_password'
                  value={reenter_password}
                  onChange={this.handleChange}
                  type='password'
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  label='Confirm password'
                  error={reenter_password === '' && error ? true : false}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='center'>
                  {password_mismatch === true ? <div className={classes.errorStyle}>New Password, Re-entered Password are not same</div> : ''}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.dividerPadding} />
          </Grid>

          <Grid item xs={1} className={classes.collectButtonPadding}>
            <Button
              variant='outlined'
              color='primary'
              className={classes.buttonAccession}
              onClick={handleCloseDialog}
            >
              Close
            </Button>
          </Grid>

          <Grid item xs={11}>
            <Grid container justify='flex-end'>
              <Button
                variant='contained'
                color='primary'
                className={classes.buttonAccession}
                onClick={this.handleUpdatePassword}
              >
                Update Password
                </Button>&nbsp;&nbsp;
              </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  updatePasswordSuccess: state.login.updatePasswordSuccess,
  currentPasswordError: state.login.updatePasswordError.currentPasswordError,
})

export default connect(mapStateToProps, {
  updatePassword,
})(UpdateProfile);