import React from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

import PDFHelper from '../../../../Utilities/PDFHelper';

import { getBarcodesData, updateBillWiseReceiveAll } from '../../../../Store/ActionCreators';

import classes from '../../../../App.module.css';

class ButtonRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: -1,
      anchorEl: null,
    }
  }

  handleOptionsClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleOptionsClose = () => {
    this.setState({ anchorEl: null })
  };

  handlePrintClick = (keys) => {
    this.handleReceivePrintAllClick(keys)
  }

  handleReceiveAll = () => {
    let item = this.props.node.data;
    return (
      <Button variant='contained' color='primary' className={classes.buttonAccession} onClick={e => this.handleReceiveAllClick({ billref: item.bill_id })}>Receive All</Button>
    )
  }

  handleReceiveAllClick = (billdata) => {
    var props = this.props;
    this.props.updateBillWiseReceiveAll(billdata).then(function (res) {
      if (res.payload.status === 200 && res.payload.data.status) {
        props.agGridReact.gridOptions.rowData.forEach((currentRow, index) => {
          if (currentRow.bill_id === parseInt(billdata.billref)) {
            props.agGridReact.gridOptions.rowData.splice(index, 1);
            props.api.setRowData(props.agGridReact.gridOptions.rowData)
          }
        })
      }
    }, function (e) {
      console.log(e);
    })
  }

  handleReceivePrintAll = () => {
    let item = this.props.node.data;
    return (
      <Button variant='contained' onClick={e => this.handleReceivePrintAllClick({ billref: item.bill_id, testref: JSON.parse(item.testrefs) })} color='primary' className={classes.buttonAccession}>Receive & Print All</Button>
    )
  }

  handleReceivePrintAllClick = async (billdata) => {
    var props = this.props;
    await this.props.updateBillWiseReceiveAll(billdata);
    await this.props.getBarcodesData(billdata);
    if (this.props.receiveAllStatus) {
      if (this.props.barCode.length > 0) {
        let printableData = {
          printable: this.props.barCode,
          type: 'pdf',
          base64: true,
        }
        PDFHelper.printPDF(printableData);
      }
      props.agGridReact.gridOptions.rowData.forEach((currentRow, index) => {
        if (currentRow.bill_id === parseInt(billdata.billref)) {
          props.agGridReact.gridOptions.rowData.splice(index, 1);
          props.api.setRowData(props.agGridReact.gridOptions.rowData)
        }
      })
    }
  }

  render() {
    let item = this.props.node.data;
    const { anchorEl } = this.state;
    return (
      <Grid container>
        <Grid item xs={9}>
          {item.bill_date}&nbsp;&nbsp;&nbsp;&nbsp;
        </Grid>
        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            <MoreVert aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleOptionsClick}>
            </MoreVert>
          </Grid>
        </Grid>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleOptionsClose}
        >
          <MenuItem onClick={e => this.handlePrintClick({ billref: item.bill_id, testref: JSON.parse(item.testrefs) })}>Print</MenuItem>
          <MenuItem disabled onClick={this.handleOptionsClose}>Dismiss Sample</MenuItem>
        </Menu>
        <Grid item xs={12} style={{ paddingTop: '10px' }}>
          <Grid container justify='flex-end' spacing={1}>
            <Grid item>{this.handleReceiveAll()}</Grid>
            <Grid item>{this.handleReceivePrintAll()}</Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  barCode: state.login.barCode,
  receiveAllStatus: state.login.receiveAllStatus,
})

export default connect(mapStateToProps, { getBarcodesData, updateBillWiseReceiveAll })(ButtonRenderer);

