import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  AppBar,
  Toolbar,
  Dialog,
  IconButton,
  InputBase,
  Grid,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Button,
  Avatar,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ChatIcon from '@material-ui/icons/Chat';
import BusinessIcon from '@material-ui/icons/Business';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import UpdateProfile from '../Profile/UpdateProfile';
import { logout, profile } from '../Store/ActionCreators';

import LucidWhiteLogo from '../Images/lucidJPG.jpg';
import classes from '../App.module.css';

class HeaderAppBar extends React.Component {
  static propTypes = {
    openDrawer: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      needHelp: '',
      openHelpPopper: false,
      openProfile: false,
      openUpdateProfile: false,
    }
    this.helpPopperRef = React.createRef();
    this.profileRef = React.createRef();
  }

  componentDidMount() {
    this.props.profile();
  }

  handleSearchChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleOpenHelp = (e) => {
    const open = this.state.needHelp ? '' : e.currentTarget
    this.setState({ needHelp: open, openHelpPopper: !this.state.openHelpPopper })
  }

  handleCloseHelp = (e) => {
    this.setState({ openHelpPopper: !this.state.openHelpPopper })
  }

  handleOpenProfile = (e) => {
    this.setState({ openProfile: !this.state.openProfile })
  }

  handleCloseProfile = (e) => {
    this.setState({ openProfile: !this.state.openProfile })
  }
  
  handleUpdateProfile = async() => {
    await this.props.profile();
    this.setState({ openUpdateProfile: true })
    this.handleCloseProfile()
  }

  handleUpdateProfileClose = () => {
    this.setState({ openUpdateProfile: false })
  }

  handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.setState({ openHelpPopper: !this.state.openHelpPopper })
    }
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { search, openHelpPopper, openProfile, openUpdateProfile } = this.state;
    const { openDrawer, toggleDrawer, profileData } = this.props;
    return (
      <Grid container alignContent="center">
        <Grid container alignContent="center" >
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: openDrawer
            })}
          >
            <Toolbar>
              <Grid container direction="row">
                <Grid item xs={6}>
                  <Grid container direction="row" alignItems="center" spacing={2}>
                    <IconButton
                      onClick={toggleDrawer}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>

                    <Grid item></Grid>
                      <img src={LucidWhiteLogo} alt="!" className={classes.logoWhite} />
                    <Grid item xs={2}></Grid>

                    <Grid item xs={6}>
                      <Paper component="form">
                        <InputBase
                          name="search"
                          value={search}
                          className={classes.search}
                          onChange={this.handleSearchChange}
                          placeholder="Search for Anything"
                          inputProps={{ 'aria-label': 'search google maps' }}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid container direction="row" justify="flex-end" className={classes.textAlign}>
                    <Button
                      className={classes.buttonShadow}
                      variant="contained"
                      color="primary"
                      onClick={this.handleOpenHelp}
                      ref={this.helpPopperRef}
                    >
                      <ChatIcon />&nbsp;
                      Need Help ?
                    </Button>
                    <Popover
                      open={openHelpPopper}
                      anchorEl={this.helpPopperRef.current}
                      onClose={this.handleCloseHelp}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Paper>
                        <MenuList onKeyDown={this.handleListKeyDown}>
                          <MenuItem onClick={this.handleCloseHelp}>Submit Support Query</MenuItem>
                          <MenuItem onClick={this.handleCloseHelp}>Request Machine Interfacing</MenuItem>
                          <MenuItem onClick={this.handleCloseHelp}>Request a Call Back</MenuItem>
                        </MenuList>
                      </Paper>
                    </Popover>

                    <Button 
                      className={classes.buttonShadow}
                      variant="contained"
                      color="primary"
                    >
                      <BusinessIcon />&nbsp;
                      Clinical Biochemistry
                    </Button>
                    

                    <Button 
                      className={classes.buttonShadow}
                      variant="contained"
                      color="primary"
                      onClick={this.handleOpenProfile}
                      ref={this.profileRef}
                    >
                      <Avatar
                        style={{
                          width: "25px",
                          height: "25px",
                        }}
                        src={profileData.profile_picture}
                      />&nbsp;
                      {profileData.full_name}
                    </Button>
                    <Popover
                      open={openProfile}
                      anchorEl={this.profileRef.current}
                      onClose={this.handleCloseProfile}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <Paper>
                        <MenuList>
                          <MenuItem onClick={this.handleUpdateProfile}>Update Profile</MenuItem>
                          <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                        </MenuList>
                      </Paper>
                    </Popover>
                    {openUpdateProfile ? 
                      <UpdateProfile
                        openDialog={true}
                        handleCloseDialog={this.handleUpdateProfileClose}
                        profileData={profileData}
                      /> 
                      : ''
                    }
                  </Grid>
                </Grid>
              </Grid>

            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.login.profile
})

export default connect(mapStateToProps, { logout, profile })(HeaderAppBar);