import React from 'react';
import PropTypes from 'prop-types';

import {
  TableCell,
  TableSortLabel,
  IconButton,
  TextField,
} from '@material-ui/core';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import classes from '../../App.module.css';

class TableHeadSort extends React.Component {
  static propTypes = {
    headCells: PropTypes.array.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    handleButtonClick: PropTypes.func.isRequired,
    openSearch: PropTypes.bool.isRequired,
    enableIcon: PropTypes.bool.isRequired,
  }

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { headCells, order, orderBy, handleButtonClick, openSearch, enableIcon } = this.props;
    return (
      headCells.map(headCell => (
        <TableCell
          style={{ fontWeight: '600' }}
          // align="center"
          key={headCell.id}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={this.createSortHandler(headCell.id)}
          >
            {headCell.label}
          </TableSortLabel>
          { 
            enableIcon ?
              <IconButton
                className={classes.buttonPadding}
                onClick={handleButtonClick}
              >
                {openSearch ? <KeyboardArrowUpIcon fontSize='small' /> : <KeyboardArrowDownIcon fontSize='small' />}
              </IconButton> 
            : ''
          }

        </TableCell>
      ))
    )
  }
}

export default TableHeadSort;