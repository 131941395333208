import React from 'react';
import clsx from 'clsx';

import {
  Container,
  Grid,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';

import QuickSettings from './QuickSettings';

import classes from '../../App.module.css';
import AdvancedSettings from './AdvancedSettings';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings_type: 0,
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ settings_type: newValue })
  }

  handleTabEvent = index => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  render() {
    const { settings_type } = this.state;
    return (
      <Container component="main" maxWidth={false} classes={{root: classes.containerPadding}} className={classes.expansionPanelColor}>
        <Grid container>
          <Typography variant='h6'> Operation Settings </Typography>
          <Grid item xs={12} className={classes.containerPadding}>
            <Tabs
              value={settings_type}
              onChange={this.handleTabChange}
              indicatorColor="primary"
            >
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Quick Settings" {...this.handleTabEvent(1)} />
              {/* <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Advanced Settings" {...this.handleTabEvent(2)} />
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Smart Report Settings" {...this.handleTabEvent(3)} /> */}
            </Tabs>
          </Grid>
          <Grid item xs={12} className={classes.backgroundWhite}>
            {settings_type === 0 ? <QuickSettings/> : (settings_type === 1 ? <AdvancedSettings/> : (settings_type === 2 ? null : null))}
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default Settings;