import React from 'react';
import { connect } from 'react-redux'

import {
  Grid,
  CircularProgress,
} from '@material-ui/core';

import BillingPage from './Billing';
import DefaultBilling from '../Billing';
import { getPatientIdBilling } from '../Store/ActionCreators'

class Billing extends React.Component {

  componentDidMount() {
    const pathName = window.location.pathname.split('/')
    if (pathName !== undefined && pathName[2] !== undefined) {
      const id = pathName[2].toString();
      this.props.getPatientIdBilling(id);
    } else window.location.replace('/billing')
  }

  render() {
    const { patientWithId } = this.props;
    if (patientWithId !== null) {
      if (patientWithId.length <= 0) {
        return <Grid container justify="center"><CircularProgress /></Grid>
      } else return <BillingPage patientWithId={patientWithId} />
    } else return <DefaultBilling />
  }
}

const mapStateToProps = state => ({
  patientWithId: state.login.patientIdBillingResponse
})

export default connect(mapStateToProps, { getPatientIdBilling })(Billing);