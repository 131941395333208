import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Tabs,
  Tab,
  Typography,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Link,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Popover,
  Paper,
  MenuList,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { ArrowForwardIos, ArrowDropDown, Close, Search } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  getTestPrices, searchBillId, addTestToBill, getBillByPatientId, searchBillByPatientName, searchBillByAccession,
  getPaymentModes,
} from '../../../Store/ActionCreators';

import classes from '../../../App.module.css';

class AddTestsToBill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billref: 0,
      patient_id: 0,
      organisation_id: 0,
      total_price: 0,   //no total_price as there is no outside fields like concession_type
      paid_amount: 0,   // 
      payable_amount: 0, //amount to be paid
      due_amount: 0,
      patient_tests: [],
      price_list: 1,

      searchPatientData: '',
      searchAccessionID: '',
      minCAPError: false,
      min_cap: 0,
      test_concession_disable: false,
      rows: [],
      barcode: 0,
      bill_history_type: 2,
      bill_id: null,
      payment_mode: '',
      add_pre_set_amount: '0',
      concession_type: 1,
      test_id: 0,
      test_name: '',
      test_price: '',
      test_price_concession: '0',
      search_test: '',
      openConcessionOptions: false,
      openBillList: false,
      openBillAccessionList: false,
      addTestClick: false,
      paymentModeError: false,
      patient_list: [],
      openSlider: false,
      warning: true,
      is_new_bill: '0',
      is_send_sms_to_organisation: '0',
      is_send_sms_to_patient: '0',
    }
    this.concessionRef = React.createRef();
  }

  componentDidMount() {
    this.props.getPaymentModes();
  }

  handleTabEvent = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ bill_history_type: newValue },
      () => {
        if (this.state.bill_history_type === 0) {
          window.location.replace('/bill-settlements')
        } else if (this.state.bill_history_type === 1) {
          window.location.replace('/allbills')
        }
      }
    )
  }

  handleSearchBillId = async () => {
    const { bill_id } = this.state
    await this.props.searchBillId({
      search: bill_id,
    })
    this.setState({ rows: this.props.searchBillList },() => {
      if(this.state.rows.length !== 0) {
        this.props.getTestPrices(this.state.rows[0].patientdetails.organisationdetails !== null ? this.state.rows[0].patientdetails.organisationdetails.id : null);
      }
    })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleOpenAddTests = (currentItem, event) => {
    var data = this.state.rows;
    data.forEach(item => {
      item.isElementOpen = false
    })
    currentItem.isElementOpen = true
    this.setState({
      rows: data,
      billref: currentItem.id,
      patient_id: currentItem.patient_id,
      patient_tests: [],
      total_price: currentItem.total_price,
      barcode: currentItem.requisition_id,
    })
  }

  handleCancelAddTests = () => {
    var data = this.state.rows;
    data.forEach(item => {
      item.isElementOpen = false
    })
    this.setState({ rows: data, patient_tests: [] })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleOpenOptions = () => {
    this.setState({ openConcessionOptions: true })
  }

  handleCloseOptions = (e) => {
    this.setState({ concession_type: e.target.value })
    this.setState({ openConcessionOptions: false })
  }

  handleSearchPatient = async (name, value) => {
    if (!value) {
      this.setState({ [name]: value })
    } else if (!isNaN(value)) {
      await this.props.getBillByPatientId({ patientref: value });
      this.setState({ patient_list: this.props.patientWiseSearchList },
        () => {
          if(this.state.patient_list.length !== 0) {
            this.props.getTestPrices(this.state.patient_list[0].patientdetails.organisationdetails !== null ? this.state.patient_list[0].patientdetails.organisationdetails.id : null);
          }
        }
      )
    } else {
      await this.props.searchBillByPatientName({ search: value })
      this.setState({ patient_list: this.props.patientNameWiseSearchList },
        () => {
          if(this.state.patient_list.length !== 0) {
            this.props.getTestPrices(this.state.patient_list[0].patientdetails.organisationdetails !== null ? this.state.patient_list[0].patientdetails.organisationdetails.id : null);
          }
        }
      )
    }
  }

  handlePatientIdClick = (values) => {
    if (values !== null) {
      let data = [values];
      this.setState({ rows: data })
    }
  }

  handleSearchAccession = async (name, value) => {
    if (!value && !isNaN(value)) {
      this.setState({ [name]: value })
    } else {
      await this.props.searchBillByAccession({ search: value }
      )
      this.setState({ patient_list: this.props.accessionWiseSearchList },
        () => {
          if(this.state.patient_list.length !== 0) {
            this.props.getTestPrices(this.state.patient_list[0].patientdetails.organisationdetails !== null ? this.state.patient_list[0].patientdetails.organisationdetails.id : null);
          } 
        }
      )
    }
  }

  handleAccessionIdClick = (values) => {
    if (values !== null) {
      let data = [values];
      this.setState({ rows: data })
    }
  }

  handleAddTestMenuClick = (e, values) => {
    const { patient_tests } = this.state;
    if (values !== null) {
      if (patient_tests.length <= 0) {
        this.setState({
          test_id: values.test_id,
          organisation_id: values.organisation_id,      //no param called organisation_id in response
          test_name: values.testdetails.test_name,
          test_price_id: values.id,
          test_price: values.price,
          concession_type: this.state.concession_type,
          price_type: values.price_type,
          test_concession_disable: values.testdetails.testpricedetail.outsource_test === '2' ? true : false,
          min_cap: values.testdetails.testpricedetail.cap
        })
      } else {
        patient_tests.map(item => {
          if (item.id !== values.id) {
            this.setState({
              test_id: values.test_id,
              test_price_id: values.id,
              organisation_id: values.organisation_id,
              test_name: values.testdetails.test_name,
              test_price: values.price,
              concession_type: this.state.concession_type,
              test_concession_disable: values.testdetails.testpricedetail.outsource_test === '2' ? true : false,
              min_cap: values.testdetails.testpricedetail.cap
            })
          } else this.setState({ testDuplicate: true })
          return false;
        })
      }
    }
    document.getElementById('Concession').focus();
  }

  handleAmountChange = (e) => {
    const { min_cap } = this.state;
    this.setState({ minCAPError: false })
    if (min_cap < e.target.value) {
      this.setState({ minCAPError: true, test_price_concession: 0 })
    } else if (min_cap > e.target.value || min_cap === parseInt(e.target.value)) {
      if (e.target.value !== '') {
        let amount = parseInt(e.target.value)
        if (amount) {
          this.setState({ [e.target.name]: amount });
        }
      } else this.setState({ [e.target.name]: 0 });
    }
    this.setState({ testDuplicate: false })
  }

  handleKeyPress = (e) => {
    const { organisation_id, test_id, test_name, test_price, test_price_concession,
      test_price_id, price_type, concession_type, patient_tests, addTestClick, min_cap
    } = this.state;

    if (e.key === 'Enter' || addTestClick) {
      e.preventDefault();
      if (patient_tests !== 'undefined') {
        if (test_name !== '' && test_price !== 0) {
          if (test_price_concession !== '') {
            this.handleAddTest(organisation_id, test_id, test_name, test_price, test_price_concession, test_price_id, price_type, concession_type, min_cap,)
          } else this.handleAddTest(organisation_id, test_id, test_name, test_price, 0, test_price_id, price_type, concession_type, min_cap)
          this.setState({ test_name: '', test_price: 0, test_price_concession: 0, addTestClick: false, search_test: '' })
        }
      } this.setState({ minCAPError: false })
    }
  }

  handleAddTest = (organisation_id, test_id, test_name, price, concession, test_price_id, price_type, concession_type, min_cap) => {
    const test_price = parseInt(price)
    const test_discount_price = parseInt(concession)
    const test_payable_amount = test_price - test_discount_price
    if (this.state.patient_tests.length === 0) {
      this.setState({
        patient_tests: [...this.state.patient_tests,
        {
          organisation_id, test_id, test_name, test_price, test_discount_price, test_price_id, test_payable_amount, price_type, concession_type,
          min_cap
        }
        ]
      })
    } else {
      let flag = false;
      this.state.patient_tests.map(item => {
        if (item.test_id === test_id) {
          flag = true
        }
        return flag === false ? this.setState({
          patient_tests: [...this.state.patient_tests,
          {
            organisation_id, test_id, test_name, test_price, test_discount_price, test_price_id, test_payable_amount, price_type, concession_type,
            min_cap,
          }
          ]
        }) : this.setState({ patient_tests: this.state.patient_tests })
      })
    }
  }

  handleCloseCAPError = () => {
    this.setState({ minCAPError: false })
  }

  handleDeleteTest = (id) => {
    const listAfterDelete = this.state.patient_tests.filter(item => item.test_id !== id)
    this.setState({ patient_tests: listAfterDelete })
  }

  handleAddTestClick = (e) => {
    this.setState({ addTestClick: true }, () => this.handleKeyPress(e))
  }

  handleButtonClick = (value) => {
    const { payment_mode } = this.state;
    this.setState({ paymentModeError: false })
    if(payment_mode !== '') {
      this.setState({ openSlider: true, is_new_bill: value })
    } else this.setState({ paymentModeError: true })
  }

  handleDialogSlider = () => {
    const { openSlider, is_send_sms_to_patient, is_send_sms_to_organisation, warning, barcode } = this.state;
    return (
      <Dialog
        open={openSlider}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container>
          <Grid item xs={11} className={classes.type}>
            <Typography>Confirm List Removal</Typography>
          </Grid>
          <Grid item xs={1}>
            <Grid container justify='flex-end'>
              <IconButton onClick={this.handleClose} >
                <Close fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <DialogContent>
          <Typography>
            Are you sure you want to update this Bill? This bill and all the associated
            reports will be updated from the system. Click No if you are not sure.
          </Typography>
          <FormControlLabel
            name='is_send_sms_to_patient'
            value={is_send_sms_to_patient}
            control={<Checkbox color='primary' />}
            label='Send SMS to Patient'
            labelPlacement='end'
            onClick={this.handleCheckBox}
          /><br />
          <FormControlLabel
            name='is_send_sms_to_organisation'
            value={is_send_sms_to_organisation}
            control={<Checkbox color='primary' />}
            label='Send SMS to Organization'
            labelPlacement='end'
            onClick={this.handleCheckBox}
          /><br />
          {
            warning ?
              <Grid container alignItems='center' justify='center' spacing={1} className={classes.warningStyles}>
                <Grid item xs={11} className={classes.type}>
                  <Typography variant='subtitle2'>Possible generated barcodes are {barcode}</Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={this.handleWarningClose}>
                    <Close fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              : ''
          }
        </DialogContent>

        <Divider />

        <DialogActions>
          <div className={classes.type}>
            <Button onClick={this.handleClose} variant='outlined' className={classes.buttonText} color="primary">
              Cancel
            </Button> &nbsp;&nbsp;
            <Button onClick={this.handleAddIntoExistingBill} variant='contained' className={classes.buttonText} color="primary">
              Yes! I'm sure
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    )
  }

  handleClose = () => {
    this.setState({ openSlider: false })
  }

  handleWarningClose = () => {
    this.setState({ warning: false })
  }

  handleAddIntoExistingBill = async (e) => {
    const { patient_tests, patient_id, billref, paid_amount,
      payable_amount, total_price, is_send_sms_to_patient, is_send_sms_to_organisation,
      add_pre_set_amount, is_new_bill, payment_mode, minCAPError
    } = this.state;
    const data = {
      billref: billref,
      patient_id: patient_id,
      organisation_id: null,
      paid_amount: parseInt(paid_amount),
      payable_amount: payable_amount,
      total_price: parseInt(total_price) + (payable_amount - paid_amount),
      due_amount: payable_amount - paid_amount,
      patient_tests: patient_tests,
      is_send_sms_to_patient: is_send_sms_to_patient,
      is_send_sms_to_organisation: is_send_sms_to_organisation,
      add_pre_set_amount: add_pre_set_amount,
      is_new_bill: is_new_bill,
      payment_mode: payment_mode,
      additional_price: 0,
      concession_type: 1,
      concession_value: 0,
      discount_price: 0,
      remarks: '',
      is_emergency_report: 0,
    }
    if (!minCAPError) await this.props.addTestToBill(data)
    if (this.props.addTestToBillSuccess) {
      window.location.reload()
    }
  }

  render() {
    const { rows, bill_history_type, bill_id, payment_mode, add_pre_set_amount, concession_type, openConcessionOptions,
      openBillList, openBillAccessionList, patient_tests, test_price, test_price_concession, paid_amount, search_test,
      payable_amount, patient_list, openSlider, test_concession_disable, minCAPError, paymentModeError,
      searchPatientData, searchAccessionID,
    } = this.state;
    const { testsData, accessionWiseSearchList } = this.props;

    let SumOfTestPrices = 0;
    this.state.patient_tests.map(item => {
      if (item.concession_type === 1) {
        return SumOfTestPrices = SumOfTestPrices + (parseInt(item.test_price) - parseInt(item.test_discount_price))
      } else return SumOfTestPrices = SumOfTestPrices + ((parseInt(item.test_price) - (parseInt(item.test_price) / 100) * parseInt(item.test_discount_price)))
    })

    this.state.payable_amount = SumOfTestPrices;

    return (
      <Container component='main' maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container justify='center' alignItems='center' spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Billing History&nbsp;
              <ArrowForwardIos fontSize='small' className={classes.fontSizeArrowIcon} />
              <b>{bill_history_type === 0 ? 'All Bills' : (bill_history_type === 1 ? 'Bill Settlements' : 'Add Test To Bill')}</b>
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Tabs
              value={bill_history_type}
              onChange={this.handleTabChange}
              indicatorColor='primary'
            >
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label='All Bills' {...this.handleTabEvent(1)} />
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label='Bill Settlements' {...this.handleTabEvent(2)} />
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label='Add Test To Bill' {...this.handleTabEvent(3)} />
            </Tabs>
          </Grid>

          <Grid item xs={7}>
            <Grid container justify='flex-end' alignItems='center' alignContent='center' spacing={1}>
              <Grid item>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant='outlined'
                  margin='dense'
                >
                  <InputLabel htmlFor='outlined-adornment-password'>
                    Search Bill Id
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-password'
                    type='number'
                    name='bill_id'
                    value={bill_id}
                    onChange={this.handleChange}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={this.handleSearchBillId}
                          edge='end'
                        >
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} justify='center' alignItems='center'>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id='asynchronous-search'
                  open={openBillList}
                  value={searchPatientData}
                  onOpen={() => {
                    this.setState({ openBillList: true })
                  }}
                  onClose={() => {
                    this.setState({ openBillList: false });
                  }}
                  onChange={(e, newValue) => this.handlePatientIdClick(newValue)}
                  getOptionLabel={(option) => option.patientdetails !== undefined ? option.patientdetails.patient_name : option}
                  filterOptions={option => option.filter((option) => {
                    return option.patientdetails.patient_name.toLowerCase().indexOf(searchPatientData.toLowerCase()) > -1
                  })}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Grid container>
                        <Grid item xs={6}><Typography>{option.patientdetails.patient_name}</Typography></Grid>
                        <Grid item xs={6}><Typography></Typography></Grid>
                        <Grid item xs={12}><Typography variant='subtitle2' className={classes.fontColor}>{option.patientdetails.gender}, {option.patientdetails.age}yrs</Typography></Grid>
                        <Grid item xs={6}><Typography>Patient ID:{option.patientdetails.id}</Typography></Grid>
                        <Grid item xs={6}>
                          <Grid container justify='flex-end'>
                            <Typography className={classes.branchDetailsStyle}>Pending Due:{option.due_amount}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}><Divider /></Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  options={patient_list}
                  loading={patient_list.length === 0 ? false : true}
                  renderInput={(params) => (
                    <TextField
                      name='searchPatientData'
                      value={searchPatientData}
                      fullWidth
                      margin='dense'
                      placeholder='Search by Patient Name or Patient ID or Contact'
                      className={classes.backgroundColor}
                      onChange={e => this.handleSearchPatient(e.target.name, e.target.value)}
                      {...params}
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {openBillList && patient_list.length === 0 ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}>
                <Grid container justify='center' className={classes.fontColor}>
                  OR
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id='asynchronous-search'
                  open={openBillAccessionList}
                  value={searchAccessionID}
                  onOpen={() => {
                    this.setState({ openBillAccessionList: true })
                  }}
                  onClose={() => {
                    this.setState({ openBillAccessionList: false });
                  }}
                  onChange={(e, newValue) => this.handleAccessionIdClick(newValue)}
                  getOptionLabel={(option) => option.requisition_id !== undefined ? option.requisition_id : option}
                  filterOptions={option => option.filter((option) => {
                    return option.requisition_id.toLowerCase().indexOf(searchAccessionID.toLowerCase()) > -1
                  })}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Grid container>
                        <Grid item xs={6}><Typography>{option.patientdetails.patient_name}</Typography></Grid>
                        <Grid item xs={6}><Typography></Typography></Grid>
                        <Grid item xs={12}><Typography variant='subtitle2' className={classes.fontColor}>{option.patientdetails.gender}, {option.patientdetails.age}yrs</Typography></Grid>
                        <Grid item xs={6}><Typography>Accession ID:{option.requisition_id}</Typography></Grid>
                        <Grid item xs={6}>
                          <Grid container justify='flex-end'>
                            <Typography className={classes.branchDetailsStyle}>Pending Due:{option.due_amount}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}><Divider /></Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  options={accessionWiseSearchList}
                  loading={accessionWiseSearchList.length === 0 ? false : true}
                  renderInput={(params) => (
                    <TextField
                      name='searchAccessionID'
                      value={searchAccessionID}
                      fullWidth
                      margin='dense'
                      placeholder='Search By Accession ID'
                      className={classes.backgroundColor}
                      onChange={e => this.handleSearchAccession(e.target.name, e.target.value)}
                      {...params}
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {openBillAccessionList && accessionWiseSearchList.length === 0 ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead style={{ minWidth: 650 }} >
                  {
                    rows.length !== 0 ?
                      <TableRow>
                        <TableCell style={{ fontWeight: '600' }}>Bill Id</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align='left'>Bill Date</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align='left'>Bill Amt</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align='left'>Referral</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align='left'>Organization</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align='left'>Tests</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align='left'></TableCell>
                      </TableRow>
                      : null
                  }
                </TableHead>
                {rows.length !== 0 ? rows.map((item, index) => (
                  <TableBody key={index}>
                    <TableRow>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.bill_on}</TableCell>
                      <TableCell>{item.total_price}</TableCell>
                      <TableCell>{item.patientdetails.referraldetails !== null ? item.patientdetails.referraldetails.referrer_name : ''}</TableCell>
                      <TableCell>{item.patientdetails.organisationdetails !== null ? item.patientdetails.organisationdetails.organisation_name : ''}</TableCell>
                      <TableCell>{item.billtestdetails.map(item => item.testdetails.test_name)}</TableCell>
                      <TableCell style={{ width: '6%' }}>
                        {
                          !item.isElementOpen ?
                            <Link onClick={e => this.handleOpenAddTests(item, e)}>Add Tests</Link> :
                            <Link onClick={this.handleCancelAddTests}>Cancel</Link>
                        }
                      </TableCell>
                    </TableRow>
                    {
                      item.isElementOpen ?
                        <TableRow
                          id={item.id}
                        >
                          <TableCell colSpan={12}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className={classes.tablePadding} style={{ fontWeight: '600', width: '40%' }}>Test Name</TableCell>
                                  <TableCell className={classes.tablePadding} style={{ fontWeight: '600', width: '25%' }}>Test Amount</TableCell>
                                  <TableCell className={classes.tablePadding} style={{ fontWeight: '600', width: '25%' }}>
                                    Concession&nbsp;
                                    <Button
                                      ref={this.concessionRef}
                                      onClick={this.handleOpenOptions}
                                      className={clsx(classes.buttonShadow, classes.buttonPadding)}
                                    >
                                      <Typography variant='caption'>{concession_type === 1 ? 'In Rs.' : 'In %'}</Typography>
                                      <ArrowDropDown fontSize='small' style={{ height: '20px' }} />
                                    </Button>
                                    <Popover
                                      open={openConcessionOptions}
                                      anchorEl={this.concessionRef.current}
                                      onClose={this.handleCloseOptions}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                    >
                                      <Paper>
                                        <MenuList>
                                          <MenuItem className={classes.menuPadding} onClick={this.handleCloseOptions} value={1}>(In Rs.)</MenuItem>
                                          <MenuItem className={classes.menuPadding} onClick={this.handleCloseOptions} value={2}>(In %)</MenuItem>
                                        </MenuList>
                                      </Paper>
                                    </Popover>
                                  </TableCell>
                                  <TableCell className={classes.tablePadding} style={{ fontWeight: '600' }}></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  patient_tests.map((item, index) => {
                                    return (
                                      <TableRow key={index}>
                                        <TableCell component='th' scope='row' className={classes.tablePadding}>
                                          <TextField
                                            value={item.test_name}
                                            variant='outlined'
                                            margin='dense'
                                            fullWidth
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell className={classes.tablePadding}>
                                          <TextField
                                            value={item.test_price}
                                            variant='outlined'
                                            margin='dense'
                                            fullWidth
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell className={classes.tablePadding}>
                                          <TextField
                                            value={item.test_discount_price}
                                            variant='outlined'
                                            margin='dense'
                                            fullWidth
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell className={classes.tablePadding}>
                                          <Close fontSize='small' key={item.test_id} onClick={() => this.handleDeleteTest(item.test_id)} />
                                        </TableCell>
                                      </TableRow>
                                    )
                                  })
                                }
                                <TableRow>
                                  <TableCell component='th' scope='row' className={classes.tablePadding}>
                                    <Autocomplete
                                      disableClearable
                                      options={testsData}
                                      value={search_test}
                                      onChange={(e, newValue) => this.handleAddTestMenuClick(e, newValue)}
                                      renderOption={(option) => (
                                        <React.Fragment>
                                          <Grid container direction='row' className={classes.payableAmount}>
                                            <Grid item xs={8}>Test Name: {option.testdetails.test_name}</Grid>
                                            <Grid item xs={4}>{option.source}</Grid>
                                            <Grid item xs={8}>Test Code: {option.testdetails.display_code}</Grid>
                                            <Grid item xs={4}>Price:{option.price}</Grid>
                                            <Grid item xs={12}><Divider /></Grid>
                                          </Grid>
                                        </React.Fragment>
                                      )}
                                      getOptionLabel={(option) => (
                                        option.testdetails !== undefined ? option.testdetails.test_name : option
                                      )}
                                      filterOptions={option => option.filter((option) => {
                                        return option.testdetails.test_name.toLowerCase().indexOf(search_test.toLowerCase()) > -1
                                      })}
                                      renderInput={(params) =>
                                        <TextField
                                          name='search_test'
                                          value={search_test}
                                          onChange={this.handleChange}
                                          fullWidth
                                          {...params}
                                          variant='outlined'
                                          margin='dense'
                                        />
                                      }
                                    />
                                  </TableCell>
                                  <TableCell className={classes.tablePadding}>
                                    <TextField
                                      name='test_price'
                                      value={test_price}
                                      label='Price in &#8377;'
                                      onKeyPress={this.handleKeyPress}
                                      onChange={this.handleAmountChange}
                                      variant='outlined'
                                      margin='dense'
                                      fullWidth
                                    />
                                  </TableCell>
                                  <TableCell className={classes.tablePadding}>
                                    <TextField
                                      id='Concession'
                                      name='test_price_concession'
                                      value={test_price_concession}
                                      disabled={test_concession_disable}
                                      onChange={this.handleAmountChange}
                                      label='Concession'
                                      onKeyPress={this.handleKeyPress}
                                      variant='outlined'
                                      margin='dense'
                                      fullWidth
                                    />
                                  </TableCell>
                                  <TableCell className={classes.tablePadding}>
                                    <Button
                                      color='primary'
                                      variant='outlined'
                                      className={classes.buttonText}
                                      onClick={this.handleAddTestClick}
                                    >
                                      Add test
                                   </Button>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colSpan={12}>
                                    {minCAPError ?
                                      (
                                        <Grid container alignItems='center' justify='center' spacing={1} style={{ backgroundColor: '#fcf8e3' }}>
                                          <Grid item xs={11}>
                                            <Typography variant='subtitle2'> Discount Price is exceeding Min CAP. Please </Typography>
                                          </Grid>
                                          <Grid item xs={1}>
                                            <IconButton onClick={this.handleCloseCAPError}>
                                              <CloseIcon fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      ) : false
                                    }
                                  </TableCell>
                                </TableRow>
                                {
                                  patient_tests.length !== 0 ?
                                    <TableRow>
                                      <TableCell colSpan={12}>
                                        <Box className={clsx(classes.type, classes.expansionPanelColor)}>
                                          <Grid container justify='center' spacing={1}>
                                            <Grid item xs={12}>
                                              <Box>
                                                <Grid container className={clsx(classes.type, classes.backgroundWhite)} spacing={1}>
                                                  <Grid item xs={2}>
                                                    <Grid container justify='flex-end'>
                                                      <Typography>
                                                        Payment mode
                                                      </Typography>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid item xs={3}>
                                                    <Select
                                                      name='payment_mode'
                                                      value={payment_mode}
                                                      onChange={this.handleChange}
                                                      variant='outlined'
                                                      margin='dense'
                                                      fullWidth
                                                      label='Payment Mode(Default: Cash)'
                                                    >
                                                      {
                                                        this.props.paymentModes.list.map((item, index) => 
                                                          <MenuItem key={index} value={item.id}>{item.payment_mode_name}</MenuItem>
                                                        )
                                                      }
                                                    </Select>
                                                    {paymentModeError && payment_mode === '' ? <div className={classes.error}>This field is required</div> : ''}
                                                    <FormControlLabel
                                                      name='add_pre_set_amount'
                                                      value={add_pre_set_amount}
                                                      control={<Checkbox color='primary' />}
                                                      label='Add Pre-set Amount(0.00 %):'
                                                      labelPlacement='end'
                                                      onClick={this.handleCheckBox}
                                                    />
                                                  </Grid>

                                                  <Grid item xs={2}></Grid>
                                                  <Grid item xs={5}>
                                                    <Typography>
                                                      Amount Payable:&nbsp;<b>INR {SumOfTestPrices} </b>
                                                    </Typography>
                                                    <Typography variant='caption'>
                                                      ( Previous Due: INR { } )
                                                  </Typography><br />
                                                    <Typography variant='caption'>
                                                      Amount Paid
                                                  </Typography>
                                                    <TextField
                                                      name='paid_amount'
                                                      value={paid_amount}
                                                      onChange={this.handleChange}
                                                      fullWidth
                                                      variant='outlined'
                                                      margin='dense'
                                                      type='number'
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <Grid container justify='flex-end' alignItems='center' spacing={1}>
                                                <Grid item>
                                                  <Typography variant='subtitle2'>
                                                    Paid Amount(INR): {paid_amount}
                                                  </Typography>
                                                  <Typography variant='caption'>
                                                    Remaining Balance (INR) : {payable_amount - paid_amount}
                                                  </Typography>
                                                </Grid>
                                                <Grid item>
                                                  <Button
                                                    variant='contained'
                                                    color='primary'
                                                    className={classes.buttonText}
                                                    onClick={e => this.handleButtonClick(false)}
                                                  >
                                                    Add into Existing Bill
                                                </Button>
                                                </Grid>
                                                <Grid item>
                                                  <Button
                                                    variant='contained'
                                                    color='primary'
                                                    className={classes.buttonText}
                                                    onClick={e => this.handleButtonClick(true)}
                                                  >
                                                    New Bill with same Accession ID
                                                </Button>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                    : null
                                }
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                        : null
                    }
                  </TableBody>
                ))
                  : null
                }
              </Table>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {openSlider ? this.handleDialogSlider() : ''}
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  searchBillList: state.login.searchBillList,
  testsData: state.login.testsData,
  addTestToBillSuccess: state.login.addTestToBillSuccess,
  patientWiseSearchList: state.login.patientWiseSearchList,
  patientNameWiseSearchList: state.login.patientNameWiseSearchList,
  accessionWiseSearchList: state.login.accessionWiseSearchList,
  paymentModes: state.login.paymentModes,
})

export default connect(mapStateToProps, {
  searchBillId,
  getTestPrices,
  addTestToBill,
  getBillByPatientId,
  searchBillByPatientName,
  searchBillByAccession,
  getPaymentModes,
})(AddTestsToBill);