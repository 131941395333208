import React from 'react';
import clsx from 'clsx';

import {
  Grid,
  Container,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Checkbox,
  Button,
} from '@material-ui/core';

import classes from '../../App.module.css';

class AddOutsourceCentres extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interfacingType: 'Unidirectional',
      relation: 'Intra Center',
    }
  }

  handleRadioButton = (e) => {
    if (e.target !== undefined && e.target.value !== undefined) {
      this.setState({ [e.target.name]: e.target.value })
    } else this.setState({ [e.target.name]: '' })
  }

  render() {
    const { interfacingType, relation } = this.state;
    return (
      <Container maxWidth='md'>
        <Grid container direction='row' alignItems='center' spacing={2}>
          <Grid item xs={3}>
            <Grid container justify='flex-end'>
              Outsource Centre
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant='outlined'
              margin='dense'
              placeholder='Enter outsource centre name'
            />
          </Grid>

          <Grid item xs={3}>
            <Grid container justify='flex-end'>
              Centre Type
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant='outlined'
              margin='dense'
              placeholder='Enter centre type'
            />
          </Grid>

          <Grid item xs={3}>
            <Grid container justify='flex-end'>
              Centre Sharing Key
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant='outlined'
              margin='dense'
              placeholder='Enter center sharing key'
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={3}>
                <Grid container justify='flex-end' className={classes.headerContainer}>
                  Centre Interfacing Type
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <RadioGroup
                  aria-label="interfacingType"
                  name="interfacingType"
                  value={interfacingType}
                  onChange={this.handleRadioButton}>
                  <FormControlLabel
                    value="Unidirectional"
                    checked={interfacingType === 'Unidirectional'}
                    control={<Radio color="primary" />}
                    label="Unidirectional"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="Bidirectional"
                    checked={interfacingType === 'Bidirectional'}
                    control={<Radio color="primary" />}
                    label="Bidirectional"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="I don't know"
                    checked={interfacingType === "I don't know"}
                    control={<Radio color="primary" />}
                    label="I don't know"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={3}>
                <Grid container justify='flex-end' className={classes.headerContainer}>
                  Relation
                </Grid>
              </Grid>

              <Grid item xs={9}>
                <RadioGroup
                  aria-label="relation"
                  name="relation"
                  value={relation}
                  onChange={this.handleRadioButton}>
                  <FormControlLabel
                    value="Intra Center"
                    checked={relation === 'Intra Center'}
                    control={<Radio color="primary" />}
                    label="Intra Center"
                    labelPlacement="end"
                  />
                  <Typography variant='body2' className={classes.radioHelperText}>
                    Intra is when you are outsourcing it your own centre. Patient Contact Details, Organisation
                    Details and Referral Details will be shared.
                </Typography>
                  <FormControlLabel
                    value="Inter Center"
                    checked={relation === 'Inter Center'}
                    control={<Radio color="primary" />}
                    label="Inter Center"
                    labelPlacement="end"
                  />
                  <Typography variant='body2' className={classes.radioHelperText}>
                    Intra is when you are not outsourcing it your own centre. Patient Contact Details, Organisation
                    Details and Referral Details will not be shared.
                </Typography>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3}>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              value='end'
              control={<Checkbox color='primary' />}
              label='Share report automatically'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
            <FormControlLabel
              value='end'
              control={<Checkbox color='primary' />}
              label='Auto report submit'
              labelPlacement='end'
              classes={{
                label: classes.label
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  size="medium"
                  variant="contained"
                  className={clsx(classes.buttonText, classes.submitButton)}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    )
  }
}

export default AddOutsourceCentres;