import React from 'react';

import {
  Grid,
  Tooltip
} from '@material-ui/core';

import classes from '../../../../App.module.css';

class SamplesRenderer extends React.Component {

  handleSamples = (data, scroller) => {
    let samples = JSON.parse(data.samples)
    let samplesCount = samples.length
    return (
      <div>
        {samples.map((item, index) => {
          index++;
          return (
            <span key={item.sample_id}>
              {index < 4 ?
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ backgroundColor: '#' + item.sample_container_color }} className={classes.testSampleCircle}></span>&nbsp;
                  {item.sample_name}
                </span>
                : (index === 4) ?
                  <Grid container>
                    <Grid item xs={10}>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ backgroundColor: '#' + item.sample_container_color }} className={classes.testSampleCircle}></span>&nbsp;
                        {item.sample_name}
                      </span>
                    </Grid>
                    {samplesCount > 4 ?
                      <Grid item xs={2}>
                        &nbsp;<Tooltip placement="top" title={<div style={{ maxWidth: 100 }}>
                          {samples.map((item,index) => { return (<div key={index}>{item.sample_name}</div>) })}</div>}>
                          <span style={{ color: 'blue' }}>more</span>
                        </Tooltip>
                      </Grid> : ''}
                  </Grid>
                  : ''
              }
            </span>
          )
        })}
      </div>
    )
  }

  render() {
    const data = this.props.node.data
    const scroller = {
      maxHeight: "90px",
      overflowY: 'auto'
    };
    return (
      <div>
        {this.handleSamples(data, scroller)}
      </div>
    );
  }
}

export default SamplesRenderer;