import React from 'react';
import {
  Grid,
} from '@material-ui/core';

class FileUpload extends React.Component {
  render() {
    return (
      <Grid container alignItems='center' spacing={1}>
        <Grid item xs={12}>
          <button>
            Choose file
          </button> &nbsp;
          No file chosen
        </Grid>
        <Grid item xs={12}>
          <div>
            <img src='' alt='!'></img>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default FileUpload;