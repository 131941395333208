import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import {
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  MenuItem,
  Menu,
} from '@material-ui/core';

import { ArrowForwardIos, MoreVert, FilterList } from '@material-ui/icons';

import { getBillList, searchBillId, updateBillPaymentCompleted } from '../../../Store/ActionCreators';

import classes from '../../../App.module.css';

var moment = require('moment');

class AllBills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bill_history_type: 0,
      bill_id: '',
      from_date: null,
      to_date: null,
      start: 0,
      length: '', 
      add_sample: false,
      openFilters: false,
      sample_mapping: false,
      rows: [],
      anchorEl: [],
      status: '',
    }
    this.handleSampleList();
  }

  handleSampleList = async () => {
    await this.props.getBillList({
      from_date: this.state.from_date === null ? moment(Date()).format() : this.state.from_date,
      to_date: this.state.to_date === null ? moment(Date()).format() : this.state.to_date,
      start: this.state.start,
      length: this.state.length,
    });
    this.setState({ rows: this.props.billList })
  }

  handleTextChange = async(e) => {
    var intValue = parseInt(e.target.value)
    this.setState({ [e.target.name]: e.target.value })
    await this.props.searchBillId({
      search: intValue,
      from_date: this.state.from_date === null ? moment(Date()).format() : this.state.from_date,
      to_date: this.state.to_date === null ? moment(Date()).format() : this.state.to_date,
      start: this.state.start,
      length: this.state.length,
    })
    this.setState({ rows: this.props.searchBillList })
  }

  handleTabEvent = index => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ bill_history_type: newValue }, () => {
      if(this.state.bill_history_type === 2) {
        window.location.replace('/addtesttobill')
      } else if(this.state.bill_history_type === 1) {
        window.location.replace('/allbills');
      }
    })
  }

  handleAddSampleType = () => {
    this.setState({ add_sample: true })
  }

  handleEditSample = (item) => {
    this.setState({ editItem: item, add_sample: true })
  }

  handleFilter = () => {
    const { bill_history_type } = this.state;
    if (bill_history_type === 0) {
      this.setState({ openFilters: !this.state.openFilters })
    } else this.setState({ sample_mapping: !this.state.sample_mapping })
  }

  handleEvent = async (event, picker) => {
    if (event.type === 'apply') {
      this.setState({
        from_date: picker.startDate,
        to_date: picker.endDate
      })
    }
    await this.props.getBillList({
      from_date: picker.startDate._d,
      to_date: picker.endDate._d,
      start: this.state.start,
      length: this.state.length,
    })
    this.setState({ rows: this.props.billList })
  }

  handleMenuOpen = (id, event) => {
    let { anchorEl } = this.state;
    anchorEl[id] = event.target;
    this.setState({ anchorEl });
  }

  handleOptionsClose = () => {
    this.setState({ anchorEl: [] })
  }

  handleBillIdChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.id.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.billList })
    }
  }

   handlePatientChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.patientdetails.patient_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.billList })
    }
  }
  
  handleRefferalChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.patientdetails.referraldetails !== null ? item.patientdetails.referraldetails.referrer_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1 : '';
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.billList })
    }
  }

  handleOrganizationChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.patientdetails.organisationdetails !== null ? item.patientdetails.organisationdetails.organisation_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1 : '';
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.billList })
    }
  }

  handleAmountChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.total_price.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.billList })
    }
  }

  handlePendingToComplete = async(item) => {
    await this.props.updateBillPaymentCompleted({patient_id: item.patient_id, billref: item.billtestdetails[0].bill_id })
    if(this.props.updateBillPaymentCompletedSuccess) {
      window.location.reload();
    }
  }

  handleStatusChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.bill_payment_status.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.billList })
    }
  }

  handleEditBill = (item) => {
    window.location.replace('/editbill/'+ item.id)
  }

  render() {
    const { bill_history_type, rows, bill_id, from_date, to_date, openFilters, status, anchorEl } = this.state;
    return (
      <Container component="main" maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Billing History&nbsp;
              <ArrowForwardIos fontSize='small' className={classes.fontSizeArrowIcon} />
              <b>{bill_history_type === 0 ? 'All Bills' : (bill_history_type === 1 ? 'Bill Settlements' : 'Add Test To Bill')}</b>
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Tabs
              value={bill_history_type}
              onChange={this.handleTabChange}
              indicatorColor="primary"
            >
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="All Bills" {...this.handleTabEvent(1)} />
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Bill Settlements" {...this.handleTabEvent(2)} />
              <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Add Test To Bill" {...this.handleTabEvent(3)} />
            </Tabs>
          </Grid>

          <Grid item xs={7}>
            <Grid container justify='flex-end' alignItems='center' alignContent='center' spacing={1}>
              <Grid item>
                <TextField
                  name='bill_id'
                  value={bill_id}
                  onChange={this.handleTextChange}
                  variant='outlined'
                  fullWidth
                  margin='dense'
                  className={classes.search}
                  placeholder="Search Bill Id"
                />
              </Grid>
              <Grid item>
                <Button variant='outlined' className={classes.buttonText} onClick={this.handleFilter}>
                  <FilterList fontSize='small' className={classes.buttonShadow} />&nbsp;Filter Rows
                </Button>
              </Grid>
              <Grid item>
                <Button variant='outlined' className={classes.datePickerButton}>
                  <DateRangePicker onEvent={this.handleEvent}>
                    {(from_date && to_date) ? <span>{from_date.format('DD-MM-YYYY').toString()} to {to_date.format('DD-MM-YYYY').toString()}</span> :
                      <span className={classes.buttonText}>Select Date Range</span>}
                  </DateRangePicker>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {bill_history_type === 0 ?
              <Table aria-label='simple table'>
                <TableHead style={{ minWidth: 650 }} >
                  <TableRow>
                    <TableCell style={{ fontWeight: '600' }}>Bill Id</TableCell>
                    <TableCell style={{ fontWeight: '600' }} align='left'>Patient Details</TableCell>
                    <TableCell style={{ fontWeight: '600' }} align='left'>Referral</TableCell>
                    <TableCell style={{ fontWeight: '600' }} align='left'>Organization</TableCell>
                    <TableCell style={{ fontWeight: '600' }} align='left'>Billing Date</TableCell>
                    <TableCell style={{ fontWeight: '600' }} align='left'>Bill Amount</TableCell>
                    <TableCell style={{ fontWeight: '600' }} align='left'>Due</TableCell>
                    <TableCell style={{ fontWeight: '600' }} align='left'>Bill Status</TableCell>
                    <TableCell style={{ fontWeight: '600' }} align='left'></TableCell>
                  </TableRow>
                  {
                    openFilters ?
                      <TableRow>
                        <TableCell align="left">
                          <TextField
                            margin='dense'
                            variant='outlined'
                            placeholder='Bill Id'
                            onChange={this.handleBillIdChange}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <TextField
                            margin='dense'
                            variant='outlined'
                            placeholder='Search Patient...'
                            onChange={this.handlePatientChange}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <TextField
                            margin='dense'
                            variant='outlined'
                            placeholder='Search Refferal...'
                            onChange={this.handleRefferalChange}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <TextField
                            margin='dense'
                            variant='outlined'
                            placeholder='Search Organization...'
                            onChange={this.handleOrganizationChange}
                          />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="left">
                          <TextField
                            margin='dense'
                            variant='outlined'
                            placeholder='Amount'
                            onChange={this.handleAmountChange}
                          />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="left">
                          <TextField
                            name='status'
                            value={status}
                            select
                            margin='dense'
                            variant='outlined'
                            onChange={this.handleStatusChange}
                          >
                            <MenuItem value='None' key={1}>None</MenuItem>
                            <MenuItem value='Pending' key={2}>Pending</MenuItem>
                            <MenuItem value='Complete' key={3}>Complete</MenuItem>
                            <MenuItem value='Cancelled' key={4}>Cancelled</MenuItem>
                          </TextField>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      : null
                  }
                </TableHead>
                <TableBody>
                  {rows.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.patientdetails.patient_name},{item.patientdetails.gender.charAt(0)}, {item.patientdetails.age}</TableCell>
                      <TableCell>{item.patientdetails.referraldetails !== null ? item.patientdetails.referraldetails.referrer_name : ''}</TableCell>
                      <TableCell>{item.patientdetails.organisationdetails !== null ? item.patientdetails.organisationdetails.organisation_name : ''}</TableCell>
                      <TableCell>{item.bill_on}</TableCell>
                      <TableCell>{item.total_price}</TableCell>
                      <TableCell>{item.due_amount}</TableCell>
                      <TableCell>
                        <span
                          className={item.bill_payment_status === 'Completed' ? clsx(classes.textAlign, classes.textColor,classes.backgroundOrange) : 
                          (item.bill_payment_status === 'Pending' ? clsx(classes.textAlign, classes.textColor,classes.backgroundGreen) : 
                          (item.bill_payment_status === 'Cancelled' ? clsx(classes.textAlign, classes.textColor,classes.buttonColor) : '') )}
                        >{item.bill_payment_status}</span></TableCell>
                      <TableCell>
                        <MoreVert onClick={e => this.handleMenuOpen(item.id, e)} />
                          <Menu
                            id={item.id}
                            anchorEl={anchorEl[item.id]}
                            keepMounted
                            open={Boolean(anchorEl[item.id])}
                            onClose={this.handleOptionsClose}
                          >
                            {/* {item.bill_payment_status === '0' ? <MenuItem>Complete and Print Bill</MenuItem> : ''} */}
                            {item.bill_payment_status === 'Pending' ? <MenuItem onClick={e => this.handlePendingToComplete(item)}>Complete {/*and Print */}Bill</MenuItem> : ''}
                            {/* {item.bill_payment_status === 'Pending' || item.bill_payment_status === 'Completed' ? <MenuItem>Print Bill</MenuItem> : ''} */}
                            {/* {item.bill_payment_status === 'Pending' ? <MenuItem>Settle Bill</MenuItem> : ''} */}
                            {item.bill_payment_status === 'Pending' || item.bill_payment_status === 'Completed' || item.bill_payment_status === 'Cancelled' ? 
                              <MenuItem onClick={e => this.handleEditBill(item)}>Edit Bill</MenuItem> : ''
                            }
                            {/* {item.bill_payment_status === 'Pending' || item.bill_payment_status === 'Completed' || item.bill_payment_status === 'Cancelled' ? <MenuItem>View Transaction</MenuItem> : ''} */}
                            {/* {item.bill_payment_status === 'Pending' ? <MenuItem>Send Bill SMS to Patient</MenuItem> : ''} */}
                            {/* {item.bill_payment_status === 'Pending' || item.bill_payment_status === 'Completed'|| item.bill_payment_status === 'Cancelled' ? <MenuItem>Print TRF</MenuItem> : ''} */}
                          </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> : ''
            }
          </Grid>
          {/* <Grid item xs={12}>
            {bill_history_type === 2 ? <AddTestsToBill/> : ''}
          </Grid> */}
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  billList: state.login.billList,
  searchBillList: state.login.searchBillList,
  updateBillPaymentCompletedSuccess: state.login.updateBillPaymentCompletedSuccess,
})

export default connect(mapStateToProps, {
  getBillList,
  searchBillId,
  updateBillPaymentCompleted,
})(AllBills);