import React from 'react';

import {
	Container,
	Grid,
	Typography,
	Button
} from '@material-ui/core';

import * as XLSX from 'xlsx';
import classes from '../../App.module.css';

class UploadReportList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			row: null,
			cols: null
		}
	}

	handleUpload = (e) => {
		e.preventDefault();

		var files = e.target.files, f = files[0];
		var reader = new FileReader();
		reader.onload = function (e) {
			var data = e.target.result;
			let readedData = XLSX.read(data, { type: 'binary' });
			const wsname = readedData.SheetNames[0];
			const ws = readedData.Sheets[wsname];

			/* Convert array to json*/
			const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
			// setFileUploaded(dataParse);
			console.log(dataParse);
		};
		reader.readAsBinaryString(f)
	}

	render() {
		return (
			<Container component="main">
				<Grid container spacing={2}>
					<Grid item xs={12} className={classes.uploadHeader}>
						<Grid container direction="row" justify="space-between" alignItems="center">
							<Grid item xs={6}>
								<Grid container direction="column" justify="center" alignItems="flex-start">
									<Typography variant="h6">TestList Upload Excel</Typography>
									<span >Upload Excel of Tests</span>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Grid container direction="column" justify="center" alignItems="flex-end">
									<Button variant="contained" color="primary">
										Excel Template
                  </Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} className={classes.uploadText}>
						<Grid container direction="row" justify="center" alignItems="center">
							<Typography variant="h6">TestList Upload Excel: &nbsp;&nbsp;</Typography>
							<input type="file" onChange={this.handleUpload.bind(this)} style={{ "padding": "10px" }} />
						</Grid>
					</Grid>
				</Grid>
			</Container>
		);
	};
}

export default UploadReportList;