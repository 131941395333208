import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ValidatorForm } from 'react-material-ui-form-validator';

import {
  Container,
  Grid,
  Typography,
  Divider,
  MenuItem,
  Select,
  Link,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Drawer,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import {
  TextValidator,
  TextField,
} from '../../Material UI';
import { getTestPrices, billing } from '../../Store/ActionCreators';
import classes from '../../App.module.css';

let Total = 0, Percentage_Concession = 0, TotalMinCAP = 0;

class Billing extends React.Component {
  static propTypes = {
    patientWithId: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      patient_id: 0,
      organisation_id: 0,
      total_price: 0,
      test_price_id: 0,
      additional_price: 0,
      concession_type: 1,
      concession_value: '0',
      discount_price: 0,
      remarks: '',
      comments: '',
      paid_amount: 0,
      payable_amount: 0,
      due_amount: 0,
      patient_tests: [],
      is_emergency_report: 0,
      price_type: '',

      minCAPError: false,
      min_cap: 0,
      test_concession_disable: false,
      billingDate: Date(),
      testDuplicate: false,
      price_list: 1,
      test_id: 0,
      test_name: '',
      test_price: '',
      test_profile_names: [],
      test_price_concession: '0',
      total_concession: '0',
      outsourced_status: '',
      percentage: 0,
      discountLimitWarning: false,
      openBillingFooter: false,
      enableConcession: false,
    }
    this.props.getTestPrices(this.props.patientWithId.organisation)
    this.state.patient_id = this.props.patientWithId.id;
  }

  handlePatientDetails = (patientdetails) => {
    if (patientdetails.patienttypedetails !== undefined) {
      return (
        <span>
          {patientdetails.patienttypedetails.type.charAt(0)} - {patientdetails.designation}&nbsp;
          {patientdetails.patient_name.length > 15 ? <span>{patientdetails.patient_name.substring(0, 15)}<Tooltip title={patientdetails.patient_name}><span>...</span></Tooltip></span> : patientdetails.patient_name} ({patientdetails.gender.charAt(0)} - {patientdetails.age})
        </span>
      );
    } else return '';
  }

  handleDateChange = (date) => {
    this.setState({ billingDate: date })
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }))
  }

  handleTextChange = (e) => {
    if (e.target.value !== '' || e.target.name === 'comments') {
      this.setState({ [e.target.name]: e.target.value, testDuplicate: false })
    }
  }

  handleConcessionAmountChange = (e) => {
    const { patient_tests } = this.state;
    this.setState({ minCAPError: false })
    patient_tests.map( item => {
      if(parseInt(TotalMinCAP) < e.target.value || parseInt(TotalMinCAP) === e.target.value) {
        this.setState({ minCAPError: true })
      } else this.handleAmountChange(e);
      if(item.test_discount_price !== 0 ) {
        this.setState({ enableConcession: true })
      } else this.handleAmountChange(e);
      return;
    })
  }

  handleAmountChange = (e) => {
    const { min_cap } = this.state;
    this.setState({ minCAPError: false })
    if(min_cap < e.target.value) {
      this.setState({ minCAPError: true, test_price_concession: 0 })
    } else if(min_cap > e.target.value || min_cap === parseInt(e.target.value)) {
      if (e.target.value !== '') {
        let amount = parseInt(e.target.value)
        if (amount) {
          this.setState({ [e.target.name]: amount });
        }
      } else this.setState({ [e.target.name]: 0 });
    }
    this.setState({ testDuplicate: false })
  }

  handlePriceChange = (e) => {
    if (e.target.value !== '') {
      let amount = parseInt(e.target.value)
      if (amount) {
        this.setState({ [e.target.name]: amount });
      }
    } else this.setState({ [e.target.name]: 0 });
    this.setState({ testDuplicate: false })
  }
  
  handleAutoFill = (item) => {
    this.setState({
      test_name: item.testdetails.test_name,
      test_price: parseInt(item.price),
      test_price_concession: parseInt(item.price_2),
    })
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (this.state.patient_tests !== 'undefined') {
        if (this.state.test_name !== '' && this.state.test_price !== 0) {
          if(parseInt(this.state.concession_value) === 0 || this.state.test_price_concession === 0) {
            if (this.state.test_price_concession !== '') {
              this.handleAddTest(this.state.organisation_id, this.state.test_id, this.state.test_name, this.state.test_price, this.state.test_price_concession, 
                this.state.total_concession, this.state.test_price_id, this.state.price_type, this.state.test_profile_names, this.state.outsourced_status,
                this.state.min_cap,
              )
            } else this.handleAddTest(this.state.organisation_id, this.state.test_id, this.state.test_name, this.state.test_price, 0, 0,this.state.test_price_id, 
              this.state.price_type, this.state.test_profile_names, this.state.outsourced_status, this.state.min_cap)
            this.setState({ test_name: '', test_price: 0, test_price_concession: 0, total_concession: 0, openBillingFooter: true })
          } else this.setState({ enableConcession: true })
        }
      }
    }
  }

  handleClose = () => {
    this.setState({ enableConcession: false })
  }

  handleCloseCAPError = () => {
    this.setState({ minCAPError: false })
  }

  handleSubmitPopUp = () => {
    this.setState({ concession_value: 0 })
  }

  handleCancelPopUp = () => {
    this.setState({ test_price_concession: 0 })
  }

  handleConcessionPopUp = () => {
    const { enableConcession } = this.state;
    return (
      <Dialog
        open={enableConcession}
        onClick={this.handleClose}
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={11} className={classes.type}>
              <Typography>Confirm Test Concession</Typography>
            </Grid>
            <Grid item xs={1}>
              <Grid container justify='flex-end'>
                <IconButton onClick={this.handleClose} >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider/>
        <Grid container>
          <DialogContent>
            <Typography>
              Are you sure you want to add Concession to the test? If the Concession is added then Overall Concession will be set to zero.
              Click No if you are not sure
            </Typography>
          </DialogContent>
        
          <Grid item xs={12}><Divider/></Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            <DialogActions>
              <Button
                color='primary'
                variant='outlined'
                className={classes.buttonText}
                onClick={this.handleCancelPopUp}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                className={classes.buttonText}
                onClick={this.handleSubmitPopUp}
              >
                Yes! I'm sure
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    )
  }

  handleAddTest = (organisation_id, test_id, test_name, price, concession, total_concession_bill, test_price_id, price_type, test_profile_names, outsourced_status, min_cap) => {
    const test_price = parseInt(price)
    const test_discount_price = parseInt(concession)
    const test_payable_amount = test_price - test_discount_price
    let total_concession = 0
    if (this.state.patient_tests.length <= 0) {
      this.setState({ patient_tests: [...this.state.patient_tests, { organisation_id, test_id, test_name, test_price, 
        test_discount_price, total_concession, test_price_id, test_payable_amount, price_type, test_profile_names, outsourced_status,
        min_cap }] 
      })
    } else {
      let flag = false;
      this.state.patient_tests.map(item => {
        if (item.test_id === test_id) {
          flag = true
        }
        return flag === false ? this.setState({ patient_tests: [...this.state.patient_tests, { organisation_id, test_id, test_name, test_price, 
          test_discount_price, total_concession, test_price_id, test_payable_amount, price_type, test_profile_names, outsourced_status,
          min_cap }] 
        }) : this.setState({ patient_tests: this.state.patient_tests })
      })
    }
  }

  handleDeleteTest = (id) => {
    const listAfterDelete = this.state.patient_tests.filter(item => item.test_id !== id)
    this.setState({ patient_tests: listAfterDelete, discountLimitWarning: false  })
  }

  handleEachTestDiscount = (Percentage_Concession,item) => {
    const { concession_type, concession_value } = this.state;
    if(item.outsourced_status === '0' || item.outsourced_status === '1') {
      if(concession_type === 1) {
        if(!isNaN((Percentage_Concession * item.test_price) / 100)) {
          return parseInt(((Percentage_Concession * item.test_price)  / 100) + item.test_discount_price);
        } else return item.test_discount_price;
      } else{
        if(!isNaN((concession_value * item.test_price) / 100)) {
          return parseInt(((concession_value * item.test_price)  / 100) + item.test_discount_price)
        } else return item.test_discount_price;
      }
    } else return 0;
  }

  handleWarningClose = () => {
    this.setState({ testDuplicate: false })
  }

  handleDiscountLimit = () => {
    this.setState({ concession_value: 0, discountLimitWarning: true })
  }

  handleDiscountLimitClose = () => {
    this.setState({ discountLimitWarning: false })
  }

  submitBilling = (e) => {
    const { concession_type, concession_value, minCAPError } = this.state;
    let total_concession_bill = 0;
    let newArray = [...this.state.patient_tests]
    this.state.patient_tests.map((item,index) => {
      if(concession_type === 1) {
        if(!isNaN((Percentage_Concession * item.test_price) / 100)) {
          total_concession_bill = parseInt(((Percentage_Concession * item.test_price)  / 100) + item.test_discount_price)
        } else total_concession_bill = item.test_discount_price;
      } else if(!isNaN((concession_value * item.test_price) / 100)) {
        total_concession_bill = parseInt(((concession_value * item.test_price)  / 100) + item.test_discount_price)
      } else total_concession_bill = parseInt(((concession_value * item.test_price)  / 100) + item.test_discount_price)
      newArray[index] = {...newArray[index], total_concession: total_concession_bill}
    })
    this.setState({
      patient_tests: newArray,
      concession_value: parseInt(this.state.concession_value),
      additional_price: parseInt(this.state.additional_price),
      total_price: Total,
      paid_amount: parseInt(this.state.paid_amount),
      due_amount: parseInt(Total - this.state.paid_amount),
    })
    // console.log(this.state);
    this.props.billing(this.state)
    e.preventDefault();
  }

  render() {
    const { billingDate, price_list, test_name, test_price, test_price_concession, patient_tests, additional_price,
      concession_type, concession_value, paid_amount, is_emergency_report, openBillingFooter, comments, testDuplicate,
      enableConcession, test_concession_disable, minCAPError
    } = this.state;

    const { patientWithId, testsData } = this.props;
    let count = 1;
    let SumOfTests = 0;
    let SumOfInternalTests = 0;
    let Price_Concession = 0;
    let discount_price = 0;
    this.state.patient_tests.map(item => {
      discount_price = discount_price + parseInt(item.test_discount_price);
      if(item.outsourced_status === '0' || item.outsourced_status === '1') {
        return SumOfInternalTests = SumOfInternalTests + item.test_price, SumOfTests = SumOfTests + (parseInt(item.test_price) - parseInt(item.test_discount_price))
      }
    })
    this.state.payable_amount = SumOfTests;
    
    if (this.state.additional_price !== '') {
      if (concession_type === 1) {
        if (concession_value !== '') {
          Total = SumOfTests + parseInt(additional_price) - parseInt(concession_value);
          Percentage_Concession = (parseInt(concession_value) * 100) / (SumOfTests);
          SumOfInternalTests = (parseInt(concession_value) * 100) / (SumOfTests);
        }
      } else {
        if (concession_value !== '') {
          Price_Concession = parseInt((parseInt(concession_value) * (SumOfTests)) / 100)
          Total = SumOfTests + parseInt(additional_price) - Price_Concession
        }
      }
    }

    return (
      <Container component="main" spacing={2} maxWidth={false} classes={{ root: classes.containerPadding }}>
        <Grid container direction="row" alignItems="center">
          <ValidatorForm onSubmit={this.submitBilling}>
            <main className={clsx(classes.appBar, {
              [classes.footer]: openBillingFooter
            })}>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <Grid container direction="row" alignContent="center" spacing={3}>
                    <Grid item xs={3}>
                      <Typography>
                        {this.handlePatientDetails(patientWithId)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>
                        Ref: {patientWithId.referraldetails !== null ? patientWithId.referraldetails.referrer_name : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>
                        {patientWithId.organisationdetails !== null ? patientWithId.organisationdetails.organisation_name : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>
                        Reg. No: {patientWithId.id}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{ marginTop: '0px' }}
                          disabled
                          margin="normal"
                          id="date-picker-dialog"
                          placeholder="Date picker inline"
                          format="dd/MM/yyyy hh:mm:ss"
                          value={billingDate}
                          onChange={this.handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}> <Divider /> </Grid>

                <Grid item xs={12}>
                  <Select
                    disabled
                    name='price_list'
                    id='price_list'
                    label='Select for different price lists'
                    value={price_list}
                    onChange={this.handleSelectChange}
                    variant='outlined'
                    margin="dense"
                  >
                    <MenuItem key={1} value={1}>Select for different price lists</MenuItem>
                    <MenuItem key={2} value={2}>List Group Test1</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12}> <Divider /> </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography align="center">
                        Test Name (<Link>See Complete Test List</Link>)
                      </Typography>
                    </Grid>
                    <Grid item xs={3}> <Typography align="center">Price (in &#8377;)</Typography> </Grid>
                    <Grid item xs={3}> </Grid>

                    <Grid item xs={6}>
                      <Autocomplete
                        id='auto'
                        options={testsData}
                        value={test_name}
                        onChange={(e, values) => {
                          if (values !== null) {
                            if (patient_tests.length <= 0) {
                              this.setState({ test_id: values.test_id, organisation_id: values.organisation_id, test_name: values.testdetails.test_name, 
                                test_price: values.price, test_price_id: values.id, price_type: values.price_type, test_profile_names: values.testdetails.test_profile_names,
                                outsourced_status: values.testdetails.testpricedetail.outsource_test ,test_concession_disable: values.testdetails.testpricedetail.outsource_test === '2' ? true : false,
                                min_cap: values.testdetails.testpricedetail.cap
                              })
                              TotalMinCAP = TotalMinCAP + values.testdetails.testpricedetail.cap;
                            } else {
                              patient_tests.map(item => {
                                if (item.test_id !== values.test_id) {
                                  this.setState({ test_id: values.test_id, organisation_id: values.organisation_id, test_name: values.testdetails.test_name,
                                    test_price: values.price, test_price_id: values.id, price_type: values.price_type, test_profile_names: values.testdetails.test_profile_names,
                                    outsourced_status: values.testdetails.testpricedetail.outsource_test, test_concession_disable: values.testdetails.testpricedetail.outsource_test === '2' ? true : false,
                                    min_cap: values.testdetails.testpricedetail.cap
                                  })
                                  TotalMinCAP = TotalMinCAP + values.testdetails.testpricedetail.cap;
                                } else this.setState({ testDuplicate: true })
                                return false;
                              })
                            }
                          }
                          document.getElementById('Concession').focus();
                        }}
                        getOptionLabel={(option) => (
                          option.testdetails !== undefined ? option.testdetails.test_name : option
                        )}
                        filterOptions={option => option.filter((option) => {
                          return option.testdetails.test_name.toLowerCase().indexOf(test_name.toLowerCase()) > -1
                        })}
                        renderOption={option => (
                          <React.Fragment>
                            <Grid container>
                              <Grid item xs={12}>
                                {option.testdetails.test_name}
                              </Grid>
                              <Grid item xs={6}>
                                Price :{option.price}
                              </Grid>
                              <Grid item xs={6}>
                                <Grid container justify='flex-end'>
                                  <b>{option.source}</b>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}> <Divider /> </Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='Test Name'
                            name='test_name'
                            value={test_name}
                            onChange={this.handleTextChange}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextValidator
                        name='test_price'
                        value={test_price}
                        disabled
                        label='Price in &#8377;'
                        onKeyPress={this.handleKeyPress}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextValidator
                        id='Concession'
                        name='test_price_concession'
                        disabled={test_concession_disable}
                        value={test_price_concession}
                        onChange={this.handleAmountChange}
                        label='Concession'
                        onKeyPress={this.handleKeyPress}
                        validators={['required']}
                        errorMessages={['This field is required', 'Invalid Mobile Number']}
                        inputProps={{
                          maxLength: 5,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {testDuplicate ?
                    (
                      <Grid container alignItems='center' justify='center' spacing={1} style={{ backgroundColor: '#fcf8e3' }}>
                        <Grid item xs={11}>
                          <Typography variant='subtitle2'>Warning! This test is already added. </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton onClick={this.handleWarningClose}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ) : false
                  }
                </Grid>
                <Grid item xs={12}> <Divider /> </Grid>

                <Grid item xs={12}>
                  {patient_tests.map(item => (
                    <Grid container spacing={1} key={item.test_id} justify='flex-end' alignItems='flex-end'>
                      <Grid item xs={1}> <Typography>{count++}</Typography> </Grid>
                      <Grid item xs={6}> <Typography>{item.test_name}</Typography> </Grid>
                      <Grid item xs={2}> {item.test_price} </Grid>
                      <Grid item xs={2}> 
                        {this.handleEachTestDiscount(SumOfInternalTests,item)}
                      </Grid>
                      <Grid item xs={1}> <CloseIcon fontSize="small" key={item.test_id} onClick={() => this.handleDeleteTest(item.test_id)} /> </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={11}>
                        {item.test_profile_names.length !== 0 ? item.test_profile_names.test_profile_names.map((list,index) => (
                          <span key={index}>{list}
                            <span>{item.test_profile_names.length - 1 === index ? '' : ','}&nbsp;</span>
                          </span>
                        )) : ''}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                {openBillingFooter === true ? (patient_tests.length > 0 ?
                  <Grid item xs={12}>
                    <ExpansionPanel square disabled>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ backgroundColor: '#eff0f1' }}
                      >
                        <Typography align='center'>
                          Other Info
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Container>
                          <Grid container alignItems='center' spacing={2}>
                            <Grid item xs={4}>
                              <Typography>Select Doctor Referral (if any):</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                margin='dense'
                                onChange={this.handleTextChange}
                              />
                            </Grid>
                            <Grid item xs={4}><Link>Add Doctor</Link></Grid>

                            <Grid item xs={4}>
                              <Typography>Select Organization (if any):</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                margin='dense'
                                onChange={this.handleTextChange}
                              />
                            </Grid>
                            <Grid item xs={4}><Link>Add Org</Link></Grid>

                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color='primary'
                                  />
                                }
                                label='Home Collection Bill'
                                labelPlacement='end'
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                Home Collection Date :
                              </Typography>
                            </Grid>
                          </Grid>
                        </Container>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
                  : '') : false}

                {
                  openBillingFooter === true ? (patient_tests.length > 0 ?
                    <Grid item xs={12}> <Divider /> </Grid>
                    : '') : false
                }

                {
                  openBillingFooter ? (concession_type === 2 && concession_value > 100 ? this.handleDiscountLimit() :
                    (concession_type === 1 && concession_value > Total ? this.handleDiscountLimit() : false))
                    : false
                }

                <Grid item xs={12}>
                  {
                    this.state.discountLimitWarning === true ?
                      <Grid container alignItems='center' justify='center' spacing={1} style={{ backgroundColor: '#fcf8e3' }}>
                        <Grid item xs={11}>
                          <Typography variant='subtitle2'>You can give only 100% discount</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton onClick={this.handleDiscountLimitClose}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                      : false
                  }
                </Grid>

                <Grid item xs={12}>
                  {minCAPError ?
                    (
                      <Grid container alignItems='center' justify='center' spacing={1} style={{ backgroundColor: '#fcf8e3' }}>
                        <Grid item xs={11}>
                          <Typography variant='subtitle2'> Discount Price is exceeding Min CAP of Test. So you cannot give the Discount.</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton onClick={this.handleCloseCAPError}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ) : false
                  }
                </Grid>

                <Grid item xs={12}>
                  {openBillingFooter === true ? (patient_tests.length > 0 ?
                    <Grid container direction='row'>
                      <Grid item xs={6}>

                        <Grid item xs={12}>
                          <Grid container alignItems='center' spacing={1}>
                            <Grid item xs={5}>
                              <Typography>
                                Additional price (if any):
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                name='additional_price'
                                value={additional_price}
                                onChange={this.handlePriceChange}
                                margin='dense'
                                inputProps={{
                                  maxLength: 5,
                                }}
                                onKeyPress={(e) => e.key === 'Enter' ? e.preventDefault() : ''}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container alignItems='center' spacing={1}>
                                <Grid item xs={5}>
                                  <Select
                                    name='concession_type'
                                    id='concession_type'
                                    value={concession_type}
                                    onChange={this.handleSelectChange}
                                    variant='outlined'
                                    margin="dense"
                                  >
                                    <MenuItem key={1} value={1}>Concession (in INR)</MenuItem>
                                    <MenuItem key={2} value={2}>Concession (in %)</MenuItem>
                                  </Select>
                                </Grid>
                                <Grid item xs={5}>
                                  <TextValidator
                                    name='concession_value'
                                    value={concession_value}
                                    onChange={this.handleConcessionAmountChange}
                                    margin='dense'
                                    inputProps={{
                                      maxLength: 5,
                                    }}
                                    onKeyPress={(e) => e.key === 'Enter' ? e.preventDefault() : ''}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  {concession_type === 1 ? Percentage_Concession.toFixed(2) + '%' : Price_Concession + 'INR'}
                                </Grid>
                              </Grid>
                            </Grid>

                            {openBillingFooter === true ? (concession_value > 0 || discount_price > 0 ?
                              <Grid item xs={9}>
                                <TextValidator
                                  name='comments'
                                  value={comments}
                                  multiline
                                  rows='3'
                                  cols={52}
                                  placeholder='Comments'
                                  onChange={this.handleTextChange}
                                  validators={['required']}
                                  errorMessages={['This Field is required']}
                                />
                              </Grid>
                              : '') : false
                            }
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography className={classes.branchDetailsStyle}>
                          Organisation Advance: INR {patientWithId.organisation_advance_amount !== undefined ? patientWithId.organisation_advance_amount : 0}
                        </Typography>
                        <Typography variant='h6' className={classes.payableAmount}>
                          Payable Advance: INR {Total}
                        </Typography>
                        <Grid container direction='row' alignItems='center' >
                          <Grid item xs={5}>
                            <Typography>Amount Paid: </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <TextValidator
                              name='paid_amount'
                              value={paid_amount}
                              onChange={this.handlePriceChange}
                              margin='dense'
                              inputProps={{
                                maxLength: 7,
                              }}
                              onKeyPress={(e) => e.key === 'Enter' ? e.preventDefault() : ''}
                            />
                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                    : '') : false
                  }
                </Grid>
              </Grid>
            </main>

            <Drawer open={openBillingFooter === true ? patient_tests.length > 0 : false} anchor='bottom' variant="persistent">
              <Grid container alignItems='center' justify='center' className={classes.type}>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={is_emergency_report}
                        color='primary'
                        onClick={e => this.setState({ is_emergency_report: (e.target.checked === true ? 1 : 0) })}
                      />
                    }
                    label='Critical/Emergency Reports.'
                    labelPlacement='end'
                  />
                </Grid>

                <Grid item xs={5}>
                  <Typography>
                    {Total >= parseInt(paid_amount) ? ('Balance remaining: INR   ' + (Total - paid_amount)) : ('Return Amount: INR   ' + (paid_amount - Total))}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    size='large'
                    variant="contained"
                    onSubmit={this.submitBilling}
                    className={classes.buttonText}
                  >
                    Confirm & Proceed
                  </Button>
                </Grid>

              </Grid>
            </Drawer>
          </ValidatorForm>

          <Grid item xs={12}>
            {enableConcession ? this.handleConcessionPopUp() : ''}
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  testsData: state.login.testsData,
})

export default connect(mapStateToProps, { getTestPrices, billing })(Billing);