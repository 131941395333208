import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';

import {
  Grid,
} from '@material-ui/core';

import AccessionIDRenderer from './AccessionIDRenderer';
import PatientDetailsRenderer from '../BillWise/PatientDetailsRenderer';
import TestsRenderer from './TestsRenderer';
import SampleRenderer from './SampleRenderer';
import CollectButtonRenderer from './CollectButtonRenderer';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { getBarcodesData, updateTestReceive } from '../../../Store/ActionCreators';
import '../../../App.module.css';

class SampleWise extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      columnDefs: [
        { headerName: 'Accession Id', field: 'accession_id', filter: 'agTextColumnFilter', cellRenderer: 'accessionIdRenderer', width: 200, cellStyle: {lineHeight: '30px'}},
        { headerName: 'Patient Details', field: 'patient_details', filter: 'agTextColumnFilter', cellRenderer: 'patientDetailsRenderer', width: 300, cellStyle: {lineHeight: '30px'} },
        { headerName: 'Tests', field: 'tests', filter: 'agTextColumnFilter', cellRenderer: 'testsRenderer', width: 350, cellStyle: {marginTop:'5px',lineHeight: '20px'} },
        { headerName: 'Sample', field: 'sample', filter: 'agTextColumnFilter', cellRenderer: 'sampleRenderer', width: 175, cellStyle: {lineHeight: '30px'} },
        { headerName: 'Billing Date', field: 'billing_date', filter: 'agTextColumnFilter', cellRenderer: 'collectButtonRenderer', width: 450, cellStyle: {lineHeight: '30px'} }
      ],
      frameworkRenderer: {
        accessionIdRenderer: AccessionIDRenderer,
        patientDetailsRenderer: PatientDetailsRenderer,
        testsRenderer: TestsRenderer,
        sampleRenderer: SampleRenderer,
        collectButtonRenderer: CollectButtonRenderer,
      },
      Collect: 'Collect'
    }
  }

  bodyScroll = (params) => {
    if (params.direction === 'vertical') {
      let bodyHeight = params.api.gridPanel.bodyHeight,
        top = params.top,
        currentLength = params.api.rowModel.rowsToDisplay.length;
      var lengthToRecall = (currentLength * 100) - bodyHeight - 100;
      if (top >= lengthToRecall && top > (this.state.top + 100)) {
        this.props.recall(currentLength, currentLength + 5);
        this.state.top = top;
      }
    }
  }

  rowHeightRenderer = (params) => {
    return 100;
  }

  render() {
    const { columnDefs } = this.state;
    const { data } = this.props;
    return (
      <Grid className='ag-theme-material sw-ag-grid'>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          getRowHeight={this.rowHeightRenderer}
          onBodyScroll={this.bodyScroll}
          frameworkComponents={this.state.frameworkRenderer}
        />
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  barCode: state.login.barCode,
})

export default connect(mapStateToProps, { getBarcodesData, updateTestReceive })(SampleWise);
