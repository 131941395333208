import React from 'react';

import {
  Grid,
  Tooltip
} from '@material-ui/core';

class AccessionIDRenderer extends React.Component {

  handleAccessionId = (data, scroller) => {
    let samples = JSON.parse(data.samples)
    let sampleCount = samples.length
    return (
      <Grid container alignItems='center' style={scroller} className="scroller">
        {samples.map((item, index) => {
          index++;
          return (
            <span key={item.sample_id}>
              {index < 5 ? <div>{item.accession_id}</div>
                : (sampleCount > 4  ?
                    <span>&nbsp;&nbsp;
                      <Tooltip placement="top" title={<div style={{ maxWidth: 100 }}>
                        {samples.map((item,index) => {return (<div key={index}>{item.accession_id}</div>)})}</div>}><span style={{ color: 'blue' }}>more</span>
                      </Tooltip>
                    </span> 
                  : ''
                )
              }
            </span>
          )
        })}
      </Grid>
    )
  }

  render() {
    const data = this.props.node.data;
    const scroller = {
      maxHeight: "90px", 
      overflowY: 'auto'
    };
    return (
      <div>
        {this.handleAccessionId(data, scroller)}
      </div>
    );
  }
}

export default AccessionIDRenderer;