import React from 'react';
import { connect } from 'react-redux';

import {
  List,
  ListItem,
  ListItemText,
  Link,
  Collapse,
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { logout } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class SideDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openReferralList: false,
      openDoctorList: false,
      openDepartmentList: false,
      openOrganizationList: false,
      openListManagement: false,
      openProfileList: false,
      openMarketingList: false,
      openPrivilegeCardList: false,
      openCenterList: false,
      openActivityLogList: false,
      openUserManagement: false,
    }
  }

  handleReferralClick = () => {
    this.setState({ openReferralList: !this.state.openReferralList })
  }

  handleDoctorClick = () => {
    this.setState({ openDoctorList: !this.state.openDoctorList })
  }

  handleDepartmentClick = () => {
    this.setState({ openDepartmentList: !this.state.openDepartmentList })
  }

  handleOrganizationClick = () => {
    this.setState({ openOrganizationList: !this.state.openOrganizationList })
  }

  handleListClick = () => {
    this.setState({ openListManagement: !this.state.openListManagement })
  }

  handleProfileClick = () => {
    this.setState({ openProfileList: !this.state.openProfileList })
  }

  handleMarketingClick = () => {
    this.setState({ openMarketingList: !this.state.openMarketingList })
  }

  handlePrivilegeClick = () => {
    this.setState({ openPrivilegeCardList: !this.state.openPrivilegeCardList })
  }

  handleCenterClick = () => {
    this.setState({ openCenterList: !this.state.openCenterList })
  }

  handleActivityLogClick = () => {
    this.setState({ openActivityLogList: !this.state.openActivityLogList })
  }

  handleUserManagement = () => {
    this.setState({ openUserManagement: !this.state.openUserManagement })
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { openReferralList, openDoctorList, openDepartmentList, openOrganizationList, openMarketingList, 
      openPrivilegeCardList, openCenterList, openActivityLogList, openProfileList,
      openUserManagement, openListManagement,
    } = this.state;
    return (
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {/* <ListItem button>
          <Link underline="none" color="inherit">
            <ListItemText> <div className={classes.drawerMainList}> Account Overview </div> </ListItemText>
          </Link>
        </ListItem> */}

        <ListItem button onClick={this.handleReferralClick}>
          <ListItemText> <div className={classes.drawerMainList}> Referral Management </div> </ListItemText>
          {openReferralList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openReferralList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit" href="/referral-list"> Referral List </Link>
            </ListItem>
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Billing List </Link>
            </ListItem> */}
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Referral Settlements </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Doctor Revenue Tracker </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Referral Performance </Link>
            </ListItem> */}
          </List>
        </Collapse>

        <ListItem button onClick={this.handleDoctorClick}>
          <ListItemText> <div className={classes.drawerMainList}> Doctor Management </div> </ListItemText>
          {openDoctorList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openDoctorList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link href='/doctor-list' underline="none" color="inherit"> Doctor List </Link>
            </ListItem>
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Doctor Revenue Management </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Doctor Revenue Tracker </Link>
            </ListItem> */}
          </List>
        </Collapse>

        <ListItem button onClick={this.handleOrganizationClick}>
          <ListItemText> <div className={classes.drawerMainList}> Organization Management </div> </ListItemText>
          {openOrganizationList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openOrganizationList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link href='/organization-list' underline="none" color="inherit"> Organization List </Link>
            </ListItem>
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Organization Revenue List </Link>
            </ListItem> */}
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Advance Management </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Organization wise marketing Report </Link>
            </ListItem>  */} {/* commented out earlier*/}
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Bulk Organization Upload </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Organization Performance[On Trial] </Link> 
            </ListItem> */}
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Ledger Dashboard </Link>
            </ListItem> */}
          </List>
        </Collapse>

        <ListItem button>
          <Link underline="none" color="inherit" href="/addoutsource">
            <ListItemText> <div className={classes.drawerMainList}> Outsourcing Management </div> </ListItemText>
          </Link>
        </ListItem>

        <ListItem button onClick={this.handleListClick}>
          <ListItemText> <div className={classes.drawerMainList}> List Management </div> </ListItemText>
          {openListManagement ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openListManagement} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link href='/list-management' underline="none" color="inherit"> List Management </Link>
            </ListItem>
            {/* <ListItem button>
              <Link underline="none" color="inherit"> List Upload Excel </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Group Management </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Patient Groups </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Add tests to list(bulk) </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Upload Organization Price List </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Update Net Amount </Link>
            </ListItem> */}
          </List>
        </Collapse>

        <ListItem button onClick={this.handleProfileClick}>
          <ListItemText> <div className={classes.drawerMainList}> Profile & Test Management </div> </ListItemText>
          {openProfileList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openProfileList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link href='/reportlist' underline="none" color="inherit"> Test List </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit" href='/profile-list'> Profile List </Link>
            </ListItem>
            <ListItem button>
              <Link href='/addreport' underline="none" color="inherit"> Add / Edit Test </Link>
            </ListItem>
            <ListItem button>
              <Link href='/billsettings' underline="none" color="inherit"> Bill Settings </Link>
            </ListItem>
            {/*
            <ListItem button>
              <Link href='/invoicesettings' underline="none" color="inherit"> Invoice Settings </Link>
            </ListItem>
            <ListItem button>
              <Link href='/reportsettings' underline="none" color="inherit"> Report Settings </Link>
            </ListItem> */}
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Upload TestList Excel </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Cancelled Tests </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Other Settings </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Dictionary Mapping </Link>
            </ListItem> */}
          </List>
        </Collapse>

        <ListItem button onClick={this.handleDepartmentClick}>
          <ListItemText> <div className={classes.drawerMainList}> Department Management </div> </ListItemText>
          {openDepartmentList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openDepartmentList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link href='/department-list' underline="none" color="inherit"> Department List </Link>
            </ListItem>
          </List>
        </Collapse>

        {/* <ListItem button onClick={this.handleMarketingClick}>
          <ListItemText> <div className={classes.drawerMainList}> Marketing Management </div> </ListItemText>
          {openMarketingList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openMarketingList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit"> Staff Management </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Add / Edit Staff </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={this.handlePrivilegeClick}>
          <ListItemText> <div className={classes.drawerMainList}> Privilege Card Management </div> </ListItemText>
          {openPrivilegeCardList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openPrivilegeCardList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit"> Add / Edit Card </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Assign Cards </Link>
            </ListItem>
          </List>
        </Collapse> */}

        <ListItem button onClick={this.handleCenterClick}>
          <ListItemText> <div className={classes.drawerMainList}> Center Management </div> </ListItemText>
          {openCenterList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openCenterList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit" href='/center-list'> Center List </Link>
            </ListItem>
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Center Details </Link>
            </ListItem> */}
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Feedback </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Payment </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Online Payment </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Instant Comments </Link>
            </ListItem> */}
          </List>
        </Collapse>

        <ListItem button onClick={this.handleUserManagement}>
          <ListItemText> <div className={classes.drawerMainList}> User Management </div> </ListItemText>
          {openUserManagement ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openUserManagement} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit" href="/adduser"> Add / Edit User </Link>
            </ListItem>
            {/* <ListItem button>
              <Link underline="none" color="inherit"> Update Password </Link>
            </ListItem> */}
          </List>
        </Collapse>

        {/*<ListItem button onClick={this.handleActivityLogClick}>
          <ListItemText> <div className={classes.drawerMainList}> Activity Log </div> </ListItemText>
          {openActivityLogList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openActivityLogList} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button>
              <Link underline="none" color="inherit"> Activity Track </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Activity Analytics </Link>
            </ListItem>
          </List>
        </Collapse>

        <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Advanced Search </div> </ListItemText>
          </Link>
        </ListItem> */}

        <ListItem button onClick={this.handleLogout}>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Logout </div> </ListItemText>
          </Link>
        </ListItem>

      </List>
    );
  }
}

export default connect(null, { logout })(SideDrawer);
