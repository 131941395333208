import React from 'react';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Dialog,
  Tabs,
  Tab,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ReferralInfo from '../ReferralInfo';
// import ReferralConfiguration from '../ReferralConfiguration';
// import LoginAndAccess from '../LoginAndAccess';
import TableHeadSort from '../../Common/TableHeadSort';

import { referralList, searchReferral, organizationList } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class ReferralList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referralDetailsTab: 0,
      dialog: false,
      search: '',
      anchorEl: null,
      rows: [],
      list: [],
      order: 'asc',
      orderBy: 'referrer_name',
      openSearch: false,
      editList: [],

      rowsPerPage: 15,
      page: 0,
    }
    this.handleReferrallist();
    this.props.organizationList();
  }

  handleReferrallist = async () => {
    await this.props.referralList()
    this.setState({ rows: this.props.referral })
  }

  debounce = (func, delay) => { 
    let debounceTimer 
    return function() { 
        const context = this
        const args = arguments 
            clearTimeout(debounceTimer) 
                debounceTimer 
            = setTimeout(() => func.apply(context, args), delay) 
    } 
  }

  handleChange =  (e) => {
    this.handleSearch( e.target.value)
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSearch = this.debounce(async (value) => {
    if (value !== '') {
        await this.props.searchReferral({ referral: value })
        this.setState({ rows: this.props.searchReferralList })
    } else {
      this.handleReferrallist();
    }
  }, 1500)

  handleTextChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.referrer_name !== null ? item.referrer_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1 : '';
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.referral })
    }
  }

  handleContactTextChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.contact_number !== null ? item.contact_number.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1 : '';
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.referral })
    }
  }

  handleEmailTextChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.email_address !== null ? item.email_address.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1 : '';
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.referral })
    }
  }

  // handlePriceListTextChange = (e) => {
  //   var updatedList = this.state.rows;

  //   if (e.target.value !== '') {
  //     updatedList = updatedList.filter(function (item) {
  //       return item.referrer_name.toLowerCase().search(
  //         e.target.value.toLowerCase()) !== -1;
  //     });
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       rows: updatedList
  //     })
  //   } else {
  //     this.setState({ rows: this.props.referral })
  //   }
  // }

  // handleRevenueListTextChange = (e) => {
  //   var updatedList = this.state.rows;

  //   if (e.target.value !== '') {
  //     updatedList = updatedList.filter(function (item) {
  //       return item.referrer_name.toLowerCase().search(
  //         e.target.value.toLowerCase()) !== -1;
  //     });
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       rows: updatedList
  //     })
  //   } else {
  //     this.setState({ rows: this.props.referral })
  //   }
  // }

  // handleBusinessSourceTextChange = (e) => {
  //   var updatedList = this.state.rows;

  //   if (e.target.value !== '') {
  //     updatedList = updatedList.filter(function (item) {
  //       return item.referrer_name.toLowerCase().search(
  //         e.target.value.toLowerCase()) !== -1;
  //     });
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       rows: updatedList
  //     })
  //   } else {
  //     this.setState({ rows: this.props.referral })
  //   }
  // }

  // handleStateTextChange = (e) => {
  //   var updatedList = this.state.rows;

  //   if (e.target.value !== '') {
  //     updatedList = updatedList.filter(function (item) {
  //       return item.referrer_name.toLowerCase().search(
  //         e.target.value.toLowerCase()) !== -1;
  //     });
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       rows: updatedList
  //     })
  //   } else {
  //     this.setState({ rows: this.props.referral })
  //   }
  // }

  handleTabChange = (event, newValue) => {
    this.setState({ referralDetailsTab: newValue })
  }

  handleTabEvent = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  handleOptionsClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleOptionsClose = () => {
    this.setState({ anchorEl: null })
  };

  handleEditDoctor = (row) => {
    this.setState({ dialog: true, editList: row })
  }

  handleAddReferral = () => {
    const { referralDetailsTab, editList } = this.state;
    return (
      <Dialog open={this.state.dialog} fullWidth maxWidth='md' classes={{ paper: classes.dialogHeight }}>
        <Container className={classes.textAlign} style={{ backgroundColor: '#eff0f1' }} maxWidth={false} classes={{root: classes.containerPadding}}>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item xs={11}>
              <Typography>Referral List</Typography>
            </Grid>
            <Grid item>
              <Grid container justify='flex-end'>
                <IconButton onClick={this.handleCloseDialog}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Tabs
                    value={referralDetailsTab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                  >
                    <Tab className={classes.buttonText} label="Referral Info" {...this.handleTabEvent(1)} />
                    <Tab className={classes.buttonText} label="Configuration"  {...this.handleTabEvent(2)} />
                    <Tab className={classes.buttonText} label="Login & Access"  {...this.handleTabEvent(3)} />
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {referralDetailsTab === 0 || referralDetailsTab === 1 || referralDetailsTab === 2 ?
                <ReferralInfo
                  editList={editList}
                  organizationList={this.props.organizationListItems}
                  handleCloseDialog={this.handleCloseDialog}
                  referralTab={referralDetailsTab}
                  handleReferralShiftTab= {e => this.handleTabChange(e, 1)}
                  handleConfigurationShiftTab= {e => this.handleTabChange(e, 2)}
                  handleConfigurationPrevious= {e => this.handleTabChange(e, 0)}
                  handleLoginPrevious= {e => this.handleTabChange(e, 1)}
                  listUpdate = {this.handleReferrallist}
                /> : ''
              }
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    );
  }

  handleOpenDialog = () => {
    this.setState({ dialog: true, editList: [] })
  }

  handleCloseDialog = () => {
    this.setState({ dialog: false, referralDetailsTab: 0 })
  }

  handleRequestSort = (e, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  }

  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  handleIconButtonClick = () => {
    this.setState({ openSearch: !this.state.openSearch })
  }

  handlePageChange = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 })
  };

  handleTable = () => {
    const headCells = [
      { id: "referrer_name", label: "Doctor Name" },
      { id: "contact_number", label: "Contact" },
      { id: "email_address", label: "Email" },
      { id: "priceList", label: "Price List" },
      { id: "revenueList", label: "Revenue List" },
      { id: "businessSource", label: "Business Source" },
      { id: "state", label: "State" },
      { id: "", label: "" }
    ];
    const { rows, anchorEl, order, orderBy, openSearch, page, rowsPerPage } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Table aria-label="simple table">
        <TableHead style={{ minWidth: 650 }} >
          <TableRow>
            <TableHeadSort
              enableIcon
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              openSearch={openSearch}
              onRequestSort={this.handleRequestSort}
              handleButtonClick={this.handleIconButtonClick}
            />
          </TableRow>
          {openSearch ?
            <TableRow>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Doctor Name'
                  onChange={this.handleTextChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField 
                  margin='dense'
                  variant='outlined'
                  placeholder='Contact'
                  onChange={this.handleContactTextChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Email'
                  onChange={this.handleEmailTextChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Price List'
                  onChange={this.handlePriceListTextChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Revenue List'
                  onChange={this.handleRevenueListTextChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='Business Source'
                  onChange={this.handleBusinessSourceTextChange}
                />
              </TableCell>
              <TableCell align="center">
                <TextField
                  margin='dense'
                  variant='outlined'
                  placeholder='All'
                  onChange={this.handleStateTextChange}
                />
              </TableCell>

            </TableRow>
            : null
          }
        </TableHead>
        <TableBody>
          {this.stableSort((rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows), this.getComparator(order, orderBy)).map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row" onClick={e => this.handleEditDoctor(row)}>{row.referrer_name}</TableCell>
              <TableCell align="center" onClick={e => this.handleEditDoctor(row)}>{row.contact_number}</TableCell>
              <TableCell align="center" onClick={e => this.handleEditDoctor(row)}>{row.email_address}</TableCell>
              <TableCell align="center" onClick={e => this.handleEditDoctor(row)}>
                {/* {row.priceList}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                {/* <IconButton><KeyboardArrowDownIcon fontSize='small' /></IconButton> */}
              </TableCell>
              <TableCell align="center" onClick={e => this.handleEditDoctor(row)}></TableCell>
              <TableCell align="center" onClick={e => this.handleEditDoctor(row)}></TableCell>
              <TableCell align="center" onClick={e => this.handleEditDoctor(row)}><Button variant='contained' className={classes.buttonText} disabled>Temp</Button></TableCell>
              <TableCell align="center">
                <MoreVertIcon onClick={this.handleOptionsClick} />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={this.handleOptionsClose}
                >
                  <MenuItem disabled onClick={this.handleOptionsClose}>Disable Referral</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
           {emptyRows > 0 && emptyRows === 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { search, rows, page, rowsPerPage } = this.state;

    return (
      <Container maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container direction='row' spacing={1}>
          <Grid item xs={6}>
            <Typography>
              Referral List
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction='row' justify='flex-end'>
              <Button variant='outlined' disabled color='primary' className={classes.buttonAccession}>
                Bulk Upload
              </Button>&nbsp;&nbsp;
              <Button variant='outlined' disabled color='primary' className={classes.buttonAccession}>
                Export
              </Button>&nbsp;&nbsp;
              <Button
                variant='contained'
                color='primary'
                className={classes.buttonAccession}
                onClick={this.handleOpenDialog}
              >
                Add Referral
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name='search'
              value={search}
              onChange={this.handleChange}
              variant='outlined'
              fullWidth
              margin='dense'
              className={classes.search}
              label="Search Referral Name/Contact Number..."
              inputProps={{ 'aria-label': 'search google maps' }}
            ><SearchIcon /></TextField>
          </Grid>
          <Grid item xs={6}>
            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={10}
              rowsPerPageOptions={[10, 50]}
              rowsPerPage={10}
              page={0}
            /> */}
            <TablePagination
              component="div"
              count={rows.length}
              rowsPerPageOptions={[5, 10, 15, 20]}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>

          <Grid item xs={12}>
            {this.handleTable()}
          </Grid>
          <Grid item xs={12}>
            {this.state.dialog ? this.handleAddReferral() : ''}
          </Grid>

        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  referral: state.login.referralList,
  searchReferralList: state.login.searchReferralList,
  organizationListItems: state.login.organizationList,
})

export default connect(mapStateToProps, {
  referralList,
  searchReferral,
  organizationList,
})(ReferralList);