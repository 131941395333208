import React from 'react';
import { connect } from 'react-redux';

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Link,
  Collapse,
  Typography,
} from '@material-ui/core';

import Operation from '../../OperationManagement';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { logout, getOperationTests } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class OperationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openWaitingList: false
    }
    this.handleAllTests();
  }

  handleAllTests = async () => {
    await this.props.getOperationTests({ department: 0 });
  }

  handleWaitingList = () => {
    this.setState({ openWaitingList: !this.state.openWaitingList })
  }

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { openWaitingList } = this.state;
    const { operationTests } = this.props;
    let backgroundClass = { backgroundColor: '#3f50b5', color: 'white' };
    let path = window.location.pathname.split('/')
    let windowPath = window.location.pathname
    return (
      <List>
        <ListItem button onClick={this.handleWaitingList}>
          <ListItemText> <div className={classes.drawerMainList}> Waiting List </div> </ListItemText>
          {openWaitingList ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.listStyle}>
            <ListItem button style={windowPath === '/patient-waitinglist' + '/total' ? backgroundClass : (windowPath === '/test-waitinglist' + '/total' ? backgroundClass : null)}>
              <Grid container justify='center'>
                <Grid item xs={9}>
                  <Link underline="none" color="inherit"
                    variant="body2"
                    href={path[1] === 'patient-waitinglist' ? '/patient-waitinglist/total' : (path[1] === 'test-waitinglist' ? '/test-waitinglist/total' : '/patient-waitinglist/total')}
                  >
                    All Tests
                  </Link>
                </Grid>
                <Grid item xs={3}>
                  {operationTests.total_totalRecords}
                </Grid>
              </Grid>
            </ListItem>
            <ListItem button style={windowPath === '/patient-waitinglist' + '/pending' ? backgroundClass : (windowPath === '/test-waitinglist' + '/pending' ? backgroundClass : null)}>
              <Grid container justify='center'>
                <Grid item xs={9}>
                  <Link underline="none" color="inherit" variant="body2"
                    href={path[1] === 'patient-waitinglist' ? '/patient-waitinglist/pending' : (path[1] === 'test-waitinglist' ? '/test-waitinglist/pending' : '/patient-waitinglist/pending')}
                  >
                    Incomplete
                  </Link>
                </Grid>
                <Grid item xs={3}>
                  {operationTests.total_incomplete}
                </Grid>
              </Grid>
            </ListItem>
            <ListItem button style={windowPath === '/patient-waitinglist' + '/partial' ? backgroundClass : (windowPath === '/test-waitinglist' + '/partial' ? backgroundClass : null)}>
              <Grid container justify='center'>
                <Grid item xs={9}>
                  <Link underline="none" color="inherit" variant="body2"
                    href={path[1] === 'patient-waitinglist' ? '/patient-waitinglist/partial' : (path[1] === 'test-waitinglist' ? '/test-waitinglist/partial' : '/patient-waitinglist/partial')}
                  >
                    Partially Completed
                  </Link>
                </Grid>
                <Grid item xs={3}>
                  {operationTests.total_paritallycompleted}
                </Grid>
              </Grid>
            </ListItem>
            <ListItem button style={windowPath === '/patient-waitinglist' + '/completed' ? backgroundClass : (windowPath === '/test-waitinglist' + '/completed' ? backgroundClass : null)}>
              <Grid container justify='center'>
                <Grid item xs={9}>
                  <Link underline="none" color="inherit" variant="body2"
                    href={path[1] === 'patient-waitinglist' ? '/patient-waitinglist/completed' : (path[1] === 'test-waitinglist' ? '/test-waitinglist/completed' : '/patient-waitinglist/completed')}
                  >
                    Completed
                  </Link>
                </Grid>
                <Grid item xs={3}>
                  {operationTests.total_completed}
                </Grid>
              </Grid>
            </ListItem>
            <ListItem button style={windowPath === '/patient-waitinglist' + '/signed' ? backgroundClass : (windowPath === '/test-waitinglist' + '/signed' ? backgroundClass : null)}>
              <Grid container justify='center'>
                <Grid item xs={9}>
                  <Link underline="none" color="inherit" variant="body2"
                    href={path[1] === 'patient-waitinglist' ? '/patient-waitinglist/signed' : (path[1] === 'test-waitinglist' ? '/test-waitinglist/signed' : '/patient-waitinglist/signed')}
                  >
                    Signed
                  </Link>
                </Grid>
                <Grid item xs={3}>
                  {operationTests.total_signed}
                </Grid>
              </Grid>
            </ListItem>

            <List>More Actions</List>

            <ListItem button style={windowPath === '/patient-waitinglist' + '/emergency' ? backgroundClass : (windowPath === '/test-waitinglist' + '/emergency' ? backgroundClass : null)}>
              <Grid container justify='center'>
                <Grid item xs={9}>
                  <Link underline="none" color="inherit"
                    href={path[1] === 'patient-waitinglist' ? '/patient-waitinglist/emergency' : (path[1] === 'test-waitinglist' ? '/test-waitinglist/emergency' : '/patient-waitinglist/emergency')}
                  >
                    Emergency Reports
                </Link>
                </Grid>
                <Grid item xs={3}>
                  {operationTests.emergency || 0}
                </Grid>
              </Grid>

            </ListItem>
            <ListItem button style={windowPath === '/operation' + '/settings' ? backgroundClass : null}>
              <Link underline="none" color="inherit"
                href='/operation/settings'
              >
                Settings
              </Link>
            </ListItem>
            <ListItem button style={windowPath === '/patient-waitinglist' + '/critical' ? backgroundClass : (windowPath === '/test-waitinglist' + '/critical' ? backgroundClass : null)}>
              <Grid container justify='center'>
                <Grid item xs={9}>
                  <Link underline="none" color="inherit"
                    href={path[1] === 'patient-waitinglist' ? '/patient-waitinglist/critical' : (path[1] === 'test-waitinglist' ? '/test-waitinglist/critical' : '/patient-waitinglist/critical')}
                  >
                    Critical Reports
                </Link>
                </Grid>
                <Grid item xs={3}>
                  {operationTests.critical || 0}
                </Grid>
              </Grid>

            </ListItem>
            <ListItem button style={windowPath === '/operation' + '/cancelled-reports' ? backgroundClass : null}>
              <Link underline="none" color="inherit"
                href='/operation/cancelled-reports'
              >
                Cancelled Reports
              </Link>
            </ListItem>
            {/* <ListItem button style={windowPath === '/patient-waitinglist' + '/critical' ? backgroundClass : (windowPath === '/test-waitinglist' + '/critical' ? backgroundClass : null)}>
            <Grid container justify='center'>
              <Grid item xs={9}>
                <Link underline="none" color="inherit"
                  href={path[1] === 'patient-waitinglist' ? '/patient-waitinglist/critical' : (path[1] === 'test-waitinglist' ? '/test-waitinglist/critical' : '')}
                >
                  Critical Reports
                </Link>
              </Grid>
              <Grid item xs={3}>
                {operationTests.critical || 0}
              </Grid>
            </Grid>

          </ListItem> */}
            {/* </List> */}

            {/* <div>More Actions</div> */}

            {/* <ListItem button>
              <Link underline="none" color="inherit"> Emergency Reports </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Critical Reports </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> TAT Exceeded </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Outsources </Link>
            </ListItem>
            <ListItem button>
              <Link underline="none" color="inherit"> Report Prints </Link>
            </ListItem> */}
            <ListItem button style={windowPath === '/operation' + '/dispatched' ? backgroundClass : null}>
              <Link underline="none" color="inherit"
                href='/operation/dispatched'
              >
                History/Dispatched
              </Link>
            </ListItem>
            {/*<ListItem button>
              <Link underline="none" color="inherit"> Settings </Link>
            </ListItem> */}

            {/* <ListItem button>
          <Link underline="none" color="inherit" >
            <ListItemText> <div className={classes.drawerMainList}> Operation/Test Status </div> </ListItemText>
          </Link>
        </ListItem>
        
        <ListItem button>
          <Link underline="none" color="inherit" >
          <ListItemText> <div className={classes.drawerMainList}> Advanced Search </div> </ListItemText>
          </Link>
        </ListItem> */}
            <ListItem button>
              <Link underline="none" color="inherit" href='/archives'>
                <ListItemText> <div className={classes.drawerMainList}> Archives </div> </ListItemText>
              </Link>
            </ListItem>

            <ListItem button onClick={this.handleLogout}>
              <Link underline="none" color="inherit" >
                <ListItemText> <div className={classes.drawerMainList}> Logout </div> </ListItemText>
              </Link>
            </ListItem>
          </List>
        </Collapse>
      </List>
    );
  }
}

const mapStateToProps = state => ({
  operationTests: state.login.operationTests,
})

export default connect(mapStateToProps, {
  logout,
  getOperationTests,
})(OperationList);