import React from 'react';
import clsx from 'clsx';

import {
  Container,
  Grid,
  TextField,
  Divider,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';

import classes from '../../App.module.css';

class AssignListsToOutsource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: ''
    }
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  }

  render() {
    const { list } = this.state;
    return (
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Container maxWidth='md'>
            <Grid container direction='row' alignItems='center' spacing={1}>
              <Grid item xs={3}>
                <Grid container justify='flex-end'>
                  Outsource Centre
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  variant='outlined'
                  margin='dense'
                  placeholder='Select outsource centre'
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Container maxWidth='md'>
            <Grid container direction='row' alignItems='center' spacing={1}>
              <Grid item xs={3}>
                <Grid container justify='flex-end'>
                  List
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <Select
                  fullWidth
                  label='Select list'
                  variant='outlined'
                  margin='dense'
                  name='list'
                  value={list}
                  onChange={this.handleSelectChange}
                >
                  <MenuItem value={10}>Select...</MenuItem>
                  <MenuItem value={20}>Ten</MenuItem>
                  <MenuItem value={30}>Twenty</MenuItem>
                  <MenuItem value={40}>Thirty</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Grid container justify='center'>
            <Grid item>
              <Button
                type="submit"
                color="primary"
                size="medium"
                variant="contained"
                className={clsx(classes.buttonText, classes.submitButton)}
              >
                Assign List
                </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    )
  }
}

export default AssignListsToOutsource;