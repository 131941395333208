import React from 'react';

class BillIDRenderer extends React.Component {
  render() {
    return (
      <div>{this.props.node.data.bill_id}</div>
    );
  }
}

export default BillIDRenderer;