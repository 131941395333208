import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Divider,
  Link,
  IconButton,
  Button,
  MenuItem,
  Menu,
} from '@material-ui/core';

import { ArrowDropDown } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { searchTest, getTestList, updateListPrices, searchOrganization, searchReferral, searchDoctor } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class AddTestList extends React.Component {
  static propTypes = {
    selectedItem: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      items: this.props.selectedItem,
      openTestList: false,
      openAddOrg: false,
      openAddRef: false,

      add_org_list: [],
      add_ref_list: [],
      deletedTest: false,
      moreinfo: false,
      type: 1,
      anchorEl: null,
      commission: '',
      errorCommission: false,
      displayType: 0,

      deletedList: [],
    }
    this.reset = this.state;
    this.props.getTestList({ branch_id: null })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleTextChange = (e, item) => {
    this.props.selectedItem.listprices[e].price = item;
    this.setState({ errorCommission: false })
  }

  handleSearchTest = async (name, value) => {
    if (!value) {
      this.setState({ [name]: value })
    } else {
      await this.props.searchTest({ test: value });
    }
  };

  handleAddTestMenuClick = (item) => {
    let status = false
    if (item !== null) {
      if (item !== null && item.testpricedetail !== null && (this.props.selectedItem.listprices.length > 1 || this.props.selectedItem.listprices.length === 1)) {
        this.props.selectedItem.listprices.map(i => {
          if (i.test_id === item.id) {
            status = true;
          }
        })
        const addItem = {
          test_name: item.test_name,
          list_id: this.props.selectedItem.id,
          listpriceref: '',
          test_id: item.id,
          price: item.testpricedetail !== null ? item.testpricedetail.price : '',
          minimum_selling_price: item.minimum_selling_price,
          mrp_price: item.mrp_price,
        }
        if (!status) {
          this.props.selectedItem.listprices.push(addItem)
        } else return null
        this.setState({ errorCommission: false })
      } else if (item !== null && item.testpricedetail !== null && item !== undefined) {
        const addItem = {
          test_name: item.test_name,
          list_id: this.props.selectedItem.id,
          listpriceref: '',
          test_id: item.id,
          price: item.testpricedetail.price,
          minimum_selling_price: item.minimum_selling_price,
          mrp_price: item.mrp_price,
        }
        this.props.selectedItem.listprices.push(addItem)
      }
    }
  }

  handleMoreInfo = () => {
    this.setState({ moreinfo: !this.state.moreinfo })
  }

  handleOptionsClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleOptionsClose = () => {
    this.setState({ anchorEl: null })
  }

  handleCloseClick = (item) => {
    this.props.selectedItem.listprices = this.props.selectedItem.listprices.filter(list => {
      if (list.test_id !== item.test_id) {
        return list.test_id !== item.test_id;
      } else if (item.listpriceref === undefined) {
        this.setState({
          deletedList: [...this.state.deletedList, {
            test_name: item.test_name,
            list_id: this.props.selectedItem.id,
            listpriceref: item.id,
            test_id: item.id,
            price: item.price,
          }
          ]
        })
      }
    });
    this.setState({ deletedTest: true })
    this.handleAddTest(this.props.selectedItem)
  }

  handleSearchItem = async (name, value) => {
    if (!value) {
      this.setState({ [name]: value })
    } else {
      switch (this.props.selectedItem.list_type_name) {
        case 'Organisation Type':
          return await this.props.searchOrganization({ organisation: value });
        case 'Referral Type':
          return await this.props.searchReferral({ referral: value });
        case 'Doctor Type':
          return await this.props.searchDoctor({ doctor: value });
        default:
          return false;
      }
    }
  }

  handleAdd = () => {
    const { openAddRef } = this.state;
    const { searchDoctorList, searchReferralList, organizationSearchList, selectedItem } = this.props;
    let list = [], name = '', placeholder = '';
    if (selectedItem.list_type_name === 'Organisation Type') {
      list = organizationSearchList;
      name = 'organisation_name';
      placeholder = 'Search Organisation or Group of Organisations'
    } else if (selectedItem.list_type_name === 'Referral Type') {
      list = searchReferralList;
      name = 'referrer_name';
      placeholder = 'Search Referral or Group of Refferals'
    } else if (selectedItem.list_type_name === 'Doctor Type') {
      list = searchDoctorList;
      name = 'doctor_name';
      placeholder = 'Search Doctor or Group of Doctors'
    }

    return (
      <Grid>
        <Autocomplete
          id="asynchronous-search"
          open={openAddRef}
          onOpen={() => {
            this.setState({ openAddRef: true })
          }}
          onClose={() => {
            this.setState({ openAddRef: false });
          }}
          onChange={(e, newValue) => { this.handleAddItemToList(newValue, name); }}
          getOptionLabel={(option) => option[name]}
          renderOption={(option) => (
            <React.Fragment>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>{option[name]}</Typography>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
              </Grid>
            </React.Fragment>
          )}
          options={list}
          loading={list.length === 0 ? false : true}
          renderInput={(params) => (
            <TextField
              fullWidth
              margin='dense'
              placeholder={placeholder}
              className={classes.backgroundColor}
              onChange={e => this.handleSearchItem(e.target.name, e.target.value)}
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {openAddRef && list.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        {this.props.selectedItem.mapped_values.map(item => {
          return (
            item ?
              <Grid container direction='row' key={item.id}>
                <Grid item xs={12} className={classes.type}>
                  <Grid container direction='row' alignItems='center' className={clsx(classes.backgroundColor, classes.box)}>
                    <Grid item xs={10} className={(classes.type)}>
                      <Typography>
                        {(item['name'])}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={e => this.handleDeleteItem(item.id)}>
                        <CloseIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              : ''
          )
        })}
      </Grid>
    )
  }

  handleAddItemToList = (item, name) => {
    if (item !== null && this.props.selectedItem.map_ids.indexOf(item.id) < 0) {
      this.props.selectedItem.mapped_values.push({ id: item.id, name: item[name] });
      this.props.selectedItem.map_ids.push(item.id);
    }
  }

  handleDeleteItem = (id) => {
    this.props.selectedItem.map_ids = this.props.selectedItem.map_ids.filter(itemId => itemId !== id);
    this.props.selectedItem.mapped_values = this.props.selectedItem.mapped_values.filter(item => item.id !== id);
    this.setState({ deletedTest: true })
  }

  handleAddTest = (selectedItem) => {
    return (
      selectedItem.length !== 0 ?
        <Box border={1} className={classes.backgroundColor}>
          {selectedItem !== undefined && selectedItem.listprices.map((item, index) =>
            item !== null ?
              <Grid container direction='row' key={index}>
                <Grid item xs={12} className={classes.type}>
                  <Grid container direction='row' alignItems='center' spacing={1}>
                    <Grid item xs={4}>
                      <Typography variant='body2'>{item.test_name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container justify='flex-end'>
                        <Grid item>
                          <Typography variant='body2'>MRP:</Typography>
                        </Grid>
                        <Grid item>
                          <Grid container justify='flex-start'>
                            <Typography variant='body2'>{item.mrp_price}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      {
                        item.minimum_selling_price !== null && item.minimum_selling_price !== undefined ?
                          <Grid container justify='flex-end'>
                            <Grid item>
                              <Typography variant='body2'>Minimum CAP:</Typography>
                            </Grid>
                            <Grid item>
                              <Grid container justify='flex-start'>
                                <Typography variant='body2'>{item.minimum_selling_price}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          : ''
                      }
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        value={item.price}
                        onChange={e => this.handleTextChange(index, e.target.value)}
                        variant='outlined'
                        margin='dense'
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Grid container justify='flex-end'>
                        <IconButton onClick={e => this.handleCloseClick(item)}>
                          <CloseIcon fontSize='small' />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              : ''
          )}
        </Box>
        : ''
    )
  }

  handleUpdateAssignClick = async () => {
    let map_id = [];
    this.props.selectedItem.mapped_values.map((item, index) => map_id[index] = item.id);

    let listprices = this.props.selectedItem.listprices.map(item => {
      item.price = item.newPrice ? item.newPrice : item.price;
      item.newPrice ? delete item['newPrice'] : (item.price = item.price);
      item.listpriceref = item.id !== undefined ? item.id : '';
      delete item.id;
      delete item.created_at; delete item.ip_address; delete item.updated_at;
      delete item.minimum_selling_price; delete item.mrp_price; delete item.test_name;
      delete item.testdetails; delete item.testpricedetails; delete item.department_name;
      delete item.category_name;
      return item
    })
    let map_ids = this.props.selectedItem.map_ids;
    await this.props.updateListPrices({ listref: this.props.selectedItem.id, map_ids, listprices, deletelistprices: this.state.deletedList })
    if (this.props.updateListPricesSuccess) {
      window.location.reload();
    } else alert('Invalid Data');
  }

  handleUpdate = () => {
    const { type, commission } = this.state;
    if (this.state.commission !== '') {
      if (type === 1) {
        this.props.selectedItem.listprices.map(item => {
          if (item.minimum_selling_price === 0 || item.minimum_selling_price === undefined) {
            let percentage = Math.round((commission / 100) * item.mrp_price);
            item.price = percentage;
          } else {
            this.props.selectedItem.listprices.map(item => {
              if (commission > 100) {
                let percentage = Math.round((commission / 100) * item.mrp_price);
                item.price = percentage;
              } else {
                item.price = item.minimum_selling_price;
              }
            })
          }
        })
        this.setState({ errorCommission: false })
      } else if (type === 2) {
        this.props.selectedItem.listprices.map(item => {
          if (item.minimum_selling_price === 0 || item.minimum_selling_price === undefined) {
            item.price = commission;
          } else {
            this.props.selectedItem.listprices.map(item => {
              if (item.price < commission) {
                item.price = commission;
              } else {
                item.price = item.minimum_selling_price;
              }
            })
          }
        })
        this.setState({ errorCommission: false })
      } else if (type === 3) {
        this.props.selectedItem.listprices.map(item => {
          if (item.minimum_selling_price === 0 || item.minimum_selling_price === undefined) {
            if (commission > item.price) {
              item.price = 0;
            } else item.price = item.mrp_price - commission;
          } else {
            let value = item.mrp_price - commission;
            if (value > item.minimum_selling_price) {
              item.price = value;
            } else if (value === item.minimum_selling_price || value < item.minimum_selling_price) {
              item.price = item.minimum_selling_price
            }
          }
        })
        this.setState({ errorCommission: false })
      }
    } else {
      this.setState({ errorCommission: true })
    }
  }

  handleAllTests = () => {
    if (this.props.addTestList && this.props.addTestList.list.length) {
      this.handleAddTestMenuClick(this.props.addTestList.list)
    }
  }

  handleTestWise = () => {
    this.props.selectedItem.listprices.sort((a, b) => (a.test_name > b.test_name) ? 1 : -1)
    this.setState({ anchorEl: null })
  }

  handleCategoryWise = () => {
    this.props.selectedItem.listprices.sort((a, b) => (a.testdetails.category_name > b.testdetails.category_name) ? 1 : -1)
    this.setState({ anchorEl: null })
  }

  handleDepartmentWise = () => {
    this.props.selectedItem.listprices.sort((a, b) => (a.testdetails.department_name > b.testdetails.department_name) ? 1 : -1)
    this.setState({ anchorEl: null })
  }

  render() {
    const { openTestList, moreinfo, type, anchorEl, commission, errorCommission, displayType } = this.state;
    const { selectedItem, searchTestList } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Grid container justify='flex-end'>
            <Button
              variant='contained'
              color='primary'
              className={classes.buttonAccession}
              onClick={this.handleUpdateAssignClick}
            >
              Update & Assign List
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.expansionPanelColor}>
            <Container maxWidth={false} classes={{ root: classes.containerPadding }}>
              <Grid container spacing={1}>
                <Grid item xs={(selectedItem.list_type_name !== 'Outsource Type' && selectedItem.list_type_name !== 'List Discount Type') ? 7 : 12}>
                  <Grid container direction='row' justify='center' spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' align='center'>
                        Add test(s) to list: {selectedItem !== null ? selectedItem.list_type_name : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        id="asynchronous-search"
                        open={openTestList}
                        onOpen={() => {
                          this.setState({ openTestList: true })
                        }}
                        onClose={() => {
                          this.setState({ openTestList: false });
                        }}
                        onChange={(e, newValue) => this.handleAddTestMenuClick(newValue)}
                        getOptionLabel={(option) => option.test_name}
                        renderOption={(option) => (
                          <React.Fragment>
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography>Test Name: {option.test_name}</Typography>
                              </Grid>
                              <Grid item xs={12}>Id: {option.id}</Grid>
                              <Grid item xs={12}><Typography>Test Code: {option.display_code}</Typography></Grid>
                              <Grid item xs={12}><Divider /></Grid>
                            </Grid>
                          </React.Fragment>
                        )}
                        options={searchTestList}
                        loading={searchTestList.length === 0 ? false : true}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            margin='dense'
                            placeholder='Test name'
                            className={classes.backgroundColor}
                            onChange={e => this.handleSearchTest(e.target.name, e.target.value)}
                            {...params}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {openTestList && searchTestList.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>

                    {moreinfo ?
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <TextField
                              name='commission'
                              value={commission}
                              onChange={this.handleChange}
                              error={errorCommission}
                              fullWidth
                              variant='outlined'
                              margin='dense'
                              placeholder='Enter commission'
                              className={classes.backgroundColor}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <TextField
                              select
                              name='type'
                              value={type}
                              onChange={this.handleChange}
                              fullWidth
                              variant='outlined'
                              margin='dense'
                              className={classes.backgroundColor}
                            >
                              <MenuItem key={1} value={1}>% of test price</MenuItem>
                              <MenuItem key={2} value={2}>INR for all tests</MenuItem>
                              <MenuItem key={3} value={3}>INR subtract from all test prices</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={3}>
                            <Grid container justify='flex-start' className={classes.headerContainer}>
                              <Grid item xs={6}>
                                <Button
                                  color='primary'
                                  variant='contained'
                                  className={classes.buttonAccession}
                                  onClick={this.handleUpdate}
                                >
                                  Update
                              </Button>
                              </Grid>
                              <Grid item xs={6}>
                                <Button
                                  variant='outlined'
                                  className={classes.buttonAccession}
                                  onClick={this.handleAllTests}
                                >
                                  All Tests
                              </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      : ''
                    }

                    <Grid item xs={12}>
                      <Grid container justify='flex-end' direction='row'>
                        <Grid item>
                          <Link onClick={this.handleMoreInfo}>
                            More Info
                          </Link>&nbsp;&nbsp;
                          <Link onClick={this.handleOptionsClick}>
                            Display Type
                            <IconButton className={classes.buttonPadding}>
                              <ArrowDropDown fontSize="small" />
                            </IconButton>
                          </Link>
                          <Menu
                            name='displayType'
                            value={displayType}
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={this.handleOptionsClose}
                          >
                            <MenuItem key={1} value={1} onClick={this.handleTestWise}>Test wise</MenuItem>
                            <MenuItem key={2} value={2} onClick={this.handleCategoryWise}>Category wise</MenuItem>
                            <MenuItem key={3} value={3} onClick={this.handleDepartmentWise}>Department wise</MenuItem>
                          </Menu>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      {
                        this.handleAddTest(this.props.selectedItem)
                      }
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Divider orientation='vertical' variant='middle' />
                </Grid>
                {(selectedItem.list_type_name !== 'Outsource Type' && selectedItem.list_type_name !== 'List Discount Type') ?
                  <Grid item xs={4} className={classes.type}>
                    <Grid container direction='row'>
                      <Grid item xs={9}>
                        <Typography>
                          List assigned to:
                      </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        {/* <Link>
                        +New Group
                      </Link> */}
                      </Grid>

                      <Grid item xs={12}>
                        {/* {selectedItem.list_type_name === 'Organisation Type' ? this.handleAddOrganization() : this.handleAddReferral()} */}
                        {this.handleAdd(selectedItem.mapped_values)}
                      </Grid>
                    </Grid>

                  </Grid> :
                  ''
                }

              </Grid>
            </Container>
          </Box>

        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  searchTestList: state.login.searchTest,
  addTestList: state.login.addTestList,
  organizationSearchList: state.login.searchList,
  searchReferralList: state.login.searchReferralList,
  searchDoctorList: state.login.searchDoctorList,
  updateListPricesSuccess: state.login.updateListPricesSuccess,
})

export default connect(mapStateToProps, {
  searchTest,
  getTestList,
  updateListPrices,
  searchOrganization,
  searchReferral,
  searchDoctor
})(AddTestList);