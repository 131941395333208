import React from 'react';

import {
  Link,
  Typography,
} from '@material-ui/core';

class Copyright extends React.Component {
  render() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">

        <Typography variant="caption" color="initial" display="block">
          Intandemly Technologies Private Limited does not provide services in the United States under the name LIMS.
        </Typography>

          <Link color="inherit" target="_blank" href="https://intandemly.com/">
            About LIMS for Diagnostics
          </Link>&nbsp;
          
          {new Date().getFullYear()}&nbsp;
          {'.'}&nbsp;

          <Link color="inherit" target="_blank" href="https://intandemly.com/">
            LIMS in Press
          </Link>&nbsp;{'.'}&nbsp;

          <Link color="inherit" target="_blank" href="https://intandemly.com/">
            Terms & Conditions 
          </Link>&nbsp;{'.'}&nbsp;

          <Link color="inherit" target="_blank" href="https://intandemly.com/">
            Privacy Policy
          </Link>&nbsp;{'.'}&nbsp;

          Version 3.5&nbsp;{'.'}&nbsp;
          @ Intandemly Technologies Pvt.Ltd
        
      </Typography>
    );
  }
}

export default Copyright;