import React from 'react';
// import clsx from 'clsx';
import { connect } from 'react-redux';

// import {
//   Container,
//   Grid,
//   Typography,
//   Tabs,
//   Tab,
//   Button,
// } from '@material-ui/core';

// import { ArrowForwardIos, Refresh, FilterList } from '@material-ui/icons';

// import AddSampleType from '../AddSampleType';

// import {} from '../../Store/ActionCreators';

// import classes from '../../App.module.css';

class SampleMapping extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     sample_type: 1,
  //     add_sample: false,
  //     sample_type_list: false,
  //     sample_mapping: false,
  //     rows: [],
  //   }
  //   // this.handleSampleMappingList();
  // }

  // handleTabEvent = index => {
  //   return {
  //     id: `simple-tab-${index}`,
  //     "aria-controls": `simple-tabpanel-${index}`
  //   }
  // }

  // handleTabChange = (event, newValue) => {
  //   this.setState({ sample_type: newValue })
  // }

  render() {
    // const { sample_type, add_sample, editItem } = this.state;

    return(
      <div>Sample Mapping</div>
    //   <Container component="main" maxWidth={false} classes={{ root: classes.containerPadding }}>
    //   {
    //     add_sample
    //       ?
    //       <Grid item xs={12}>
    //         <AddSampleType editItem={editItem !== undefined ? editItem : []} />
    //       </Grid>
    //       :
    //       <Grid container>
    //         <Grid item xs={10}>
    //           <Typography>
    //             Accession Settings&nbsp;
    //             <ArrowForwardIos fontSize='small' className={classes.fontSizeArrowIcon} />
    //             <b>{sample_type === 0 ? 'Sample Type List' : (sample_type === 1 ? 'Edit Bulk' : '')}</b>
    //           </Typography>
    //         </Grid>
    //         <Grid item xs={2}>
    //           <Grid container justify='flex-end'>
    //             <Button
    //               variant='contained'
    //               color='primary'
    //               className={classes.buttonAccession}
    //               onClick={this.handleAddSampleType}
    //             >
    //               Add Sample Type
    //             </Button>
    //           </Grid>
    //         </Grid>
    //         <Grid item xs={6}>
    //           <Tabs
    //             value={sample_type}
    //             onChange={this.handleTabChange}
    //             indicatorColor="primary"
    //           >
    //             <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Sample Type List" {...this.handleTabEvent(1)} />
    //             <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Sample Mapping" {...this.handleTabEvent(2)} />
    //           </Tabs>
    //         </Grid>

    //         <Grid item xs={6}>
    //           <Grid container justify='flex-end'>
    //             <Button className={classes.buttonText} onClick={this.handleFilter}>
    //               <FilterList fontSize='small' className={classes.buttonShadow} />&nbsp;Filter Rows
    //             </Button>
    //             <Button className={classes.buttonText} onClick={this.handleRefresh}>
    //               <Refresh fontSize='small' className={classes.buttonShadow} />&nbsp;Refresh
    //             </Button>
    //           </Grid>
    //         </Grid>

    //         <Grid item xs={12}>
              
    //         </Grid>
    //       </Grid>
    //   }

    // </Container>
    )
  }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, {
})(SampleMapping);