import axios from 'axios';
import { data } from 'jquery';

import * as actionTypes from '../Actions/actionTypes';

const instance = axios.create({
  baseURL: 'https://limsapi-uat.intandemly.com/',
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error !== '') {
      if (error.hasOwnProperty('response')) {
        if (error.response !== undefined && error.response.status !== undefined) {
          if (error.response.status === 401) {
            alert('Unauthorised');
            appReset();
          } else if (error.response.status === 403) {
            alert('Forbidden');
            window.location.reload();
          } else if (error.response.status === 404) {
            alert('Page Not Found');
            window.location.reload();
            // window.location.reload()
            // appReset();
            // window.location.replace('/error')
          } else if (error.response.status === 500) {
            alert('Server Down');
            window.location.reload();
          } else if (error.response.status === 503) {
            alert('Server busy');
            appReset();
            window.location.replace('/error');
          }
          return Promise.reject(error.response);
        }
      } else return false;
    } else return false;
  }
);

var access_token = localStorage.getItem('lims_access_token');
instance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

export function login(username, password, loginType) {
  return async (dispatch) => {
    const response = await instance({
      method: 'post',
      url: 'login',
      data: { username, password, loginType }, //branch id is neccessary for all api calls
    }).then((res) => {
      return dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        payload: res,
      });
    });

    if (response !== undefined && typeof response === 'object') {
      if (response.hasOwnProperty('payload')) {
        if (response.payload.hasOwnProperty('data')) {
          const data = response.payload.data;
          if (data.access_token !== null && data.status) {
            localStorage.setItem('lims_access_token', data.access_token);
            if (!data.already_login) {
              localStorage.setItem('lims_already_loggedin', data.already_login);
              window.location.replace('/home');
            } else {
              localStorage.setItem('lims_already_loggedin', data.already_login);
              window.location.replace('/inter');
            }
          }
        }
      }
    }
  };
}

export function logout() {
  return async (dispatch) => {
    const response = await instance({
      method: 'post',
      url: 'logout',
    }).then((res) => {
      return dispatch({
        type: actionTypes.LOGOUT_SUCCESS,
        payload: res,
      });
    });

    if (response !== undefined && typeof response === 'object') {
      if (response.hasOwnProperty('payload')) {
        if (response.payload.hasOwnProperty('data')) {
          const data = response.payload.data;
          if (data.code === '200') {
            localStorage.removeItem('lims_access_token');
            localStorage.removeItem('lims_already_loggedin');
            localStorage.removeItem('lims_already_loggedin');
            alert(data.message);
            window.location.replace('/');
          } else window.location.replace('/error');
        }
      }
    }
  };
}

export function activityLog() {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'activitylog',
      }).then((res) => {
        return dispatch({
          type: actionTypes.ACTIVITYLOG_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function loginConfirmation() {
  if (access_token !== null) {
    return async (dispatch) => {
      const response = await instance({
        method: 'post',
        url: 'tlogin',
      }).then((res) => {
        return dispatch({
          type: actionTypes.LOGINCONFIRM_SUCCESS,
          payload: res,
        });
      });

      if (response !== undefined && typeof response === 'object') {
        if (response.hasOwnProperty('payload')) {
          if (response.payload.hasOwnProperty('data')) {
            const data = response.payload.data;
            if (data.access_token !== null) {
              localStorage.setItem('lims_access_token', data.access_token);
              if (!data.already_login) {
                localStorage.setItem('lims_already_loggedin', data.already_login);
                window.location.replace('/home');
              } else {
                localStorage.setItem('lims_already_loggedin', data.already_login);
                window.location.replace('/inter');
              }
            } else {
              window.location.replace('/');
            }
          }
        }
      }
    };
  }
}

export function appReset() {
  localStorage.removeItem('lims_access_token');
  localStorage.removeItem('lims_already_loggedin');
  window.location.replace('/');
}

export function profile() {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'profile',
      }).then((res) => {
        return dispatch({
          type: actionTypes.PROFILE_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getPatientCount() {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getpatientcount',
      }).then((res) => {
        return dispatch({
          type: actionTypes.PATIENT_COUNT_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function patientType() {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getpatienttype',
      }).then((res) => {
        return dispatch({
          type: actionTypes.PATIENT_TYPE_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getPatientMobile(mobile) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getpatientsmobile',
        data: { mobile },
      }).then((res) => {
        return dispatch({
          type: actionTypes.PATIENT_MOBILE_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getPatientName(name) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getpatientsname',
        data: { name },
      }).then((res) => {
        return dispatch({
          type: actionTypes.PATIENT_NAME_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getOrganisations(organisation) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getorganisations',
        data: { organisation },
      }).then((res) => {
        return dispatch({
          type: actionTypes.PATIENT_ORGANISATION_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getReferrals(referral) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getreferrals',
        data: { referral },
      }).then((res) => {
        return dispatch({
          type: actionTypes.PATIENT_REFERRAL_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function submitRegistration(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      const response = await instance({
        method: 'post',
        url: 'registerpatient',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.REGISTRATION_SUCCESS,
          payload: res,
        });
      });

      if (response !== undefined && typeof response === 'object') {
        if (response.hasOwnProperty('payload')) {
          if (typeof response.payload === 'object' && response.payload.hasOwnProperty('data')) {
            const data = response.payload.data;
            if (data.status) {
              const mobile = parseInt(data.data);
              if (mobile !== 'object' && mobile !== '') {
                window.location.replace('/bill/' + mobile);
              } else window.location.replace('/billing');
            }
          }
        }
      }
    };
  }
}

export function updateRegistration(data) {
  delete data.organisationType;
  delete data.referralType;
  delete data.openOrg;
  delete data.openRef;
  delete data.error;
  delete data.date_of_birth;
  delete data.openMobileSuggest;
  delete data.openNameSuggest;
  delete data.openAlternateMobileSuggest;
  delete data.country_flag;
  delete data.alt_country_flag;
  delete data.openOrganisationSuggest;
  delete data.openReferralSuggest;
  delete data.existingDataFlag;
  delete data.patient_types;
  delete data.type_of_patient;
  delete data.type_of_flag;
  delete data.type_of_alt_flag;
  const info = data;
  if (access_token !== null) {
    return async (dispatch) => {
      const response = await instance({
        method: 'post',
        url: 'updatepatient',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATION_SUCCESS,
          payload: res,
        });
      });

      if (response !== undefined && typeof response === 'object') {
        if (response.hasOwnProperty('payload')) {
          if (typeof response.payload === 'object' && response.payload.hasOwnProperty('data')) {
            const data = response.payload.data;
            if (data.status) {
              if (!info.flag) {
                alert(data.message);
                window.location.reload();
              } else window.location.replace('/bill/' + info.mobile);
            }
          }
        }
      }
    };
  }
}

export function getPatientMobileBilling(mobile) {
  if (access_token !== null) {
    return async (dispatch) => {
      const response = await instance({
        method: 'post',
        url: 'getpatientmobile',
        data: { mobile },
      }).then((res) => {
        return dispatch({
          type: actionTypes.PATIENT_MOBILE_BILLING_SUCCESS,
          payload: res,
        });
      });

      if (response !== undefined && typeof response === 'object') {
        if (response.hasOwnProperty('payload')) {
          if (typeof response.payload === 'object' && response.payload.hasOwnProperty('data')) {
            const data = response.payload.data;
            if (!data.status) {
              window.location.replace('/billing');
            }
          }
        }
      }
    };
  }
}

export function getPatientIdBilling(id) {
  if (access_token !== null) {
    return async (dispatch) => {
      const response = await instance({
        method: 'post',
        url: 'getpatientbyid',
        data: { id },
      }).then((res) => {
        return dispatch({
          type: actionTypes.PATIENT_ID_BILLING_SUCCESS,
          payload: res,
        });
      });

      if (response !== undefined && typeof response === 'object') {
        if (response.hasOwnProperty('payload')) {
          if (typeof response.payload === 'object' && response.payload.hasOwnProperty('data')) {
            const data = response.payload.data;
            if (!data.status) {
              window.location.replace('/billing');
            }
          }
        }
      }
    };
  }
}

export function getPatients(searchkeyword) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getpatients',
        data: { searchkeyword },
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_PATIENT_BILLING_SEARCH_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getTestPrices(organisationid) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'gettestprices',
        data: { organisationid },
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_TEST_PRICES,
          payload: res,
        });
      });
    };
  }
}

export function billing(data) {
  delete data.test_item;
  delete data.test_id;
  delete data.test_name;
  delete data.test_price;
  delete data.test_price_concession;
  delete data.openBillingFooter;
  delete data.billingDate;
  delete data.test_price_id;

  if (access_token !== null) {
    return async (dispatch) => {
      const response = await instance({
        method: 'post',
        url: 'createbill',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.PATIENT_BILLING_SUCCESS,
          payload: res,
        });
      });

      if (response !== undefined && typeof response === 'object') {
        if (response.hasOwnProperty('payload')) {
          if (typeof response.payload === 'object' && response.payload.hasOwnProperty('data')) {
            const data = response.payload.data;
            if (data.status) {
              window.location.replace('/registration');
            }
          }
        }
      }
    };
  }
}

export function getPendingTests(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getpendingsamples',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_PENDING_SAMPLES,
          payload: res,
        });
      });
    };
  }
}

export function getRejectedTests(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getrejectedtests',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_REJECTED_SAMPLES,
          payload: res,
        });
      });
    };
  }
}

export function getAccessionDepartmentsList() {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getdepartments',
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_DEPARTMENTS_LIST,
          payload: res,
        });
      });
    };
  }
}

export function searchDepartmentList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchdepartment',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_DEPARTMENT_LIST,
          payload: res,
        });
      });
    };
  }
}

export function createDepartment(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createdepartment',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.ADD_DEPARTMENT_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function updateDepartment(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatedepartment',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_DEPARTMENT_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function updateTestReceive(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      return await instance({
        method: 'post',
        url: 'updatetestreceive',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_TEST_RECEIVE,
          payload: res,
        });
      });
    };
  }
}

export function getBarcodesData(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'gettestbarcode',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.BAR_CODE_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getBillWiseTests(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getbillwisetests',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_BILLWISE_TESTS,
          payload: res,
        });
      });
    };
  }
}

export function updateBillWiseReceiveAll(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      return await instance({
        method: 'post',
        url: 'updatealltoreceived',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_BILLWISE_RECEIVE_ALL,
          payload: res,
        });
      });
    };
  }
}

export function getAccessedTests(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getaccessedtests',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_ACCESSED_TESTS,
          payload: res,
        });
      });
    };
  }
}

export function createTest(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createtest',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.TEST_CREATION_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getDoctorList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getdoctorlist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.DOCTOR_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function editDoctor(data) {
  delete data.showPassword;
  delete data.openUploadImageDialog;
  delete data.errors;
  delete data.openSnackBar;
  delete data.openVisitings;
  delete data.openToast; //delete data.visitingdays;
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatedoctor',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.EDIT_DOCTOR_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function searchDoctor(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchdoctor',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_DOCTOR_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function addDoctor(data) {
  delete data.showPassword;
  delete data.openUploadImageDialog;
  delete data.errors;
  delete data.openSnackBar;
  delete data.openVisitings;
  delete data.openToast;
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createdoctor',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.ADD_DOCTOR_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function addOrganization(data) {
  delete data.showPassword;
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createorganisation',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.ADD_ORGANIZATION_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function organizationList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getorganisationlist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.ORGANIZATION_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function searchOrganization(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchorganisation',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_ORGANIZATION_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function editOrganization(data) {
  delete data.showPassword;
  delete data.errors;
  delete data.openSnackBar;
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updateorganisation',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.EDIT_ORGANIZATION_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function searchList(data) {
  //List Management
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchlist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function createList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createlist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.CREATE_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getTestList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'gettestlist',
        params: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_TEST_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function searchTest(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchtest',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_TEST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function updateListPrices(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatelistprices',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_LIST_PRICES_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function updateList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatelist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function deleteList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'delete',
        url: 'deletelist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.DELETE_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getlist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getListPrices(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getlistprices',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_LIST_PRICES_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function addReferral(data) {
  delete data.showPassword;
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createreferral',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.ADD_REFERRAL_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function referralList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getreferrallist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.REFERRAL_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function searchReferral(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchreferral',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_REFERRAL_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function editReferral(data) {
  delete data.showPassword;
  delete data.errors;
  delete data.openSnackBar;
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatereferral',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.EDIT_REFERRAL_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function testList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'gettestlist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.TEST_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getCategoryList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getcategorylist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_CATEGORY_LIST,
          payload: res,
        });
      });
    };
  }
}

export function getTestSampleTypesList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'gettestsampletypeslist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_TEST_SAMPLE_TYPES_LIST,
          payload: res,
        });
      });
    };
  }
}

export function getReportTypeList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getreporttypelist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_REPORT_TYPE_LIST,
          payload: res,
        });
      });
    };
  }
}

export function updateTest(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatetest',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_TEST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getBillSetting(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getbillsetting',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_BILL_SETTINGS,
          payload: res,
        });
      });
    };
  }
}

export function updateBillSetting(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatebillsetting',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_BILL_SETTINGS,
          payload: res,
        });
      });
    };
  }
}

export function updateProfile(data) {
  delete data.updatePassword;
  // if(data.profile_picture === null) delete data.profile_picture;
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updateprofile',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_PROFILE_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function createtestparameter(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createtestparameter',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.CREATE_TEST_PARAM_SUCCESS,
          payload: res,
        });
      });
    };
  }
}
export function updatePassword(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatepassword',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_PASSWORD_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function updateTestResult(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatetestresult',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_TEST_RESULT,
          payload: res,
        });
      });
    };
  }
}

export function getOperationTests(data) {
  // Operation Management
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getoperationtests',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_OPERATION_TESTS_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getTestResults(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'gettestresults',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_TEST_RESULTS,
          payload: res,
        });
      });
    };
  }
}

export function getOperationSettings(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getoperationsetting',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_OPERATION_SETTINGS,
          payload: res,
        });
      });
    };
  }
}

export function updateOperationSettings(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updateoperationsetting',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_OPERATION_SETTINGS,
          payload: res,
        });
      });
    };
  }
}

export function createCenter(data) {
  //Center Management
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createbranch',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.CREATE_BRANCH_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getCenterList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getbranchlist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_BRANCH_LIST,
          payload: res,
        });
      });
    };
  }
}

export function updateCenter(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatebranch',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_BRANCH_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function searchCenter(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchbranch',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_BRANCH_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function createTestProfile(data) {
  /* Profile Management */
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createtestprofile',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.ADD_TEST_PROFILE_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getTestProfileList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'gettestprofilelist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_TEST_PROFILE_LIST,
          payload: res,
        });
      });
    };
  }
}

export function searchTestProfile(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchtestprofile',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_TEST_PROFILE_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function updateTestProfile(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatetestprofile',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_TEST_PROFILE_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getBillList(data) {
  /* Bill Settlement */
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getbilllist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_BILL_LIST_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getBill(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getbill',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_BILL_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function searchBillId(data) {
  delete data.errorSampleName;
  delete data.errorSampleType;
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchbill',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_BILL_ID_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function addTestToBill(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'addtesttobill',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.ADD_TEST_TO_BILL,
          payload: res,
        });
      });
    };
  }
}

export function searchBillByBillId(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchbillbybillid',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_BILL_BY_BILL_ID,
          payload: res,
        });
      });
    };
  }
}

export function getBillByPatientId(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getbillbypatientid',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_BILL_BY_PATIENT_ID,
          payload: res,
        });
      });
    };
  }
}

export function searchBillByPatientName(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchbillbypatient',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_BILL_BY_PATIENT_NAME,
          payload: res,
        });
      });
    };
  }
}

export function searchBillByAccession(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchbillbyaccession',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_BILL_BY_ACCESSION,
          payload: res,
        });
      });
    };
  }
}

export function updateBillPaymentCompleted(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatebillpaymentcompleted',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_BILL_PAYMENT_COMPLETED,
          payload: res,
        });
      });
    };
  }
}

export function updateBill(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatebill',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_BILL_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function cancelBill(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'cancelbill',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.CANCEL_BILL_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function updateBillsPayment(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatebillspayment',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_BILLS_PAYMENT,
          payload: res,
        });
      });
    };
  }
}

export function getTestSampleTypeList(data) {
  /* Sample Management */
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'gettestsampletypelist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_TEST_SAMPLE_TYPE_LIST,
          payload: res,
        });
      });
    };
  }
}

export function createTestSample(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createtestsampletype',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.CREATE_TEST_SAMPLE_TYPE,
          payload: res,
        });
      });
    };
  }
}

export function updateTestSample(data) {
  delete data.errorSampleName;
  delete data.errorSampleType;
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatetestsampletype',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_TEST_SAMPLE_TYPE,
          payload: res,
        });
      });
    };
  }
}

export function getSampleTypeList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getsampletypelist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_SAMPLE_TYPE_LIST,
          payload: res,
        });
      });
    };
  }
}

export function deleteTestSample(data) {
  delete data.errorSampleName;
  delete data.errorSampleType;
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'delete',
        url: 'deletetestsampletype',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.DELETE_TEST_SAMPLE_TYPE,
          payload: res,
        });
      });
    };
  }
}

export function getSampleContainerList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getsamplecontainerlist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_SAMPLE_CONTAINER_LIST,
          payload: res,
        });
      });
    };
  }
}

export function getOutsourceList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getoutsourcelist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_OUTSOURCE_LIST,
          payload: res,
        });
      });
    };
  }
}

export function getOutsourceCenterList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getoutsourcecenter',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_OUTSOURCE_CENTER_LIST,
          payload: res,
        });
      });
    };
  }
}

export function createBatch(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createbatch',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.CREATE_BATCH_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function validateBatchTests(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'validatebatchtests',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.VALIDATE_BATCH_TESTS,
          payload: res,
        });
      });
    };
  }
}

export function getBatchList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getbatchlist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_BATCH_LIST,
          payload: res,
        });
      });
    };
  }
}

export function updateSampleToReject(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatesampletoreject',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_SAMPLE_TO_REJECT,
          payload: res,
        });
      });
    };
  }
}

export function updateSampleToReceive(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updatesampletoreceive',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_SAMPLE_TO_RECEIVE,
          payload: res,
        });
      });
    };
  }
}

export function getBatchLogistic(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'getbatchlogistic',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_BATCH_LOGISTICS,
          payload: res,
        });
      });
    };
  }
}

export function getPaymentModes(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getpaymentmodes',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_PAYMENT_MODES,
          payload: res,
        });
      });
    };
  }
}

export function getPermissionsList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getpermissions',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_PERMISSIONS_LIST,
          payload: res,
        });
      });
    };
  }
}

export function createUser(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'createuser',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.CREATE_USER_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function updateUser(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'updateuser',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.UPDATE_USER_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function searchUser(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'post',
        url: 'searchuser',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.SEARCH_USER_SUCCESS,
          payload: res,
        });
      });
    };
  }
}

export function getUserList(data) {
  if (access_token !== null) {
    return async (dispatch) => {
      await instance({
        method: 'get',
        url: 'getuserlist',
        data: data,
      }).then((res) => {
        return dispatch({
          type: actionTypes.GET_USER_LIST,
          payload: res,
        });
      });
    };
  }
}
