import React from 'react';
import clsx from 'clsx';

import {
  Container,
  Grid,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FileUpload from '../../Common/FileUpload';

import classes from '../../App.module.css';

class ReportSettings extends React.Component {
  render() {
    return (
      <Container component='main' maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container direction='row' justify='flex-start' spacing={1} className={classes.drawerMainList}>
          <Grid item xs={12}>
            <h3>Default Report Settings</h3>
          </Grid>
          <Grid item xs={12}>
            Default settings related to print format of report.
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center'>
              <Grid item xs={12}>
                <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                  <Grid item xs={2}>
                    <Grid container direction='row' justify='flex-end'>
                      Paper Size:
                  </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Font Size:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Font Type:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Primary Sign Position:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Sign Size:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Header Size:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Footer Size:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' justify='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container item justify='center'>
                      <FormControlLabel
                        value='end'
                        control={<Checkbox color='primary' />}
                        label='Show PDF Header'
                        labelPlacement='end'
                        classes={{
                          label: classes.label
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container item justify='center'>
                      <FormControlLabel
                        value='end'
                        control={<Checkbox color='primary' />}
                        label='Show PDF Footer'
                        labelPlacement='end'
                        classes={{
                          label: classes.label
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Paper Margin:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Vertical Spacing:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder='**END OF REPORT**'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Date Format:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Min Approval:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Max Approval:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <ExpansionPanel square>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={{ backgroundColor: '#eff0f1' }}
                  >
                    <Grid container justify='center'>
                      Upload Watermark
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container direction='row' alignItems='center' spacing={2}>
                      <Grid item xs={5}>
                        <Grid container justify='flex-end'>
                          Watermark image:
                        </Grid>
                      </Grid>
                      <Grid item xs={7}>
                        <FileUpload/>
                      </Grid>

                      <Grid item xs={3}>
                        <Grid container justify='flex-end'>
                          Width:
                      </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          margin='dense'
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Grid container justify='flex-end'>
                          Height:
                      </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          margin='dense'
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container justify='center'>
                          <FormControlLabel
                            value='end'
                            control={<Checkbox color='primary' />}
                            label='Show Watermark Image on PDF'
                            labelPlacement='end'
                            classes={{ label: classes.payableAmount }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Patient Frame:
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container justify='center'>
              All the below checked fields will be shown on the PDF w.r.t the report.
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container item justify='flex-start'>
              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Patient Name'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Patient Age'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Registration No.'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Referring Doctor'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Report ID'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Report Date'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Sample Date'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Sample ID'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Floating Signature'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Registered by'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Reported by'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='End of Report'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Hide Report Name'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Show horizontal line'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Show Organisation'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Print Date'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Lab Code'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='HPE No.'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox color='primary' />}
                  label='Calculated Age'
                  labelPlacement='end'
                  classes={{
                    label: classes.label
                  }}
                />
              </Grid>

            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

          </Grid>

          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  size="medium"
                  variant="contained"
                  className={clsx(classes.buttonText, classes.submitButton)}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    );
  }
}

export default ReportSettings;