import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';

import {
  Grid,
  Paper,
  Select,
  MenuItem,
  TextField,
  Container,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  RadioGroup,
  Radio,
  Snackbar,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import designationData from '../../Data/designation.json';
import flags from '../../Data/flags.json';
import { addReferral, editReferral } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class ReferralInfo extends React.Component {

  static propTypes = {
    handleCloseDialog: PropTypes.func.isRequired,
    referralTab: PropTypes.number.isRequired,
    handleReferralShiftTab: PropTypes.func.isRequired,
    handleConfigurationShiftTab: PropTypes.func.isRequired,
    handleConfigurationPrevious: PropTypes.func.isRequired,
    handleLoginPrevious: PropTypes.func.isRequired,

    // editList: PropTypes.object,
    // organizationList: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      designation: this.props.editList.designation !== undefined && this.props.editList.designation !== null ? this.props.editList.designation : 'Mr.',
      referrer_name: this.props.editList.referrer_name !== undefined && this.props.editList.referrer_name !== null ? this.props.editList.referrer_name : '',
      display_name: this.props.editList.display_name !== undefined && this.props.editList.display_name !== null ? this.props.editList.display_name : '',
      contact_country_code: this.props.editList.contact_country_code !== undefined && this.props.editList.contact_country_code !== null ? this.props.editList.contact_country_code : '+91',
      contact_number: this.props.editList.contact_number !== undefined && this.props.editList.contact_number !== null ? this.props.editList.contact_number : '',
      email_address: this.props.editList.email_address !== undefined && this.props.editList.email_address !== null ? this.props.editList.email_address : '',
      address: this.props.editList.address !== undefined && this.props.editList.address !== null ? this.props.editList.address : '',
      date_of_birth: this.props.editList.date_of_birth !== undefined && this.props.editList.date_of_birth !== null ? this.props.editList.date_of_birth : null,
      date_of_anniversary: this.props.editList.date_of_anniversary !== undefined && this.props.editList.date_of_anniversary !== null ? this.props.editList.date_of_anniversary : null,
      city: this.props.editList.city !== undefined && this.props.editList.city !== null ? this.props.editList.city : '',
      pincode: this.props.editList.pincode !== undefined && this.props.editList.pincode !== null ? this.props.editList.pincode : '',
      comments: this.props.editList.comments !== undefined && this.props.editList.comments !== null ? this.props.editList.comments : '',

      registration_number: this.props.editList.registration_number !== undefined && this.props.editList.registration_number !== null ? this.props.editList.registration_number : '',
      revenue_target: this.props.editList.revenue_target !== undefined && this.props.editList.revenue_target !== null ? this.props.editList.revenue_target : '',
      referral_speciality: this.props.editList.referral_speciality !== undefined && this.props.editList.referral_speciality !== null ? this.props.editList.referral_speciality : null,
      referral_source: this.props.editList.referral_source !== undefined && this.props.editList.referral_source !== null ? this.props.editList.referral_source : 10,
      organisation_id: this.props.editList.organisation_id !== undefined && this.props.editList.organisation_id !== null ? this.props.editList.organisation_id : '',
      is_send_sms: this.props.editList.is_send_sms !== undefined && this.props.editList.is_send_sms !== null ? this.props.editList.is_send_sms : '0',
      is_send_email: this.props.editList.is_send_email !== undefined && this.props.editList.is_send_email !== null ? this.props.editList.is_send_email : '0',

      username: this.props.editList.username !== undefined && this.props.editList.username !== null ? this.props.editList.username : '',
      password: this.props.editList.password !== undefined && this.props.editList.password !== null ? this.props.editList.password : '',
      is_finance_manangement_enable: this.props.editList.is_finance_manangement_enable !== undefined && this.props.editList.is_finance_manangement_enable !== null ? this.props.editList.is_finance_manangement_enable : '0',
      report_access: this.props.editList.report_access !== undefined && this.props.editList.report_access !== null ? this.props.editList.report_access : '0',
      
      referralref: this.props.editList.id !== undefined && this.props.editList.id !== null ? this.props.editList.id : null,
      user_id: this.props.editList.user_id !== undefined && this.props.editList.user_id !== null ? this.props.editList.user_id : null,

      showPassword: false,
      errors: false,
      openSnackBar: true,
    }
    this.reset = this.state;
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  }

  handleDateChange = (date) => {
    this.setState({ date_of_birth: date })
  }

  handleAnniversaryDateChange = (date) => {
    this.setState({ date_of_anniversary: date })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleRadioButton = (e) => {
    if (e.target !== undefined && e.target.value !== undefined) {
      this.setState({ [e.target.name]: e.target.value })
    } else this.setState({ [e.target.value]: '' })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnackBar: false })
  }

  handleReferralInfo = () => {
    const { referrer_name, display_name, designation, contact_country_code, contact_number, email_address, address, date_of_birth,
      date_of_anniversary, city, pincode, comments, errors,
    } = this.state;
    const { handleCloseDialog, handleReferralShiftTab, addReferralSuccess, referralNameError, editList } = this.props;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.paperHeight }}>
            <Container maxWidth={false} classes={{root: classes.containerPadding}}>
              <Grid container direction='row' alignItems='center' spacing={1} className={classes.textAlign}>
                <Grid item xs={12}>
                  Referral Name *
                  <Grid container direction='row'>
                    <Grid item xs={2}>
                      <Select
                        fullWidth
                        placeholder='Designation'
                        variant='outlined'
                        name='designation'
                        value={designation}
                        onChange={this.handleSelectChange}
                      >
                        {designationData.map(item => <MenuItem value={item.title} key={item.id}> {item.title} </MenuItem>)}
                      </Select>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        name='referrer_name'
                        value={referrer_name}
                        onChange={this.handleChange}
                        fullWidth
                        variant='outlined'
                        placeholder='Referral Name'
                      />
                      
                      {errors && referrer_name === '' ? <div className={classes.error}>This field should not be empty</div> : ''}
                      {addReferralSuccess === false ? <div className={classes.error}>{referralNameError}</div> : ''}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  Display Name
                  <TextField
                    name='display_name'
                    value={display_name}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Display Name'
                  />
                </Grid>

                <Grid item xs={12}>
                  Contact Number
                  <Grid container direction='row'>
                    <Grid item xs={2}>
                      <Select
                        fullWidth
                        variant='outlined'
                        name='contact_country_code'
                        value={contact_country_code}
                        onChange={this.handleSelectChange}
                      >
                        {flags.map(flag =>
                          <MenuItem value={flag.dial_code} key={flag.code}>
                            {flag.flag} {flag.name} {flag.dial_code}
                          </MenuItem>
                        )}
                      </Select>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        name='contact_number'
                        value={contact_number}
                        onChange={this.handleChange}
                        fullWidth
                        variant='outlined'
                        placeholder='Contact Number'
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  Email ID
                  <TextField
                    name='email_address'
                    value={email_address}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Email ID'
                  />
                </Grid>

                <Grid item xs={12}>
                  Address
                  <TextField
                    name='address'
                    value={address}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Address'
                  />
                </Grid>

                <Grid item xs={6}>
                  Date of Birth
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin='normal'
                      id='date-picker-dialog'
                      inputVariant='outlined'
                      placeholder='MM/DD/YYYY'
                      format='MM/dd/yyyy'
                      name='date_of_birth'
                      value={date_of_birth}
                      onChange={this.handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      className={classes.datePicker}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={6}>
                  Date of Anniversary
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth
                      margin='normal'
                      id='date-picker-dialog'
                      inputVariant='outlined'
                      placeholder='MM/DD/YYYY'
                      format='MM/dd/yyyy'
                      name='date_of_anniversary'
                      value={date_of_anniversary}
                      onChange={this.handleAnniversaryDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      className={classes.datePicker}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={6}>
                  City
                  <TextField
                    name='city'
                    value={city}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    placeholder='City'
                  />
                </Grid>

                <Grid item xs={6}>
                  Pin Code
                  <TextField
                    name='pincode'
                    value={pincode}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Pin Code'
                  />
                </Grid>

                <Grid item xs={12}>
                  Comments
                  <TextField
                    name='comments'
                    value={comments}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Comments'
                  />
                </Grid>

              </Grid>
            </Container>
          </Paper>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={11}>
          <Grid container justify='flex-end'>
            {
              editList.length !== 0 ?
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddReferral}
                >
                  Update Referral
              </Button> :
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddReferral}
                >
                  Add Referral
              </Button>
            }
            &nbsp;&nbsp;
            <Button
              variant='contained'
              color='primary'
              className={classes.buttonAccession}
              onClick={handleReferralShiftTab}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  handleConfiguration = () => {
    const { referral_speciality, referral_source, organisation_id, registration_number, revenue_target, is_send_email, is_send_sms } = this.state;
    const { handleCloseDialog, handleConfigurationShiftTab, handleConfigurationPrevious, addReferralSuccess, referralSpecialityError, editList, organizationList } = this.props;
    
    let speciality = [
      { id: 1, speciality_name: 'Speciality 1'},
      { id: 2, speciality_name: 'Speciality 2'},
      { id: 3, speciality_name: 'Speciality 3'},
      { id: 4, speciality_name: 'Speciality 4'}
    ]
    
    return (
      <Grid container direction='row' spacing={1}>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.paperHeight }}>
            <Container maxWidth={false} classes={{root: classes.containerPadding}}>
              <Grid container direction='row' spacing={1} alignItems='flex-start'>
                <Grid item xs={12}>
                  Registration Number
                  <TextField
                    name='registration_number'
                    value={registration_number}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Registration Number'
                  />
                </Grid>

                <Grid item xs={12}>
                  Revenue Target
                  <TextField
                    name='revenue_target'
                    value={revenue_target}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Revenue Target'
                  />
                </Grid>

                <Grid item xs={12}>
                  Speciality
                  <Select
                    fullWidth
                    variant='outlined'
                    name='referral_speciality'
                    value={referral_speciality}
                    onChange={this.handleSelectChange}
                  >
                    {speciality.map((item,index) => <MenuItem key={index} value={item.id}>{item.speciality_name}</MenuItem>)}
                  </Select>
                  {addReferralSuccess === false ? <div className={classes.error}>{referralSpecialityError}</div> : ''}
                </Grid>

                <Grid item xs={12}>
                  Source
                  <Select
                    fullWidth
                    variant='outlined'
                    name='referral_source'
                    value={referral_source}
                    onChange={this.handleSelectChange}
                  >
                    <MenuItem value={1}>Select...</MenuItem>
                    <MenuItem value={2}>Source1</MenuItem>
                    <MenuItem value={3}>Source2</MenuItem>
                    <MenuItem value={4}>Source3</MenuItem>
                  </Select>
                </Grid>

                <Grid item xs={12}>
                  Organization
                  <Select
                    fullWidth
                    variant='outlined'
                    name='organisation_id'
                    value={organisation_id}
                    onChange={this.handleSelectChange}
                  >
                    { organizationList !== null && organizationList !== undefined ? organizationList.map(item => <MenuItem key={item.id} value={item.id}>{item.organisation_name}</MenuItem>): ''}
                  </Select>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <FormControlLabel
                      name='is_send_sms'
                      value={is_send_sms}
                      checked={true ? is_send_sms === '1' : false}
                      control={<Checkbox color='primary' />}
                      label='Send SMS'
                      labelPlacement='end'
                      onClick={this.handleCheckBox}
                    />
                    <FormControlLabel
                      name='is_send_email'
                      value={is_send_email}
                      checked={true ? is_send_email === '1' : false}
                      control={<Checkbox color='primary' />}
                      label='Send Email'
                      labelPlacement='end'
                      onClick={this.handleCheckBox}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container justify='center'>
                    <Typography>
                      * Please note that each sms will be charged if you tick on send SMS option.
                    </Typography>
                  </Grid>
                </Grid>

              </Grid>
            </Container>
          </Paper>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleConfigurationPrevious}
          >
            Previous
          </Button>
        </Grid>

        <Grid item xs={10}>
          <Grid container justify='flex-end'>
            {
              editList.length !== 0 ?
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddReferral}
                >
                  Update Referral
              </Button> :
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddReferral}
                >
                  Add Referral
              </Button>
            }
            &nbsp;&nbsp;
            <Button
              variant='contained'
              color='primary'
              className={classes.buttonAccession}
              onClick={handleConfigurationShiftTab}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  handleLoginAndAccess = () => {
    const { password, showPassword, report_access, username, is_finance_manangement_enable } = this.state;
    const { handleCloseDialog, handleLoginPrevious, addReferralSuccess, referralFinanceError, usernameError, editList, editReferralSuccess,
      userNameUpdateError,
    } = this.props;
    return (
      <Grid container direction='row' spacing={1}>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.paperHeight }}>
            <Container maxWidth={false} classes={{root: classes.containerPadding}}>
              <Grid container direction='row' alignItems='center' spacing={1} className={classes.textAlign}>
                <Grid item xs={12}>
                  Your current active logins are 0, and your maximum login limit is 25
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction='row' justify='flex-end'>
                    <Grid item>
                      Referral Username
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={2}>
                      <TextField
                        disabled
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        className={clsx(classes.search, classes.expansionPanelColor)}
                        placeholder='lucidatta'
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        name='username'
                        value={username}
                        onChange={this.handleChange}
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        className={classes.search}
                        placeholder='Username'
                      />
                      {addReferralSuccess === false ? <div className={classes.error}>{usernameError}</div> : ''}
                      {editReferralSuccess === false && userNameUpdateError !==[] ? <div className={classes.error}>{userNameUpdateError}</div> : ''}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container direction='row'>
                  <Grid item>
                    Referral Password*
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      value={password}
                      margin='dense'
                      className={classes.search}
                      onChange={this.handleChange}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={this.handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  * Password should contain at least one  small letter, one capital letter,
                  one digit,one special character & greater than 8 characters length
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_finance_manangement_enable'
                    value={is_finance_manangement_enable}
                    checked={true ? is_finance_manangement_enable === '1' : false}
                    control={<Checkbox color='primary' />}
                    label='Finance Management'
                    labelPlacement='end'
                    onClick={this.handleCheckBox}
                  />
                  {addReferralSuccess === false ? <div className={classes.error}>{referralFinanceError}</div> : ''}
                </Grid>

                <Grid item xs={12}>
                  Report Access :
                  <RadioGroup
                    aria-label='report_access'
                    name='report_access'
                    value={report_access}
                    onChange={this.handleRadioButton}
                  >
                    <Grid container direction='row'>
                      <Grid item xs={4}>
                        <FormControlLabel
                          value='0'
                          checked={report_access === '0'}
                          control={<Radio color='primary' />}
                          label='Show All Registered Report.'
                          labelPlacement='end'
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormControlLabel
                          value='1'
                          checked={report_access === '1'}
                          control={<Radio color='primary' />}
                          label='Signed Report.'
                          labelPlacement='end'
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <FormControlLabel
                          value='2'
                          checked={report_access === '2'}
                          control={<Radio color='primary' />}
                          label='Submitted Reports.'
                          labelPlacement='end'
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>

              </Grid>
            </Container>
          </Paper>
        </Grid>


        <Grid item xs={1}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleLoginPrevious}
          >
            Previous
          </Button>
        </Grid>

        <Grid item xs={10}>
          <Grid container justify='flex-end'>
            {
              editList.length !== 0 ?
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddReferral}
                >
                  Update Referral
              </Button> :
                <Button
                  type='submit'
                  variant='outlined'
                  color='primary'
                  className={classes.buttonAccession}
                  onSubmit={this.handleAddReferral}
                >
                  Add Referral
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }

  handleAddReferral = async (e) => {
    const { referrer_name } = this.state;
    const { editList, handleCloseDialog } = this.props;
    e.preventDefault();
    this.setState({ errors: false, openSnackBar: true })
    if (referrer_name === '') {
      this.setState({ errors: true })
    } else if (editList.length !== 0) {
      await this.props.editReferral(this.state);
      if (this.props.editReferralSuccess) {
        this.props.listUpdate();
        handleCloseDialog();
      }
    } else {
      await this.props.addReferral(this.state);
      if (this.props.addReferralSuccess) {
        this.props.listUpdate();
        this.setState(this.reset)
        handleCloseDialog();
      }
    }
  }

  render() {
    const { errors, referrer_name, openSnackBar } = this.state;
    const { referralTab } = this.props;
    return (
      <div>
        <form onSubmit={this.handleAddReferral}>
          {
            referralTab === 0 ? this.handleReferralInfo() :
              (referralTab === 1 ? this.handleConfiguration() :
                (referralTab === 2 ? this.handleLoginAndAccess() : ''))
          }
        </form>
        <div>
          {errors && referrer_name === '' ?
            <Snackbar
              open={openSnackBar}
              autoHideDuration={6000}
              onClose={this.handleCloseSnackBar}
              message='Please fill all mandatory fields'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              action={
                <React.Fragment>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleCloseSnackBar}
                  >
                    <CloseIcon />
                  </IconButton>
                </React.Fragment>
              }
            /> : ''
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  addReferralSuccess: state.login.addReferralSuccess,
  referralNameError: state.login.errorMessagesReferral.referralNameError,
  editReferralSuccess: state.login.editReferralSuccess,
  referralSpecialityError: state.login.errorMessagesReferral.referralSpecialityError,
  referralFinanceError: state.login.errorMessagesReferral.referralFinanceError,
  usernameError: state.login.errorMessagesReferral.usernameError,

  userNameUpdateError: state.login.errorMessagesUpdateReferral.userNameError,
})

export default connect(mapStateToProps, {
  addReferral,
  editReferral,
})(ReferralInfo);