import React from 'react';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';

import {
  Container,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
} from '@material-ui/core';

import classes from '../../../App.module.css';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { createtestparameter } from '../../../Store/ActionCreators';

class AgeSpecificRange extends React.Component {
  constructor (props) {
    super(props); 
    this.state = {
      parameter: '',
      parameter_unit: '',
      parameterCode: '',
      method: '',
      integrationCode: '',
      positionPdf: 1,
      male_min_range: '',
      male_max_range: '',
      female_min_range: '',
      female_max_range: '',
      descriptive_female_range: '',
      male_critical_min_range: '',
      descriptive_male_range: '',
      male_critical_max_range: '',
      female_critical_min_range: '',
      female_critical_max_range: '',
      added_age_specific_ranges: 1,
      age_range_details: 'age_range_in_months',
      lower_age: 0,
      upper_age: 0,
      dictionary: '',
      is_sms_doctor: '0',
      is_email_doctor: '0',
      is_highlight_value: '0',
      is_underline_value: '0',
      is_non_editable_field: '0',
      is_optional_field: '0',
      is_break_line: '0',
      is_impressions: '0',
      report_name: 'Test_With_Age_Specific_Range',
      report_type: this.props.reportType,
      createAnother: false,
      test_id: this.props.testRef
    }
    this.reset = this.state;
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  copyRanges = (e) => {
    if(e.target.checked){
      this.setState({
        male_critical_min_range : this.state.male_min_range , 
        male_critical_max_range:  this.state.male_max_range, 
        female_critical_min_range: this.state.female_min_range, 
        female_critical_max_range: this.state.female_max_range
      })
    }
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleSaveChanges = async(e) => {
    var obj = {
      test_id: this.state.test_id,
      parameter_info: {}
    }
    Object.keys(this.state).forEach(item => {
      if(item !== 'test_id' && item !== 'createAnother'){
        obj.parameter_info[item] = this.state[item];
      }
    })
    await this.props.createtestparameter(obj);
    if(this.props.createTestParamSuccess){
      if(this.state.createAnother){
        this.setState(this.reset);
      } else {
        this.props.history.push({pathname: '/reportlist'})
      }
    }
  }

  render() {
    const { parameter, parameter_unit, method, integrationCode, added_age_specific_ranges, age_range_details, lower_age, upper_age, parameterCode, positionPdf, male_min_range, male_max_range, descriptive_male_range,
      female_min_range, female_max_range, descriptive_female_range, male_critical_min_range, male_critical_max_range, female_critical_min_range, female_critical_max_range
      , dictionary, is_sms_doctor, is_email_doctor, is_highlight_value, is_underline_value, is_non_editable_field, is_optional_field, is_break_line, is_impressions, createAnother
    } = this.state;
    return (
      <ValidatorForm onSubmit={this.handleSaveChanges}>
        <Container>
          <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                  <Typography variant="h4">Test with Age-Specific Range</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Name:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='parameter'
                    value={parameter}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter the value name"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Unit:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='parameter_unit'
                    value={parameter_unit}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter the Primary value unit"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Code:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='parameterCode'
                    value={parameterCode}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter the Primary Code"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Method:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='method'
                    value={method}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter the method by which test is conducted"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Integration Code:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='integrationCode'
                    value={integrationCode}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Integration Code"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Position in PDF:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='positionPdf'
                    value={positionPdf}
                    onChange={this.handleChange}
                    fullWidth
                    placeholder='Position in PDF'
                    variant='outlined'
                    margin='dense'
                  >
                    <MenuItem value={1} key={1}> {1} </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider/>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Select Age Range here:</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    select
                    name='added_age_specific_ranges'
                    value={added_age_specific_ranges}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Added age-specific ranges come here'
                  >
                    <MenuItem value={1}>Added age-specific ranges come here</MenuItem>
                  </TextField>
              </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                  <TextField
                    select
                    name='age_range_details'
                    value={age_range_details}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Age Range'
                  >
                    <MenuItem value={'age_range_in_months'}>Age Range(in Months)</MenuItem>
                    <MenuItem value={'age_range_in_days'}>Age Range(in Days)</MenuItem>
                    <MenuItem value={'age_range_in_years'}>Age Range(in Years)</MenuItem>
                  </TextField>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='lower_age'
                    value={lower_age}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter lower age"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Grid container justify='center'>
                    <Typography>-</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='upper_age'
                    value={upper_age}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter upper age"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>* Note :</Grid>
                </Grid>
                <Grid item xs={9}>1 year = 365 days, 1 month = 30 days</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider/>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Male Range:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='male_min_range'
                    value={male_min_range}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter lower range (Male)"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Grid container justify='center'>
                    <Typography>-</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='male_max_range'
                    value={male_max_range}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter upper range (Male)"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item xs={3}>
                <Grid container justify='flex-end'>
                  <Typography>Descriptive Male Range:</Typography>
              	</Grid>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  multiline
                  name='descriptive_male_range'
                  value={descriptive_male_range}
                  onChange={this.handleChange}
                  fullWidth
                  variant='outlined'
                  margin='dense'
                  placeholder="Enter descriptive male range"
                />
              </Grid>
            </Grid>
          </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Female Range:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='female_min_range'
                    value={female_min_range}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter lower range (Female)"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Grid container justify='center'>
                    <Typography>-</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='female_max_range'
                    value={female_max_range}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter upper range (Female)"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item xs={3}>
                <Grid container justify='flex-end'>
                  <Typography>Descriptive Female Range:</Typography>
              	</Grid>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  multiline
                  name='descriptive_female_range'
                  value={descriptive_female_range}
                  onChange={this.handleChange}
                  fullWidth
                  variant='outlined'
                  margin='dense'
                  placeholder="Enter descriptive female range"
                />
              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={12}>
            <ExpansionPanel square>
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                style={{ backgroundColor: '#eff0f1' }}
              >
                <Grid container justify='center'>
                  Critical Ranges
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={12}>
                  <Grid container direction='row' justify="space-between" alignItems='center' spacing={2}>
                    <Grid item>
                      <Grid container justify='flex-end'>
                        <h4>Critical Ranges:</h4>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                    <FormControlLabel
                      value='end'
                      control={<Checkbox color='primary' onChange={e => {
                        this.copyRanges(e)
                      }}/>}
                      label='Same as Normal Ranges'
                      labelPlacement='end'
                    />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Male Range:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='male_min_range'
                    value={male_critical_min_range}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter lower range (Male)"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Grid container justify='center'>
                    <Typography>-</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='male_max_range'
                    value={male_critical_max_range}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter upper range (Male)"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Female Range:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='female_min_range'
                    value={female_critical_min_range}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter lower range (Female)"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Grid container justify='center'>
                    <Typography>-</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name='female_max_range'
                    value={female_critical_max_range}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter upper range (Female)"
                  />
                </Grid>
              </Grid>
            </Grid>
              </Grid>
              
              
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>


            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Enter Dictionary:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='dictionary'
                    value={dictionary}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Enter Dictionary Name'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider/>
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_sms_doctor'
                value={is_sms_doctor}
                checked={true ?  is_sms_doctor === '1' : false}
                control={<Checkbox color='primary' />}
                label='SMS to doctor'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_email_doctor'
                value={is_email_doctor}
                checked={true ? is_email_doctor === '1' : false}
                control={<Checkbox color='primary' />}
                label='Email to doctor'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_highlight_value'
                value={is_highlight_value}
                checked={true ? is_highlight_value === '1' : false}
                control={<Checkbox color='primary' />}
                label='Highlight this value'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_underline_value'
                value={is_underline_value}
                checked={true ? is_underline_value === '1' : false}
                control={<Checkbox color='primary' />}
                label='Underline this value'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_non_editable_field'
                value={is_non_editable_field}
                checked={true ? is_non_editable_field === '1' : false}
                control={<Checkbox color='primary' />}
                label='Non-editable field'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_optional_field'
                value={is_optional_field}
                checked={true ? is_optional_field === '1' : false}
                control={<Checkbox color='primary' />}
                label='Optional field'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_break_line'
                value={is_break_line}
                checked={true ? is_break_line === '1' : false}
                control={<Checkbox color='primary' />}
                label='Break line'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_impressions'
                value={is_impressions}
                checked={true ? is_impressions === '1' : false}
                control={<Checkbox color='primary' />}
                label='Has Impressions'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' justify="flex-end" alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <FormControlLabel
                    name='createAnother'
                    value={createAnother}
                    checked={createAnother ? true : false}
                    control={<Checkbox color='primary' />}
                    label='Create Another'
                    labelPlacement='end'
                    onClick={this.handleCheckBox}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    className={classes.buttonText}
                    onSubmit={this.handleSaveChanges}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Container>
      </ValidatorForm>
    )
  }
}

const mapStateToProps = state => ({
  createTestParamSuccess: state.login.createTestParamSuccess
})

export default connect(mapStateToProps, {
  createtestparameter
})(AgeSpecificRange);