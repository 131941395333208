import React from 'react';

import {
  Grid
} from '@material-ui/core';

import classes from '../../../../App.module.css';

class SampleRenderer extends React.Component {
  render() {
    return (
      <Grid container alignItems='center'>
        <span style={{ backgroundColor: '#' + this.props.node.data.container_color }} className={classes.testSampleCircle}>
        </span>&nbsp;
        {this.props.node.data.sample_name}
      </Grid>
    );
  }
}

export default SampleRenderer;