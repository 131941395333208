import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import {
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  Link,
} from '@material-ui/core';

import { ArrowForwardIos, Refresh, FilterList } from '@material-ui/icons';

import SampleMapping from '../SampleMapping';
import AddSampleType from '../AddSampleType';

import { getTestSampleTypeList } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

class SampleManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sample_type: 0,
      add_sample: false,
      sample_type_list: false,
      sample_mapping: false,
      rows: [],
    }
    this.handleSampleList();
  }

  handleSampleList = async () => {
    await this.props.getTestSampleTypeList();
    this.setState({ rows: this.props.testSampleTypeList })
  }

  handleTabEvent = index => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({ sample_type: newValue })
  }

  handleAddSampleType = () => {
    this.setState({ add_sample: true })
  }

  handleEditSample = (item) => {
    this.setState({ editItem: item, add_sample: true })
  }

  handleFilter = () => {
    const { sample_type } = this.state;
    if (sample_type === 0) {
      this.setState({ sample_type_list: !this.state.sample_type_list })
    } else this.setState({ sample_mapping: !this.state.sample_mapping })
  }

  handleRefresh = () => {
    this.handleSampleList();
  }

  handleSampleNameChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.test_sample_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.testSampleTypeList })
    }
  }

  handleSampleTypeChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.sampledetails.sample_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.testSampleTypeList })
    }
  }

  handleContainerTypeChange = (e) => {
    var updatedList = this.state.rows;

    if (e.target.value !== '') {
      updatedList = updatedList.filter(function (item) {
        return item.samplecontainerdetails.container_name.toLowerCase().search(
          e.target.value.toLowerCase()) !== -1;
      });
      this.setState({
        [e.target.name]: e.target.value,
        rows: updatedList
      })
    } else {
      this.setState({ rows: this.props.testSampleTypeList })
    }
  }


  render() {
    const { sample_type, add_sample, sample_type_list, sample_mapping, editItem, rows } = this.state;
    return (
      <Container component="main" maxWidth={false} classes={{ root: classes.containerPadding }}>
        {
          add_sample
            ?
            <Grid item xs={12}>
              <AddSampleType editItem={editItem !== undefined ? editItem : []} />
            </Grid>
            :
            <Grid container alignItems='center'>
              <Grid item xs={10}>
                <Typography>
                  Accession Settings&nbsp;
                  <ArrowForwardIos fontSize='small' className={classes.fontSizeArrowIcon} />
                  <b>{sample_type === 0 ? 'Sample Type List' : (sample_type === 1 ? 'Edit Bulk' : '')}</b>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Grid container justify='flex-end'>
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.buttonAccession}
                    onClick={this.handleAddSampleType}
                  >
                    Add Sample Type
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Tabs
                  value={sample_type}
                  onChange={this.handleTabChange}
                  indicatorColor="primary"
                >
                  <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Sample Type List" {...this.handleTabEvent(1)} />
                  {/* <Tab className={clsx(classes.buttonText, classes.drawerMainList)} label="Sample Mapping" {...this.handleTabEvent(2)} /> */}
                </Tabs>
              </Grid>

              <Grid item xs={6}>
                <Grid container justify='flex-end'>
                  <Button className={classes.buttonText} onClick={this.handleFilter}>
                    <FilterList fontSize='small' className={classes.buttonShadow} />&nbsp;Filter Rows
                  </Button>
                  <Button className={classes.buttonText} onClick={this.handleRefresh}>
                    <Refresh fontSize='small' className={classes.buttonShadow} />&nbsp;Refresh
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {sample_type === 0 ?
                  <Table aria-label='simple table'>
                    <TableHead style={{ minWidth: 650 }} >
                      <TableRow>
                        <TableCell style={{ fontWeight: '600' }}>Sr. No.</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align='left'>Sample Name</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align='left'>Sample Type</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align='left'>Container Type</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align='left'></TableCell>
                      </TableRow>
                      {
                        sample_type_list ?
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="left">
                              <TextField
                                margin='dense'
                                variant='outlined'
                                placeholder='Sample Name'
                                onChange={this.handleSampleNameChange}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                margin='dense'
                                variant='outlined'
                                placeholder='Sample Type'
                                onChange={this.handleSampleTypeChange}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                margin='dense'
                                variant='outlined'
                                placeholder='Container Type'
                                onChange={this.handleContainerTypeChange}
                              />
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        : null
                      }
                    </TableHead>
                    <TableBody>
                      {rows.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{index+1}</TableCell>
                          <TableCell>{item.test_sample_name}</TableCell>
                          <TableCell>{item.sampledetails.sample_name}</TableCell>
                          <TableCell>
                            <span style={{ backgroundColor: '#' + item.samplecontainerdetails.container_color }} className={classes.sampleContainerCircle}></span>
                            &nbsp;{item.samplecontainerdetails.container_name}
                          </TableCell>
                          <TableCell>
                            <Link onClick={e => this.handleEditSample(item)}>
                              Edit Sample
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table> : ''
                  // (sample_type === 1 ? <SampleMapping filterList={sample_mapping} /> : '')
                }
              </Grid>
            </Grid>
        }

      </Container>
    )
  }
}

const mapStateToProps = state => ({
  testSampleTypeList: state.login.testSampleTypeList,
})

export default connect(mapStateToProps, {
  getTestSampleTypeList,
})(SampleManagement);