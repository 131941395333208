import React from 'react';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';

import {
  Container,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button
} from '@material-ui/core';

import classes from '../../../App.module.css';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { createtestparameter } from '../../../Store/ActionCreators';

class DescriptiveTest extends React.Component {
  constructor (props) {
    super(props); 
    this.state = {
      parameter: '',
      parameterCode: '',
      description: '',
      integrationCode: '',
      positionPdf: 1,
      dictionary: '',
      is_sms_doctor: '0',
      is_email_doctor: '0',
      is_highlight_value: '0',
      is_underline_value: '0',
      is_non_editable_field: '0',
      is_optional_field: '0',
      is_break_line: '0',
      is_impressions: '0',
      report_name: 'Test_With_Descriptive_Test',
      report_type: this.props.reportType,
      createAnother: false,
      test_id: this.props.testRef
    }
    this.reset = this.state;
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleSaveChanges = async(e) => {
    var obj = {
      test_id: this.state.test_id,
      parameter_info: {}
    }
    Object.keys(this.state).forEach(item => {
      if(item !== 'test_id' && item !== 'createAnother'){
        obj.parameter_info[item] = this.state[item];
      }
    })
    await this.props.createtestparameter(obj);
    if(this.props.createTestParamSuccess){
      if(this.state.createAnother){
        this.setState(this.reset);
      } else {
        this.props.history.push({pathname: '/reportlist'})
      }
    }
  }

  render() {
    const { parameter, integrationCode, description, parameterCode, positionPdf, 
       dictionary, is_sms_doctor, is_email_doctor, is_highlight_value, is_underline_value, is_non_editable_field, is_optional_field, is_break_line, is_impressions, createAnother
    } = this.state;
    return (
      <ValidatorForm onSubmit={this.handleSaveChanges}>
        <Container>
          <Grid container direction='row' justify='center' alignItems='center' spacing={1}>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                  <Typography variant="h4">Descriptive Test (no range)</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Name:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='parameter'
                    value={parameter}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter the value name"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Code:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='parameterCode'
                    value={parameterCode}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter the Primary Code"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Integration Code:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='integrationCode'
                    value={integrationCode}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Integration Code"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Position in PDF:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='positionPdf'
                    value={positionPdf}
                    onChange={this.handleChange}
                    fullWidth
                    placeholder='Position in PDF'
                    variant='outlined'
                    margin='dense'
                  >
                    <MenuItem value={1} key={1}> {1} </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Description:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='description'
                    multiline={true}
                    value={description}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder="Enter Default Description"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction='row' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <Grid container justify='flex-end'>
                    <Typography>Enter Dictionary:</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name='dictionary'
                    value={dictionary}
                    onChange={this.handleChange}
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    placeholder='Enter Dictionary Name'
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider/>
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_sms_doctor'
                value={is_sms_doctor}
                checked={true ?  is_sms_doctor === '1' : false}
                control={<Checkbox color='primary' />}
                label='SMS to doctor'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_email_doctor'
                value={is_email_doctor}
                checked={true ? is_email_doctor === '1' : false}
                control={<Checkbox color='primary' />}
                label='Email to doctor'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_highlight_value'
                value={is_highlight_value}
                checked={true ? is_highlight_value === '1' : false}
                control={<Checkbox color='primary' />}
                label='Highlight this value'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_underline_value'
                value={is_underline_value}
                checked={true ? is_underline_value === '1' : false}
                control={<Checkbox color='primary' />}
                label='Underline this value'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_non_editable_field'
                value={is_non_editable_field}
                checked={true ? is_non_editable_field === '1' : false}
                control={<Checkbox color='primary' />}
                label='Non-editable field'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_optional_field'
                value={is_optional_field}
                checked={true ? is_optional_field === '1' : false}
                control={<Checkbox color='primary' />}
                label='Optional field'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_break_line'
                value={is_break_line}
                checked={true ? is_break_line === '1' : false}
                control={<Checkbox color='primary' />}
                label='Break line'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                name='is_impressions'
                value={is_impressions}
                checked={true ? is_impressions === '1' : false}
                control={<Checkbox color='primary' />}
                label='Has Impressions'
                labelPlacement='end'
                onClick={this.handleCheckBox}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container direction='row' justify="flex-end" alignItems='center' spacing={2}>
                <Grid item xs={3}>
                  <FormControlLabel
                    name='createAnother'
                    value={createAnother}
                    checked={createAnother ? true : false}
                    control={<Checkbox color='primary' />}
                    label='Create Another'
                    labelPlacement='end'
                    onClick={this.handleCheckBox}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    type="submit"
                    color="primary"
                    size="medium"
                    variant="contained"
                    className={classes.buttonText}
                    onSubmit={this.handleSaveChanges}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Container>
      </ValidatorForm>
    )
  }
}

const mapStateToProps = state => ({
  createTestParamSuccess: state.login.createTestParamSuccess
})

export default connect(mapStateToProps, {
  createtestparameter
})(DescriptiveTest);