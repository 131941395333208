import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Grid,
  Paper,
  Select,
  MenuItem,
  TextField,
  Container,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Snackbar,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import flags from '../../Data/flags.json';
import timeList from '../../Data/time.json';
import days from '../../Data/days.json';
import UploadImageDialog from '../../Common/UploadImageDialog';
import designationData from '../../Data/designation.json';
import { addDoctor, editDoctor } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

let visitingdays = days;

class DoctorInfo extends React.Component {
  static propTypes = {
    // editDoctorList: PropTypes.object.isRequired,
    // departmentsList: PropTypes.object.isRequired,
    listRefresh: PropTypes.func.isRequired,

    handleCloseDialog: PropTypes.func.isRequired,
    doctorTab: PropTypes.number.isRequired,
    handleDoctorShiftTab: PropTypes.func.isRequired,
    handleSignatureShiftTab: PropTypes.func.isRequired,
    handleSignaturePrevious: PropTypes.func.isRequired,
    handleLoginAccessPrevious: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      designation: this.props.editDoctorList.designation !== null && this.props.editDoctorList.designation !== undefined ? this.props.editDoctorList.designation : 'Dr.',
      doctor_name: this.props.editDoctorList.doctor_name !== null && this.props.editDoctorList.doctor_name !== undefined ? this.props.editDoctorList.doctor_name : '',
      contact_country_code: this.props.editDoctorList.contact_country_code !== null && this.props.editDoctorList.contact_country_code !== undefined ? this.props.editDoctorList.contact_country_code : '+91',
      contact_number: this.props.editDoctorList.contact_number !== null && this.props.editDoctorList.contact_number !== undefined ? this.props.editDoctorList.contact_number : '',
      email_address: this.props.editDoctorList.email_address !== null && this.props.editDoctorList.email_address !== undefined ? this.props.editDoctorList.email_address : '',
      address: this.props.editDoctorList.address !== null && this.props.editDoctorList.address !== undefined ? this.props.editDoctorList.address : '',
      city: this.props.editDoctorList.city !== null && this.props.editDoctorList.city !== undefined ? this.props.editDoctorList.city : '',
      pincode: this.props.editDoctorList.pincode !== null && this.props.editDoctorList.pincode !== undefined ? this.props.editDoctorList.pincode : '',
      date_of_birth: this.props.editDoctorList.date_of_birth !== null && this.props.editDoctorList.date_of_birth !== undefined ? this.props.editDoctorList.date_of_birth : undefined,
      date_of_anniversary: this.props.editDoctorList.date_of_anniversary !== null && this.props.editDoctorList.date_of_anniversary !== undefined ? this.props.editDoctorList.date_of_anniversary : undefined,
      doctor_pass_key: this.props.editDoctorList.doctor_pass_key !== null && this.props.editDoctorList.doctor_pass_key !== undefined ? this.props.editDoctorList.doctor_pass_key : '',
      doctor_speciality: this.props.editDoctorList.doctor_speciality !== null && this.props.editDoctorList.doctor_speciality !== undefined ? this.props.editDoctorList.doctor_speciality : 0,
      department: this.props.editDoctorList.department !== null && this.props.editDoctorList.department !== undefined ? this.props.editDoctorList.department : '',
      is_send_sms: this.props.editDoctorList.is_send_sms !== null && this.props.editDoctorList.is_send_sms !== undefined ? this.props.editDoctorList.is_send_sms : '0',
      is_send_email: this.props.editDoctorList.is_send_email !== null && this.props.editDoctorList.is_send_email !== undefined ? this.props.editDoctorList.is_send_email : '0',

      day: this.props.editDoctorList.day !== null && this.props.editDoctorList.day !== undefined ? this.props.editDoctorList.day : 'Sunday',
      from_time: this.props.editDoctorList.from_time !== null && this.props.editDoctorList.from_time !== undefined ? this.props.editDoctorList.from_time : '12:00 AM',
      to_time: this.props.editDoctorList.to_time !== null && this.props.editDoctorList.to_time !== undefined ? this.props.editDoctorList.to_time : '12:00 AM',
      username: this.props.editDoctorList.username !== null && this.props.editDoctorList.username !== undefined ? this.props.editDoctorList.username : '',
      password: this.props.editDoctorList.password !== null && this.props.editDoctorList.password !== undefined ? this.props.editDoctorList.password : '',
      doctorvisittiming: this.props.editDoctorList.doctorvisittiming !== null && this.props.editDoctorList.doctorvisittiming !== undefined ? this.props.editDoctorList.doctorvisittiming : [],

      doctorref: this.props.editDoctorList.id !== null && this.props.editDoctorList.id !== undefined ? this.props.editDoctorList.id : null,
      user_id: this.props.editDoctorList.user_id !== null && this.props.editDoctorList.user_id !== undefined ? this.props.editDoctorList.user_id : null,

      image: [],
      showPassword: false,
      openUploadImageDialog: false,
      errors: false,
      openSnackBar: true,
      openVisitings: false,
      openToast: false,
    }
    this.reset = this.state;
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  }

  handleTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckBox = (e) => {
    this.setState({ [e.target.name]: e.target.checked === true ? '1' : '0' })
  }

  handleDialog = () => {
    return (
      <UploadImageDialog
        openDialog={this.state.openUploadImageDialog}
        handleCloseDialog={this.handleCloseUploadImageDialog}
      />
    );
  }

  handleUploadImageDialog = () => {
    this.setState({ openUploadImageDialog: true })
  }

  handleCloseUploadImageDialog = () => {
    this.setState({ openUploadImageDialog: false })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    };
    this.setState({ openSnackBar: false })
  }

  handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    };
    this.setState({ openToast: false })
  }

  handleDOBChange = (e) => {
    this.setState({ date_of_birth: e.target.value })
  }

  handleDOAChange = (e) => {
    this.setState({ date_of_anniversary: e.target.value })
  }

  handleDoctorInfo = () => {
    const { doctor_name, designation, contact_country_code, contact_number, email_address, address, city, pincode,
      date_of_birth, date_of_anniversary, doctor_pass_key, doctor_speciality, department, is_send_sms, is_send_email,
      errors,
    } = this.state;

    const { departmentsList, addDoctorSuccess, doctorNameError, contactNumberError, departmentError, doctorSpecialityError,
      handleCloseDialog, handleDoctorShiftTab, editDoctorList,
    } = this.props;

    let speciality = [
      { id: 1, speciality_name: 'Speciality 1' },
      { id: 2, speciality_name: 'Speciality 2' },
      { id: 3, speciality_name: 'Speciality 3' },
      { id: 4, speciality_name: 'Speciality 4' }
    ]
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.paperHeight }}>
            <Container maxWidth={false} classes={{ root: classes.containerPadding }}>
              <Grid container direction='row' alignItems='center' spacing={1} className={classes.textAlign}>
                <Grid item xs={12}>
                  <Grid container direction='row' justify='flex-end'>
                    <Grid item>
                      Doctor Name<span className={classes.error}> *</span>
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={2}>
                      <Select
                        fullWidth
                        placeholder='Designation'
                        variant='outlined'
                        name='designation'
                        value={designation}
                        onChange={this.handleSelectChange}
                      >
                        {designationData.map(item => <MenuItem value={item.title} key={item.id}> {item.title} </MenuItem>)}
                      </Select>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        name='doctor_name'
                        value={doctor_name}
                        onChange={this.handleTextChange}
                        fullWidth
                        variant='outlined'
                        placeholder='Doctor Name'
                      />
                      {errors && doctor_name === '' ? <div className={classes.error}>This field is required</div> : ''}
                      {addDoctorSuccess === false ? <div className={classes.error}>{doctorNameError}</div> : ''}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction='row' justify='flex-end'>
                    <Grid item>
                      Contact<span className={classes.error}> *</span>
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={2}>
                      <Select
                        name='contact_country_code'
                        value={contact_country_code}
                        onChange={this.handleSelectChange}
                        fullWidth
                        variant='outlined'
                      >
                        {flags.map(flag =>
                          <MenuItem value={flag.dial_code} key={flag.code}>
                            {flag.flag} {flag.name} {flag.dial_code}
                          </MenuItem>
                        )}
                      </Select>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        name='contact_number'
                        value={contact_number}
                        onChange={this.handleTextChange}
                        fullWidth
                        variant='outlined'
                        placeholder='Mobile Number'
                      // validators={['required']}
                      // errorMessages={['This field is required']}
                      />
                      {errors && contact_number === '' ? <div className={classes.error}>This field is required</div> : ''}
                      {addDoctorSuccess === false && contactNumberError !== [] ? <div className={classes.error}>{contactNumberError}</div> : ''}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  Email ID
                  <TextField
                    name='email_address'
                    value={email_address}
                    onChange={this.handleTextChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Email ID'
                  />
                </Grid>

                <Grid item xs={12}>
                  Address
                  <TextField
                    name='address'
                    value={address}
                    onChange={this.handleTextChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Address'
                  />
                </Grid>

                <Grid item xs={6}>
                  City
                  <TextField
                    name='city'
                    value={city}
                    onChange={this.handleTextChange}
                    fullWidth
                    variant='outlined'
                    placeholder='City Name'
                  />
                </Grid>

                <Grid item xs={6}>
                  Pin Code
                  <TextField
                    name='pincode'
                    value={pincode}
                    onChange={this.handleTextChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Pin Code'
                  />
                </Grid>

                <Grid item xs={6}>
                  Date of Birth
                  <TextField
                    fullWidth
                    variant='outlined'
                    type='date'
                    value={date_of_birth}
                    onChange={this.handleDOBChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  Date of Anniversary
                  <TextField
                    fullWidth
                    variant='outlined'
                    type='date'
                    value={date_of_anniversary}
                    onChange={this.handleDOAChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  Doctor Passkey
                  <TextField
                    name='doctor_pass_key'
                    value={doctor_pass_key}
                    onChange={this.handleTextChange}
                    fullWidth
                    variant='outlined'
                    placeholder='Doctor Pass Key'
                  />
                </Grid>

                <Grid item xs={12}>
                  Doctor Speciality
                  <Select
                    name='doctor_speciality'
                    value={doctor_speciality}
                    onChange={this.handleSelectChange}
                    fullWidth
                    label='Select...'
                    variant='outlined'
                  >
                    {speciality.map((item, index) => <MenuItem key={index} value={item.id}>{item.speciality_name}</MenuItem>)}
                  </Select>
                  {errors && doctor_speciality === '' ? <div className={classes.error}>This field is required</div> : ''}
                  {addDoctorSuccess === false ? <div className={classes.error}>{doctorSpecialityError}</div> : ''}
                </Grid>

                <Grid item xs={12}>
                  Department<span className={classes.error}> *</span>
                  <Select
                    name='department'
                    value={department}
                    onChange={this.handleSelectChange}
                    fullWidth
                    label='Select...'
                    variant='outlined'
                  >
                    {departmentsList.length > 0 ? departmentsList.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.department_name}
                      </MenuItem>
                    )) : ''}
                  </Select>
                  {errors && department === '' ? <div className={classes.error}>This field is required</div> : ''}
                  {addDoctorSuccess === false && departmentError !== [] ? <div className={classes.error}>{departmentError}</div> : ''}
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    name='is_send_sms'
                    value={is_send_sms}
                    checked={is_send_sms === '1' ? true : false}
                    onClick={this.handleCheckBox}
                    control={<Checkbox color='primary' />}
                    label='Send SMS'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    name='is_send_email'
                    value={is_send_email}
                    checked={is_send_email === '1' ? true : false}
                    onClick={this.handleCheckBox}
                    control={<Checkbox color='primary' />}
                    label='Send Email'
                    labelPlacement='end'
                  />
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={11}>
          <Grid container justify='flex-end'>
            {editDoctorList.length !== 0 ?
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
                onSubmit={this.handleAddDoctor}
              >
                Update Doctor
              </Button> :
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
                onSubmit={this.handleAddDoctor}
              >
                Add Doctor
              </Button>
            }
              &nbsp;&nbsp;
            <Button
              variant='contained'
              color='primary'
              className={classes.buttonAccession}
              onClick={handleDoctorShiftTab}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  handleFileChange = async (e, name) => {
    let file = e.currentTarget.files[0];
    if (file) {
      // this.setState({[name]: await toBase64(file)})
      this.setState({ image: file.name })
    }
  }

  handleSignature = () => {
    const { openUploadImageDialog, image } = this.state;
    const { handleCloseDialog, handleSignatureShiftTab, handleSignaturePrevious, editDoctorList } = this.props;
    return (
      <Grid container direction='row' spacing={1}>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.paperHeight }}>
            <Container maxWidth={false} classes={{ root: classes.containerPadding }}>
              <Grid container direction='column' spacing={1} alignItems='center'>
                <Grid item xs={12}>
                  <IconButton>
                    <CloudUploadIcon
                      fontSize='large'
                      classes={{ fontSizeLarge: classes.fontSizeIcon }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Upload your file here
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <label htmlFor="upload-signature">
                    <input
                      style={{ display: 'none' }}
                      id="upload-signature"
                      name="upload-signature"
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      onChange={e => this.handleFileChange(e, 'pdf_header_image')}
                    />

                    <Button color="primary" variant="outlined" component="span" className={classes.buttonAccession}
                    >
                      Upload Image
                    </Button>
                  </label>
                </Grid>

                <Grid item xs={6}>
                  <h3>{image}</h3>
                </Grid>

                <Grid item xs={12}>
                  <Typography>
                    Only .png, .jpeg and .jpg file are supported
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {openUploadImageDialog ? this.handleDialog() : ''}
                </Grid>

              </Grid>
            </Container>
          </Paper>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleSignaturePrevious}
          >
            Previous
          </Button>
        </Grid>

        <Grid item xs={10}>
          <Grid container justify='flex-end'>
            {editDoctorList.length !== 0 ?
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
                onSubmit={this.handleAddDoctor}
              >
                Update Doctor
              </Button> :
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
                onSubmit={this.handleAddDoctor}
              >
                Add Doctor
              </Button>
            }
            &nbsp;&nbsp;
            <Button
              variant='contained'
              color='primary'
              className={classes.buttonAccession}
              onClick={handleSignatureShiftTab}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  handleAddClick = () => {
    const { day, from_time, to_time, doctorvisittiming } = this.state;
    if (doctorvisittiming.length < 7 || doctorvisittiming.length === 0) {
      visitingdays = visitingdays.filter(item => item.day !== day);
      //  this.setState({ visitingdays: deleteDay })
      if (from_time !== to_time) {
        this.setState({
          openVisitings: true,
          doctorvisittiming: [...this.state.doctorvisittiming, { day, from_time, to_time }]
        })
      } else this.setState({ openToast: true })
    }
  }

  handleRemoveItem = (id) => {
    const removeVisitTiming = this.state.doctorvisittiming.filter((item, index) => index !== id)
    this.setState({ doctorvisittiming: removeVisitTiming })
  }

  handleAddAllClick = () => {
    const { from_time, to_time, doctorvisittiming } = this.state;
    if (doctorvisittiming.length === 0) {
      if (from_time !== to_time) {
        days.map(item => {
          let day = item.day;
          this.setState(prevState => ({ doctorvisittiming: [...prevState.doctorvisittiming, { day, from_time, to_time }], openVisitings: true }))
        })
      } else this.setState({ openToast: true })
    } else {
      this.setState({ doctorvisittiming: [] })
      days.map(item => {
        let day = item.day;
        this.setState(prevState => ({ doctorvisittiming: [...prevState.doctorvisittiming, { day, from_time, to_time }], openVisitings: true }))
      })
    }
  }

  handleRemoveAllClick = () => {
    this.setState({ doctorvisittiming: [] })
  }

  handleLoginAccess = () => {
    const { day, from_time, to_time, username, password, showPassword, openVisitings, doctorvisittiming, openToast } = this.state;
    const { handleCloseDialog, handleLoginAccessPrevious, editDoctorList, addDoctorSuccess, userNameError, editDoctorSuccess, userNameUpdateError } = this.props;
    return (
      <Grid container direction='row' spacing={1}>
        <Grid item xs={12}>
          <Paper classes={{ root: classes.paperHeight }}>
            <Container maxWidth={false} classes={{ root: classes.containerPadding }}>
              <Grid container direction='row' alignItems='center' spacing={1} className={classes.textAlign}>
                <Grid item xs={12}>
                  <Grid container direction='row' justify='flex-end'>
                    <Grid item>
                      Doctor Username
                    </Grid>
                  </Grid>
                  <Grid container direction='row'>
                    <Grid item xs={2}>
                      <TextField
                        disabled
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        className={clsx(classes.search, classes.expansionPanelColor)}
                        placeholder='lucidatta'
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        name='username'
                        value={username}
                        onChange={this.handleTextChange}
                        inputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        variant='outlined'
                        fullWidth
                        margin='dense'
                        className={classes.search}
                        placeholder='Username'
                      />
                      {addDoctorSuccess === false && userNameError !== [] ? <div className={classes.error}>{userNameError}</div> : ''}
                      {editDoctorSuccess === false && userNameUpdateError !== [] ? <div className={classes.error}>{userNameUpdateError}</div> : ''}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  Doctor Password*
                </Grid>

                <Grid item xs={10}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      value={password}
                      margin='dense'
                      className={classes.search}
                      onChange={this.handleTextChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  * Password should contain at least one small letter, one capital letter,
                  one digit,one special character & greater than 8 characters length
                </Grid>

                <Grid item xs={12}>
                  Visit Timing
                  <Grid container direction='row' spacing={2}>
                    <Grid item xs={2}>
                      <Select
                        name='day'
                        value={day}
                        onChange={this.handleSelectChange}
                        fullWidth
                        variant='outlined'
                        margin='dense'
                      >
                        {visitingdays.map(item => <MenuItem key={item.id} value={item.day}>{item.day}</MenuItem>)}
                      </Select>
                    </Grid>

                    <Grid item xs={2}>
                      <Select
                        fullWidth
                        variant='outlined'
                        margin='dense'
                        value={from_time}
                        name='from_time'
                        onChange={this.handleSelectChange}
                      >
                        {timeList.map(item => <MenuItem key={item.id} value={item.time}>{item.time}</MenuItem>)}
                      </Select>
                    </Grid>

                    <Grid item xs={2}>
                      <Select
                        fullWidth
                        variant='outlined'
                        margin='dense'
                        value={to_time}
                        name='to_time'
                        onChange={this.handleSelectChange}
                      >
                        {timeList.map(item => <MenuItem key={item.id} value={item.time}>{item.time}</MenuItem>)}
                      </Select>
                    </Grid>

                    <Grid item>
                      <Button
                        variant='outlined'
                        color='primary'
                        className={classes.buttonAccession}
                        onClick={this.handleAddClick}
                      >
                        Add
                      </Button>&nbsp;&nbsp;

                      <Button
                        variant='outlined'
                        color='primary'
                        className={classes.buttonAccession}
                        onClick={this.handleAddAllClick}
                      >
                        Add All
                      </Button>&nbsp;&nbsp;

                        <Button
                        variant='outlined'
                        color='primary'
                        className={classes.buttonAccession}
                        onClick={this.handleRemoveAllClick}
                      >
                        Remove All
                      </Button>
                    </Grid>

                  </Grid>
                  <Grid item xs={12}>
                    {
                      openVisitings ? doctorvisittiming.map((item, index) => {
                        return (
                          <Grid container justify='flex-start' alignItems='center' key={index} className={classes.type}>
                            <Grid item xs={3}><Typography>{item.day}</Typography></Grid>
                            <Grid item xs={2}><Typography>{item.from_time}</Typography></Grid>
                            <Grid item xs={2}><Typography>{item.to_time}</Typography></Grid>
                            <Grid item xs={2}><Button variant='contained' color='primary' onClick={e => this.handleRemoveItem(index)}>Remove</Button></Grid>
                          </Grid>
                        )
                      }) : ''
                    }
                    {
                      from_time === to_time ?
                        <Snackbar
                          open={openToast}
                          autoHideDuration={6000}
                          onClose={this.handleCloseToast}
                          message='Start and end time should not be same'
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          action={
                            <React.Fragment>
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleCloseToast}
                              >
                                <CloseIcon />
                              </IconButton>
                            </React.Fragment>
                          }
                        />
                        : ''
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonAccession}
            onClick={handleLoginAccessPrevious}
          >
            Previous
          </Button>
        </Grid>

        <Grid item xs={10}>
          <Grid container justify='flex-end'>
            {editDoctorList.length !== 0 ?
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
                onSubmit={this.handleAddDoctor}
              >
                Update Doctor
              </Button> :
              <Button
                type='submit'
                variant='outlined'
                color='primary'
                className={classes.buttonAccession}
                onSubmit={this.handleAddDoctor}
              >
                Add Doctor
              </Button>}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  handleAddDoctor = async (e) => {
    const { doctor_name, contact_number, department } = this.state;
    const { editDoctorList } = this.props;
    e.preventDefault();
    this.setState({ errors: false, openSnackBar: true })
    if (doctor_name === '' || contact_number === '' || department === '') {
      this.setState({ errors: true })
    } else if (editDoctorList.length !== 0 && editDoctorList !== undefined) {
      await this.props.editDoctor(this.state);
      if (this.props.editDoctorSuccess) {
        this.props.listRefresh();
        this.props.handleCloseDialog();
      }
    } else {
      await this.props.addDoctor(this.state);
      if (this.props.addDoctorSuccess) {
        this.setState(this.reset);
        this.props.listRefresh();
        this.props.handleCloseDialog();
      }
    }
  }

  render() {
    const { doctor_name, contact_number, department, doctor_speciality, errors, openSnackBar } = this.state;
    const { doctorTab } = this.props;
    return (
      <div>
        <form onSubmit={this.handleAddDoctor}>
          {
            doctorTab === 0 ? this.handleDoctorInfo() :
              (doctorTab === 1 ? this.handleSignature() :
                (doctorTab === 2 ? this.handleLoginAccess() : ''))
          }
        </form>
        <div>
          {errors && (doctor_name === '' || contact_number === '' || department === '' || doctor_speciality === '') ?
            <Snackbar
              open={openSnackBar}
              autoHideDuration={6000}
              onClose={this.handleCloseSnackBar}
              message='Please fill all mandatory fields'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              action={
                <React.Fragment>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleCloseSnackBar}
                  >
                    <CloseIcon />
                  </IconButton>
                </React.Fragment>
              }
            /> : ''
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  addDoctorSuccess: state.login.addDoctorSuccess,
  doctorNameError: state.login.errorMessagesDoctor.doctorNameError,
  contactNumberError: state.login.errorMessagesDoctor.contactNumberError,
  departmentError: state.login.errorMessagesDoctor.departmentError,
  doctorSpecialityError: state.login.errorMessagesDoctor.doctorSpecialityError,
  userNameError: state.login.errorMessagesDoctor.userNameError,

  editDoctorSuccess: state.login.editDoctorSuccess,
  userNameUpdateError: state.login.errorMessagesUpdateDoctor.userNameError,
})

export default connect(mapStateToProps, {
  addDoctor,
  editDoctor
})(DoctorInfo);