import React from 'react';
import clsx from 'clsx';

import {
  Container,
  Grid,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core';

import FileUpload from '../../Common/FileUpload';

import classes from '../../App.module.css';

class InvoiceSettings extends React.Component {
  render() {
    return (
      <Container component='main' maxWidth={false} classes={{root: classes.containerPadding}}>
        <Grid container direction='row' justify='flex-start' spacing={1} className={classes.drawerMainList}>
          <Grid item xs={12}>
            <h3>Invoice Settings</h3>
          </Grid>
          <Grid item xs={12}>
            Settings related to Invoices.
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center'>
              <Grid item xs={12}>
                <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Header Height
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='flex-end'>
                      Footer Height
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      margin='dense'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item xs={5}>
                <Grid container direction='row' justify='flex-end'>
                  Upload Invoice Header:
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <FileUpload />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='center' spacing={2}>
              <Grid item xs={5}>
                <Grid container direction='row' justify='flex-end'>
                  Upload Invoice Footer:
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <FileUpload />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              value='end'
              control={<Checkbox color='primary' />}
              label='Invoice Header Flag'
              labelPlacement='end'
              classes={{ label: classes.label }}
            />
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              value='end'
              control={<Checkbox color='primary' />}
              label='Invoice Footer Flag'
              labelPlacement='end'
              classes={{ label: classes.label }}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <Button
                  type="submit"
                  color="primary"
                  size="medium"
                  variant="contained"
                  className={clsx(classes.buttonText, classes.submitButton)}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    );
  }
}

export default InvoiceSettings;