import React from 'react';

class StatusRenderer extends React.Component {
  
  handleStatus = (data) => {
    return (
      <div>
        {data.collected_samples >= 10 ? data.collected_samples : '0' + (data.collected_samples !== undefined ? data.collected_samples : '0')}
          /{data.total_samples > 9 ? data.total_samples : '0' + data.total_samples}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.handleStatus(this.props.node.data)}
      </div>
    );
  }
}

export default StatusRenderer;