import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import {
  Dialog,
  Grid,
  IconButton,
  TextField,
  Typography,
  Divider,
  FormControlLabel,
  RadioGroup,
  Radio,
  ListItem,
  Link,
  Container,
  Button,
  Avatar,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';

import UpdatePassword from '../UpdatePassword';
import { updateProfile } from '../../Store/ActionCreators';

import classes from '../../App.module.css';

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

class UpdateProfile extends React.Component {
  static propTypes = {
    openDialog: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.profileData.user_id,
      full_name: this.props.profileData.full_name,
      email: this.props.profileData.email !== null ? this.props.profileData.email : '',
      mobile: this.props.profileData.mobile !== null ? this.props.profileData.mobile : '',
      gender: this.props.profileData.gender !== null ? this.props.profileData.gender : 'MALE',
      profile_picture: this.props.profileData.profile_picture !== null ? this.props.profileData.profile_picture : null,
      dob: this.props.profileData.dob !== null ? this.props.profileData.dob : null,
      
      updatePassword: false,
    }
    this.reset = this.state;
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSelectChange = (e) => {
    this.setState(oldValues => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }))
  }

  handleDateChange = (e) => {
    this.setState({ dob: e.target.value })
  }
  
  handleChangePassword = () => {
    this.setState({ updatePassword: true })
  }

  handleClosePasswordDialog = () => {
    this.setState({ updatePassword: false })
  }

  handleFileChange = async (e, name) => {
    let file = e.currentTarget.files[0];
    if(file){
      this.setState({profile_picture: await toBase64(file)})
    }
  }

  handleUpdateProfile = async() => {
    await this.props.updateProfile(this.state);
    if(this.props.updateProfileSuccess) {
      this.props.handleCloseDialog();
      this.setState(this.reset);
    }
  }

  render() {
    const { full_name, email, mobile, profile_picture, dob, gender, updatePassword } = this.state;
    const { openDialog, handleCloseDialog, profileData } = this.props;
    return (
      <Dialog open={openDialog} maxWidth='sm' classes={{ paper: classes.dialogWidth}}>
        <Grid container justify='center' alignItems='center'>
          <Grid item xs={11}>
            <span className={classes.type}>
              <Typography className={classes.collectButtonPadding}>Update Profile</Typography>
            </span>
          </Grid>
          <Grid item xs={1} className={classes.type}>
            <Grid container justify='flex-end'>
              <IconButton onClick={handleCloseDialog}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' alignItems='flex-start'>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <label htmlFor="contained-button-file">
                      <Avatar
                        style={{
                          margin: "10px",
                          width: "120px",
                          height: "120px",
                        }}
                        src={profile_picture}
                      />
                      <input
                        id="upload-profile"
                        name="upload-profile"
                        type="file"
                        accept="application/pdf, image/jpeg, image/jpg, image/png"
                        onChange={ e => this.handleFileChange(e, 'pdf_header_image')}
                      />
                    </label>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={8}>
                <Grid container alignItems='center'>
                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Name&nbsp;&nbsp;</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name='full_name'
                      value={full_name}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder='Enter Full Name'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Contact&nbsp;&nbsp;</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name='mobile'
                      value={mobile}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder='Enter Contact Number'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Email&nbsp;&nbsp;</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      name='email'
                      value={email}
                      onChange={this.handleChange}
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      placeholder='Enter your email'
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>DOB&nbsp;&nbsp;</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      margin='dense'
                      variant='outlined'
                      type='date'
                      value={dob}
                      onChange={this.handleDateChange}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Grid container justify='flex-end'>
                      <Typography>Gender&nbsp;&nbsp;</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="gender"
                      value={gender}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value='MALE'
                        checked={gender === 'MALE'}
                        control={<Radio color='primary' />}
                        label='Male'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        value='FEMALE'
                        checked={gender === 'FEMALE'}
                        control={<Radio color='primary' />}
                        label='Female'
                        labelPlacement='end'
                      />
                      <FormControlLabel
                        value='OTHER'
                        checked={gender === 'OTHER'}
                        control={<Radio color='primary' />}
                        label='Other'
                        labelPlacement='end'
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={3}></Grid>
                  <Grid item xs={7}>
                    <ListItem button onClick={this.handleChangePassword}>
                      <Link underline='none' color='primary'>
                        <Typography>Update Password ?</Typography>
                      </Link>
                    </ListItem>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={11}>
            <Typography variant='h6'>
              Login Devices Details
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Container maxWidth='sm'>
              <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12} className={classes.error}>
                  <Typography className={classes.type}>Note : *Blocking device will block your login from selected device</Typography>
                </Grid>
                <Grid item xs={12} className={classes.error}>
                  <Typography>Delete device : You will be notified, over email in case of any login from deleted device.</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography>Device</Typography>
                </Grid>
                {/* <Grid item xs={6}>
                  <ListItem button>
                    <Link underline='none' color='primary'>
                      <Typography>Logout from all device</Typography>
                    </Link>
                  </ListItem>
                </Grid> */}

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} className={classes.heightFix}>
                  <Grid container>
                    <Grid item xs={9}>
                      <Typography>Chrome (83.0.4103) on Mac OS X</Typography>
                      <div /><Button
                        variant='contained'
                        color='primary'
                        className={clsx(classes.buttonAccession, classes.buttonLineHeight)}
                      >
                        Active
                      </Button>
                    </Grid>
                    <Grid item xs={2}>
                      {/* <ListItem button>
                        <Link underline='none' color='primary'>
                          <Typography>Block</Typography>
                        </Link>
                      </ListItem> */}
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton>
                        <CancelPresentationIcon fontSize='small' />
                      </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={9}>
                      <Typography>Firefox (77.0) on Windows 10</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <ListItem button>
                        <Link underline='none' color='primary'>
                          <Typography>Block</Typography>
                        </Link>
                      </ListItem>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton>
                        <CancelPresentationIcon fontSize='small' />
                      </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={9}>
                      <Typography>Firefox (77.0) on Windows 10</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <ListItem button>
                        <Link underline='none' color='primary'>
                          <Typography>Block</Typography>
                        </Link>
                      </ListItem>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton>
                        <CancelPresentationIcon fontSize='small' />
                      </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={9}>
                      <Typography>Firefox (77.0) on Windows 10</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <ListItem button>
                        <Link underline='none' color='primary'>
                          <Typography>Block</Typography>
                        </Link>
                      </ListItem>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton>
                        <CancelPresentationIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Container>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.dividerPadding} />
          </Grid>

          <Grid item xs={1} className={classes.collectButtonPadding}>
            <Button
              variant='outlined'
              color='primary'
              className={classes.buttonAccession}
              onClick={handleCloseDialog}
            >
              Close
            </Button>
          </Grid>

          <Grid item xs={11}>
            <Grid container justify='flex-end'>
              <Button
                variant='contained'
                color='primary'
                className={classes.buttonAccession}
                onClick={this.handleUpdateProfile}
              >
                Update
              </Button>&nbsp;&nbsp;
              </Grid>
          </Grid>

          <Grid item xs={12}>
            {updatePassword ?
              <UpdatePassword
                openDialog={updatePassword}
                handleCloseDialog={this.handleClosePasswordDialog}
                userId={profileData.id}
              />
              : ''
            }
          </Grid>

        </Grid>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  updateProfileSuccess: state.login.updateProfileSuccess
})

export default connect(mapStateToProps, {
  updateProfile
})(UpdateProfile);